<script lang="ts">
import { defineComponent, computed, getCurrentInstance } from 'vue';
import ClickButton from '@/components/base/ClickButton.vue';
import BaseIcon from '@/components/base/BaseIcon.vue';
import { useUserStore } from '@/store';
import { UserRole } from '@/types/user';
import { getClassByRole } from '@/utils/composable/globalHelpers';

export default defineComponent({
  name: 'BaseModal',
  components: {
    ClickButton,
    BaseIcon,
  },
  props: {
    isOpen: { type: Boolean, required: true }, // unique id
    modalTitle: { type: String, required: true }, // title
    modalSubtitle: { type: String, default: '', required: false }, // subtitle / description
    isForm: { type: Boolean },
    modalStyle: { type: String, default: '' }, // additional styling
    noClose: { type: Boolean, default: false }, // component can't close itself
    initOpen: { type: Boolean, required: false }, // open on initialization
    deleteAction: { type: Boolean },
    showButtons: { type: Boolean, default: true }, // below buttons showing by default
    dataCy: { type: String, required: true },
  },
  emits: ['handleClose'],
  setup(props, ctx) {
    const userStore = useUserStore();
    const cypressDataAttribute = `${getCurrentInstance()?.type.name}`;

    const handleCloseModal = () => {
      ctx.emit('handleClose');
    };

    const roleClassName = () => getClassByRole(userStore.getUserRole as UserRole);
    const modalCloseCyName = `${props?.dataCy}-modal-close`;
    const outerStyleClasses = computed(() =>
      props.modalStyle.split(' ').map((item) => `outer-${item}`),
    );

    return {
      handleCloseModal,
      roleClassName,
      cypressDataAttribute,
      modalCloseCyName,
      outerStyleClasses,
    };
  },
});
</script>

<template>
  <teleport to="body">
    <transition name="modal">
      <div
        v-if="isOpen"
        :class="[`modal modal__backdrop role-${roleClassName()}`, outerStyleClasses]"
        aria-modal="true"
        role="dialog"
        @mousedown.self="handleCloseModal"
      >
        <div :class="['modal__container', modalStyle]">
          <div class="modal__top-row">
            <div class="modal__header">
              <h2 class="h3 modal__title" :data-cy="`${cypressDataAttribute}${dataCy}`">
                {{ modalTitle }}
              </h2>
              <button v-if="!noClose" class="modal__close" @click="handleCloseModal">
                <BaseIcon
                  :data-cy="`${cypressDataAttribute}-${modalCloseCyName}`"
                  icon-name="close"
                />
              </button>
            </div>
            <p v-if="modalSubtitle" class="modal__subtitle">
              {{ modalSubtitle }}
            </p>
          </div>
          <slot />
          <div v-if="!isForm && showButtons" class="modal__buttons">
            <click-button
              :data-cy="`${cypressDataAttribute}-${dataCy}ModalDeclineBtn`"
              class="btn--outlined-primary"
              @click="handleCloseModal"
            >
              {{ 'ModalWindow.declineBtn' }}
            </click-button>
            <click-button :data-cy="`${cypressDataAttribute}-${dataCy}ModalAcceptBtn`">
              {{ 'ModalWindow.acceptBtn' }}
            </click-button>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<style lang="scss" scoped>
.modal {
  &__container {
    display: block;
    position: absolute;
    z-index: 1000;
    top: 50%;
    left: 50%;
    width: calc(100% - #{remCalc(20)});
    max-width: remCalc(1100);
    height: auto;
    max-height: calc(100vh - #{remCalc(20)});
    padding: remCalc(20);
    overflow: auto;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: remCalc(4);
    background: #fff;
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);

    @media screen and (min-width: $tablet-minus) {
      width: calc(100% - #{remCalc(40)});
      height: auto;
      max-height: calc(100vh - #{remCalc(120)});
      padding: remCalc(40);
    }

    &.little {
      max-width: remCalc(400);
    }

    &.smallest {
      max-width: remCalc(500);
    }

    &.smaller {
      max-width: remCalc(600);
    }

    &.small {
      max-width: remCalc(700);
    }

    &.medium {
      max-width: remCalc(800);
    }

    &.medium-plus {
      max-width: remCalc(960);
    }

    &.large {
      max-width: remCalc(1100);
    }

    &.larger {
      max-width: remCalc(1338);
    }

    &.auto {
      width: auto;
    }

    &.full-size {
      width: 100%;
      height: 100%;
      max-height: none;
      max-width: none;
    }

    &.calendar-popup {
      min-width: 52%;
      .modal__top-row {
        margin-bottom: 0;
      }

      :deep(.tip) {
        font-size: remCalc(16);
        text-align: center;
      }

      :deep(.no-account-text) {
        margin-top: remCalc(16);
        font-size: remCalc(16);
        @extend %fw-400;
        color: var(--color-light-grey);
        text-align: center;
      }

      /**
      * Using px instead of rems as these don't conflict with the widget
      * Most of the values are suggested by the official documentation
      */
      &-calendly {
        width: 100%;
        height: 100%;
        max-width: 1200px;
        max-height: 750px;
        padding: 0;
        border-radius: 0;
        background: none;
        box-shadow: none;
        box-sizing: border-box;

        .modal__top-row {
          display: none;
        }

        :deep(.main-wrapper) {
          height: 100%;
          padding: 0;
        }

        :deep(.steps) {
          display: none;
        }

        :deep(.tip) {
          display: none;
        }

        @media screen and (max-width: $tablet) {
          width: 65%;
          max-height: 345px;
        }
      }
    }

    &.fit-content {
      width: fit-content;
    }

    &.scroll {
      padding-right: remCalc(25);
      overflow-y: scroll;
    }

    // -v classes are for vertical positioning
    &.center-v {
      // center vertically
      top: 50%;
      left: 50%;
      max-height: calc(100vh - 40px);
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }

    &.small-v {
      top: 10%;
      max-height: calc(100vh - 10% - #{remCalc(15)});
    }

    &.medium-v {
      top: 20%;
      max-height: calc(100vh - 20% - #{remCalc(15)});
    }

    &.text-content {
      .modal__top-row {
        margin-bottom: 8px;
      }
    }

    &.reject-webinar {
      .modal__subtitle {
        @extend %fw-700;
        font-size: var(--text-s);
        line-height: var(--line-s);
      }
    }
  }

  &__backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.35);
    overflow: auto;
    padding: var(--space-xxs);

    &.outer-overflow-visible {
      text-align: center;
      &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        width: 0px;
      }
    }
  }

  &__title {
    @extend %font-modal-title-bold;
    color: var(--color-main);
  }

  &__subtitle {
    @extend %font-modal-subtitle;
    color: var(--color-main);
    margin-top: remCalc(8);
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: $tablet-minus) {
      align-items: center;
    }
  }

  &__top-row {
    display: flex;
    flex-direction: column;
    margin-bottom: remCalc(32);
  }

  &__close {
    display: flex;
    width: auto;
    cursor: pointer;
  }
}

.popup-form {
  max-width: remCalc(450);
  width: 90%;
  padding: remCalc(24);
}

.popup-form-md {
  @extend .popup-form;
  max-width: remCalc(550);
}

.popup-form-v-md {
  min-height: remCalc(320);
}
.popup-form-lg {
  @extend .popup-form;
  max-width: remCalc(720);
  top: 50%;
}
.overflow-visible {
  overflow: visible;
  max-height: none;
  transform: none;
  position: static;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}

.bsupp-modal {
  max-width: remCalc(800);

  .modal__top-row {
    margin-bottom: remCalc(8);
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.4s ease-in-out;

  > div:not(.overflow-visible) {
    transition: all 0.4s ease-in-out;
    transform: translate(-50%, -50%);
  }
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;

  > div:not(.overflow-visible) {
    transform: translate(-50%, -45%);
  }
}
</style>
