import { apolloClient } from '@/services/apollo';
import { EnergyExchangeOrderFieldsFragment } from '@/types/graphql/fragments';
import { Order, OrderPaginationInfo } from '@/types/graphql';
import { GET_ORDERS } from '@/api/energyExchange';
import { UserRole } from '@/types/user';
import { t } from '@/utils/composable/localeHelper';
import { NotificationType } from '@/types/notification';
import {
  setQuoteState,
  setQuoteLoadingState,
  selectedFilterOrderConfirmations,
  activeSortFieldOrderConfirmations,
  sortOrderOrderConfirmations,
  setLatestCreatedOrder,
} from '@/utils/composable/EnergyExchange';
import { useNotificationStore, useUserStore } from '@/store';
import { fieldTranslationMap } from '@/types/energy-exchange';

const { addNotification } = useNotificationStore();

const userStore = useUserStore();

type NotificationOrderPayload = {
  id: string;
  number: string;
  volume: number;
  type: string;
  term: number;
  commodity: {
    id: string;
    name: string;
    __typename: 'Commodity';
  };
  priceId: string;
  price: number;
  status: string;
  deliveryDate: string[];
  paymentDate: string[];
  createdAt: string[];
  updatedAt: string[];
  totalPrice?: number | null;
  __typename: 'Order';
};

type NotificationPayload = {
  type: 'PRICE_REJECTED' | 'PRICE_ACCEPTED';
  payload: {
    account: {
      id: string;
      name: string;
      firstName: string;
      middleName?: string;
      lastName: string;
      __typename: 'Account';
    };
    order: NotificationOrderPayload;
    product: {
      id: string;
      name: string;
      abbreviation: string;
      __typename: 'Product';
    };
    clientCompany: {
      id: string;
      name: string;
      __typename: 'ClientCompany';
    };
  };
  notification_id: string;
};

const updateCacheForOrderStatus = (
  orderData: NotificationOrderPayload,
  clientCompany: NotificationPayload['payload']['clientCompany'],
): Promise<void> =>
  new Promise((resolve) => {
    const { cache } = apolloClient;
    const updatedOrder: EnergyExchangeOrderFieldsFragment = {
      __typename: 'Order',
      createdAt: orderData.createdAt[0],
      deliveryDate: orderData.deliveryDate[0],
      id: orderData.id,
      number: orderData.number,
      paymentDate: orderData.paymentDate[0],
      priceId: orderData.priceId,
      price: orderData.price,
      status: orderData.status,
      term: orderData.term,
      totalAmount: orderData.totalPrice || null,
      type: orderData.type,
      updatedAt: orderData.updatedAt[0],
      volume: orderData.volume,
      isPaid: false,
      createdBy: {
        __typename: 'Account',
        clientCompany: {
          __typename: 'ClientCompany',
          id: clientCompany.id,
          legalName: clientCompany.name,
        },
      },
    };

    // Read existing orders from the cache
    const existingOrders = cache.readQuery<{
      orders: { collection: Order[]; paginationInfo: OrderPaginationInfo };
    }>({
      query: GET_ORDERS,
      variables: { page: 1 },
    });

    // Update the specific order in the orders collection
    if (existingOrders?.orders?.collection) {
      const queryvariables = {
        page: 1,
        ...(selectedFilterOrderConfirmations.value
          ? { status_list: [selectedFilterOrderConfirmations.value] }
          : {}),
        ...(activeSortFieldOrderConfirmations.value !== 'createdAt'
          ? {
              order: [
                {
                  [fieldTranslationMap[activeSortFieldOrderConfirmations.value]]:
                    sortOrderOrderConfirmations.value,
                },
              ],
            }
          : {}),
      };
      if (existingOrders.orders.collection.find((order) => order.id === updatedOrder.id)?.id) {
        const updatedOrders = existingOrders.orders.collection.map((order) =>
          order.id === updatedOrder.id ? updatedOrder : order,
        );
        cache.writeQuery<{
          orders: {
            collection: EnergyExchangeOrderFieldsFragment[];
            paginationInfo: OrderPaginationInfo;
          };
        }>({
          query: GET_ORDERS,
          data: {
            ...existingOrders,
            orders: {
              ...existingOrders.orders,
              collection: updatedOrders,
              paginationInfo: existingOrders.orders.paginationInfo,
            },
          },
          variables: queryvariables,
        });
        resolve();
      } else if (userStore.getAllUserRoles?.includes('ROLE_SUSTAINABILITY_ADVISOR')) {
        cache.writeQuery<{
          orders: {
            collection: EnergyExchangeOrderFieldsFragment[];
            paginationInfo: OrderPaginationInfo;
          };
        }>({
          query: GET_ORDERS,
          data: {
            ...existingOrders,
            orders: {
              ...existingOrders.orders,
              collection: [updatedOrder, ...existingOrders.orders.collection],
              paginationInfo: existingOrders.orders.paginationInfo,
            },
          },
          variables: queryvariables,
        });
        resolve();
      }
    }
  });

// Only send notifications to company representatives, it's a response to creating an order which only they can
export const handleOrderViaWebsocket = async (newMessage: NotificationPayload) => {
  if (newMessage.type === 'PRICE_REJECTED') {
    if (userStore.getUserRole === UserRole.ROLE_COMPANY_REPRESENTATIVE) {
      addNotification({
        message: t('pages.pushNotifications.priceRejected.popupMessage'),
        type: NotificationType.ERROR,
        showIcon: true,
      });

      setQuoteLoadingState(false);
    }

    await updateCacheForOrderStatus(newMessage.payload.order, newMessage.payload.clientCompany);
    setLatestCreatedOrder({
      orderNumber: newMessage.payload.order.number,
      status: newMessage.payload.order.status,
    });
  }

  if (newMessage.type === 'PRICE_ACCEPTED') {
    if (userStore.getUserRole === UserRole.ROLE_COMPANY_REPRESENTATIVE) {
      addNotification({
        message: t('pages.pushNotifications.priceAccepted.popupMessage'),
        type: NotificationType.SUCCESS,
        showIcon: true,
      });

      setQuoteLoadingState(false);
      setQuoteState('prices');
    }

    await updateCacheForOrderStatus(newMessage.payload.order, newMessage.payload.clientCompany);
    setLatestCreatedOrder({
      orderNumber: newMessage.payload.order.number,
      status: newMessage.payload.order.status,
    });
  }
};

export async function getExchangeStatus() {
  try {
    const response = await fetch(`${import.meta.env.VITE_PORTAL_GRAPHQL_URL}/exchange/status`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${userStore.accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();

    if (data.status === 'enabled') {
      setQuoteState('prices');
    } else if (data.status === 'manually disabled') {
      setQuoteState('disabled');
    }

    return data.status;
  } catch (error) {
    return 'error';
  }
}
