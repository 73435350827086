import { t } from '@/utils/composable/localeHelper';

export type MarketData = {
  ask: number | null;
  bid: number | null;
  enabled: boolean;
};

export interface MarginAdjustment {
  id: string;
  commodity: string;
  createdAt: string;
  t0: MarketData;
  t2: MarketData;
  t4: MarketData;
  t6: MarketData;
  t8: MarketData;
  t10: MarketData;
}

export type PriceServiceMessage = {
  id: string;
  ask: number | null;
  bid: number | null;
  time: string;
  symbol: string;
  t0: MarketData;
  t2: MarketData;
  t4: MarketData;
  t6: MarketData;
  t8: MarketData;
  t10: MarketData;
};

export type HistoricalPrice = {
  id: string;
  price: number;
  time: string;
  symbol: string;
};

export const fieldTranslationMap: Record<string, string> = {
  [t('EeOrderConfirmation.settled')]: 'isPaid',
  [t('EeOrderConfirmation.orderNumber')]: 'number',
  [t('EeOrderConfirmation.status')]: 'status',
  [t('EeOrderConfirmation.buySell')]: 'type',
  [t('EeOrderConfirmation.term')]: 'term',
  [t('EeOrderConfirmation.clientCompany')]: 'createdBy.clientCompany.legalName', // Adjust if needed
  [t('EeOrderConfirmation.totalAmountCertificates')]: 'volume',
  [t('EeOrderConfirmation.certificatePrice')]: 'price',
  [t('EeOrderConfirmation.totalNotional')]: 'totalAmount',
  [t('EeOrderConfirmation.paymentDate')]: 'paymentDate',
  [t('EeOrderConfirmation.deliveryDate')]: 'deliveryDate',
  [t('EeOrderConfirmation.settled')]: 'isPaid',
  createdAt: 'createdAt',
};

export type EnergyExchangeFeature = {
  title: string;
  text: string;
  icon: string;
  image: string;
};
