<script lang="ts">
import { computed, defineComponent, getCurrentInstance } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useGlobalStore, useUserStore } from '@/store';
import { getBtnTerms, getTranslationTerms } from '@/utils/composable/localeHelper';
import ClickButton from '@/components/base/ClickButton.vue';
import { COMMON_SIDE_BAR_ROUTES, MAIN_ROUTES, USER_ROLE_ROUTES } from '@/config/constants/routes';
import UserMenuButton from '@/components/navigation/UserMenuButton.vue';
import UserMenu from '@/components/navigation/UserMenu.vue';
import { notificationWindowOpen, userMenuOpen } from '@/utils/composable/topNavigationBar';
import NotificationsDropdown from '@/views/notificationsModule/NotificationsDropdown.vue';
import NotificationsBellButton from '@/components/navigation/NotificationsBellButton.vue';
import BackButton from '@/components/navigation/BackButton.vue';
import NavControlButton from '@/components/navigation/NavControlButton.vue';
import { isMobileWindowWidth } from '@/utils/composable/navigation';

export default defineComponent({
  name: 'TopNavBar',
  components: {
    NavControlButton,
    BackButton,
    NotificationsBellButton,
    NotificationsDropdown,
    UserMenu,
    UserMenuButton,
    ClickButton,
  },
  setup() {
    const globalStore = useGlobalStore();
    const cypressDataAttribute = `${getCurrentInstance()?.type.name}`;
    const userStore = useUserStore();
    const { toggleProductSwitcherPopup } = globalStore;
    const { isGoToCalendlyButtonVisible, headerTitle, isActionButtonVisible, actionButtons } =
      storeToRefs(globalStore);

    const router = useRouter();

    const getHeaderTerms = getTranslationTerms.bind(null, 'base', 'header');
    const getProductName = getTranslationTerms.bind(null, 'products', 'product');
    const defaultBackButtonText = getHeaderTerms('backButton');

    const noBackButtonRoutes = [
      MAIN_ROUTES.DASHBOARD.path,
      COMMON_SIDE_BAR_ROUTES.BLOG.path,
      COMMON_SIDE_BAR_ROUTES.BLOG_SQUIDEX.path,
      COMMON_SIDE_BAR_ROUTES.NEWSLETTER.path,
      MAIN_ROUTES.SETTINGS.path,
      USER_ROLE_ROUTES.COMPANY.path,
      USER_ROLE_ROUTES.CLIENT_COMPANY_PROFILE_DETAIL.path,
      MAIN_ROUTES.IOI_OVERVIEW.path,
    ];

    const isBackButtonVisible = computed(() => {
      let noBackButtonMatch = false;
      // using as string because it is
      // these pages should have back buttons
      if (
        ['indicationdsOfInterestDetail'].includes(
          router?.currentRoute?.value?.matched?.[0]?.name as string,
        )
      ) {
        return false;
      }
      router.currentRoute.value.matched.forEach((matched) => {
        if (noBackButtonRoutes.includes(matched.path)) {
          noBackButtonMatch = true;
        }
      });
      return !noBackButtonMatch && !!router.options.history.state.back && !userStore.isAPIUserRole;
    });

    const goBack = (): void => {
      router.back();
    };

    function handleActionButtonText(text: string): string {
      return text || defaultBackButtonText;
    }

    function toActionLink(actionLink: string): void {
      if (actionLink) {
        router.push(actionLink);
      }
    }

    return {
      isBackButtonVisible,
      headerTitle,
      goBack,
      defaultBackButtonText,
      router,
      handleActionButtonText,
      toActionLink,
      isActionButtonVisible,
      actionButtons,
      globalStore,
      toggleProductSwitcherPopup,
      getProductName,
      getHeaderTerms,
      getBtnTerms,
      cypressDataAttribute,
      isGoToCalendlyButtonVisible,
      userMenuOpen,
      notificationWindowOpen,
      userStore,
      isMobileWindowWidth,
    };
  },
});
</script>

<template>
  <div class="header-cont">
    <header class="header">
      <div class="header__middle-cont">
        <NavControlButton />
        <BackButton v-if="isBackButtonVisible" />
        <div v-if="!isBackButtonVisible" id="topbar-left" />
        <div v-if="headerTitle" class="header__title">
          {{ headerTitle }}
        </div>
      </div>

      <div class="header__right-cont">
        <div class="header__action-block topNavBar">
          <div v-if="actionButtons.length" class="action-container">
            <ClickButton
              v-for="item in actionButtons"
              :key="item.actionButtonId"
              :icon="!!item.actionButtonIcon ? item.actionButtonIcon : ''"
              :data-cy="`${cypressDataAttribute}-${item.actionButtonText}`"
              :status="`${
                item.actionButtonOutlined ? 'outlined-secondary' : 'secondary'
              } header__action-button`"
              @click="
                item.toActionLink ? toActionLink(item.toActionLink) : item.toggleButtonState()
              "
            >
              {{ handleActionButtonText(item.actionButtonText) }}
            </ClickButton>
          </div>
        </div>
        <div class="header__action-divider" />
        <ClickButton
          v-if="!userStore.isAPIUserRole"
          v-tooltip="getHeaderTerms('changeProduct')"
          icon="switcher"
          data-cy="HeaderProductSwitcher"
          icon-position="right"
          :class="['btn--product-switcher', { icon__only: !userStore.getSelectedProduct }]"
          @click="toggleProductSwitcherPopup(true)"
        >
          <template v-if="userStore.getSelectedProduct && !isMobileWindowWidth">
            {{ getProductName(userStore.getSelectedProduct.abbr) }}
          </template>
        </ClickButton>
        <template v-if="!userStore.isAPIUserRole">
          <div class="header__action-divider" />
          <NotificationsBellButton />
        </template>
        <NotificationsDropdown v-if="notificationWindowOpen" />
        <user-menu-button id="user-menu-button" class="header__user-menu-button" />
        <transition name="fade">
          <user-menu v-if="userMenuOpen" />
        </transition>
      </div>
    </header>
  </div>
</template>
<style lang="scss" scoped>
.header-cont {
  position: sticky;
  z-index: 50;

  // if displaying floating action buttons provide margin
  &:has(.header__action-block .action-container:not(:empty)) {
    margin-bottom: 2.2rem;

    @media screen and (min-width: $mobile-plus) {
      margin-bottom: 0;
    }
  }
}

.header {
  display: grid;
  grid-template-columns: 1fr auto;
  height: auto;
  background: var(--color-bg-light);
  box-shadow: var(--box-shadow-normal);
  min-height: var(--top-nav-height);
  padding: var(--space-xxs);

  @media screen and (min-width: $small-desktop) {
    grid-template-columns: 1fr auto;
  }

  @media screen and (min-width: $menuHidden) {
    padding-left: var(--space-xs);
  }

  &__title {
    @extend %fw-700;
    font-size: var(--text-xxl);
    line-height: var(--line-xxl);
    color: var(--color-primary);
  }

  &__back-button {
    @extend %font-button-sm;
    cursor: pointer;
    color: var(--color-secondary);
    display: flex;
    align-items: center;

    &_icon {
      height: remCalc(16);
      width: remCalc(16);
      margin-right: var(--space-6xs);
      stroke: var(--color-secondary);
      stroke-width: 2px;
      stroke-linejoin: round;
    }

    &_text {
      @extend %fw-700;
    }
  }

  &__name {
    margin-left: 1rem;
  }

  &__middle-cont {
    display: flex;
    align-items: center;

    &.flex-end {
      justify-content: flex-end;
    }
  }

  &__right-cont {
    display: flex;
    align-items: center;
    margin-left: auto;

    @media screen and (min-width: $mobile-plus) {
      margin-right: var(--space-xxs);
    }
  }

  &__action-divider {
    display: none;
    height: remCalc(32);
    width: 1px;
    background: var(--color-shade-4);
    margin: 0 var(--space-xxs);

    @media screen and (min-width: $mobile-plus) {
      display: block;
    }
  }

  &__notification-button {
    display: flex;
    align-items: center;
    padding: var(--space-3xs);
    margin-right: var(--space-xs);
  }

  &__versioning {
    display: flex;
    align-items: center;
    margin-right: var(--space-xs);
  }

  &__versioning-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__product-switcher {
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: var(--space-3xs);

    &_icon {
      display: block;
      flex: 0 0 auto;
      width: remCalc(24);
      height: auto;
    }

    span {
      @extend %font-navigation-item;
      line-height: var(--line-s);
    }
  }

  &__action-block {
    &:not(:empty) {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: repeat(auto-fill, auto);
      gap: var(--space-xxs);
      position: absolute;
      top: 3.8rem;
      width: 100%;
      left: 0;
      overflow: auto;
      background: white;
      box-shadow: var(--box-shadow-normal);
      padding: 0 0 6px 0;

      @media screen and (min-width: $mobile-plus) {
        width: auto;
        position: relative;
        top: auto;
        box-shadow: none;
        padding: 0;
      }
    }

    :deep(.action-container) {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      overflow: auto;
      padding: 0 var(--space-4xs);
      gap: var(--space-4xs);

      @media screen and (min-width: $mobile-plus) {
        padding: 0 1px;
      }
    }

    > :deep(.btn + .btn) {
      margin-left: 0;
    }
  }

  &__action-block:empty + &__action-divider {
    display: none;
  }

  .user-menu-button {
    margin-left: var(--space-5xs);

    @media screen and (min-width: $mobile-plus) {
      margin-left: var(--space-xxs);
    }
  }
}

.btn--product-switcher {
  padding-left: var(--space-4xs);
  padding-right: var(--space-4xs);
  white-space: nowrap;

  :deep(.nav-mb__icon) {
    @media screen and (max-width: $mobile-plus) {
      margin-left: 0;
    }
  }

  @media screen and (min-width: $mobile-plus) {
    padding: var(--button-padding);
  }
}
</style>
