import { UserState, UserRole, ViewType } from '@/types/user';
import { frontendRolesToBackendRoles } from '@/config/user';

const getLocalUserState = (): UserState => {
  const savedLocal =
    localStorage.getItem('user') !== null
      ? JSON.parse(localStorage.getItem('user') as string)
      : {
          roles: [],
          id: null,
          clientCompanyId: null,
          clientCompanyName: null,
          createdAt: null,
          selectedProduct: null,
          clientCompany: null,
          appointedClients: null,
          appointedProducts: null,
          selectedTab: null,
          copywriterRole: null,
          avatar: null,
          firstName: '',
          lastName: '',
          middleName: '',
          phoneNumber: null,
          email: null,
        };
  let viewType = sessionStorage.getItem('viewType') ? sessionStorage.getItem('viewType') : null;
  const viewCompany =
    sessionStorage.getItem('viewCompany') !== null
      ? JSON.parse(sessionStorage.getItem('viewCompany') as string)
      : null;

  // reset if in client view but no client selected
  if (!viewCompany && viewType === ViewType.CLIENT) {
    viewType = ViewType.PERSONAL;
  }
  if (
    !viewCompany &&
    savedLocal.roles &&
    savedLocal.roles[0] === frontendRolesToBackendRoles[UserRole.ROLE_COMPANY_REPRESENTATIVE] &&
    (savedLocal.roles.includes(frontendRolesToBackendRoles[UserRole.ROLE_SUSTAINABILITY_ADVISOR]) ||
      savedLocal.roles.includes(frontendRolesToBackendRoles[UserRole.ROLE_BUSINESS_SUPPORT]))
  ) {
    savedLocal.roles.shift();
  } else if (
    viewCompany &&
    !savedLocal.roles.includes(frontendRolesToBackendRoles[UserRole.ROLE_COMPANY_REPRESENTATIVE])
  ) {
    savedLocal.roles?.unshift(frontendRolesToBackendRoles[UserRole.ROLE_COMPANY_REPRESENTATIVE]);
  }
  return {
    ...savedLocal,
    viewType,
    viewCompany,
  };
};

const setLocalUserState = (userInfo: UserState): void => {
  const userInfoToLocal = JSON.parse(JSON.stringify(userInfo));
  delete userInfoToLocal.viewType;
  delete userInfoToLocal.viewCompany;
  localStorage.setItem('user', JSON.stringify(userInfoToLocal));
  sessionStorage.setItem('viewType', userInfo.viewType ?? '');
  sessionStorage.setItem('viewCompany', JSON.stringify(userInfo.viewCompany));
};

const removeLocalUserState = (): void => {
  localStorage.removeItem('user');
  sessionStorage.removeItem('viewType');
  sessionStorage.removeItem('viewCompany');
};

export { getLocalUserState, setLocalUserState, removeLocalUserState };
