import { ref, computed, ComputedRef, Ref } from 'vue';
import dayjs from 'dayjs';
import { getFormattedDate } from '@/utils/composable/date';
import { ActivityLog } from '@/types/ioi';
import getUserFullName from '@/utils/getUserFullName';
import { formatPrice } from '@/utils/formatNumbers';
// eslint-disable-next-line camelcase
import { GetSingleIoiQuery, IndicationOfInterestFilter_ExpiresAt } from '@/types/graphql/ioi';
// eslint-disable-next-line camelcase

export const getActivityLogs = (
  indicationOfInterest: NonNullable<GetSingleIoiQuery>['indicationOfInterest'],
): ActivityLog[] => {
  if (!indicationOfInterest) return [];
  const logs: ActivityLog[] = [
    {
      type: 'submit',
      id: indicationOfInterest.id,
      data: {
        date: getFormattedDate(indicationOfInterest.createdAt, true, true),
        name: indicationOfInterest.clientCompany.legalName,
      },
    },
  ];

  if (indicationOfInterest.offers?.collection?.length) {
    const offers = indicationOfInterest.offers.collection;

    offers.forEach((offer) => {
      const offerCreator = offer?.createdBy || {
        firstName: '',
        lastName: '',
        middleName: '',
      };
      logs.push({
        type: 'setPrice',
        id: offer?.id || '',
        data: {
          date: getFormattedDate(offer?.createdAt || '', true, true),
          name: getUserFullName(
            offerCreator.firstName || '',
            offerCreator.lastName || '',
            offerCreator.middleName,
          ),
          price: formatPrice(offer?.price || 0, indicationOfInterest.currencyCode),
        },
      });

      if (offer?.answeredAt) {
        logs.push({
          type: offer.isAccepted ? 'acceptPrice' : 'rejectPrice',
          id: offer.id,
          data: {
            date: getFormattedDate(offer.answeredAt, true, true),
            price: offer.isAccepted
              ? formatPrice(offer.price, indicationOfInterest.currencyCode)
              : undefined,
          },
        });
      }
    });
  }

  if (indicationOfInterest.status === 'expired') {
    logs.push({
      type: 'expired',
      id: indicationOfInterest.id,
      data: {
        date: getFormattedDate(indicationOfInterest.expiresAt || '', true, true),
      },
    });
  }

  return logs;
};

export const classByStatus = (status: string) => {
  switch (status) {
    case 'accepted':
      return 'chip--success chip--w-fit-content';
    case 'expired':
      return 'chip--grey chip--w-fit-content';
    case 'rejected':
      return 'chip--error chip--w-fit-content';
    case 'in_progress':
      return 'chip--alert chip--w-fit-content';
    case 'new':
      return 'chip--alert chip--w-fit-content';
    case 'canceled':
      return 'chip--dark chip--w-fit-content';
    case 'pending':
      return 'chip--alert chip--w-fit-content';
    case 'placed':
      return 'chip--success chip--w-fit-content';
    default:
      return '';
  }
};

let instance: {
  currentPage: Ref<number>;
  currentFilter: Ref<string>;
};

export const usePaginationVariables = () => {
  if (instance) return instance;

  const currentPage = ref<number>(1);
  const currentFilter = ref<string>('');

  instance = { currentPage, currentFilter };
  return instance;
};

export const usePaginationVariablesComputed = () => {
  const isExpired: ComputedRef<boolean | undefined> = computed(() => {
    if (['in_progress'].includes(instance.currentFilter.value)) {
      return undefined;
    }
    if (['new'].includes(instance.currentFilter.value)) {
      return false;
    }
    if (['expired'].includes(instance.currentFilter.value)) {
      return true;
    }
    if (['accepted'].includes(instance.currentFilter.value)) {
      return true;
    }
    return true;
  });

  // eslint-disable-next-line camelcase
  const expiresAt: ComputedRef<IndicationOfInterestFilter_ExpiresAt[] | undefined> = computed(
    () => {
      if (['in_progress'].includes(instance.currentFilter.value)) {
        return [{ after: dayjs().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') }];
      }
      return undefined;
    },
  );

  return { isExpired, expiresAt };
};
