import { computed, ref } from 'vue';
import { ApolloClient, ApolloLink } from '@apollo/client/core';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { graphQLErrorHandler } from '@/utils/apiErrorHandler';
import { headers, endpoints, links, cacheConfig } from '@/config/http';

const getHeaders = computed(() => headers.value);
const squidexToken = ref<string | null | undefined>(localStorage.getItem('squidexToken'));

export const fetchSquidexToken = () =>
  new Promise<string>((resolve) => {
    const requestData = new URLSearchParams();
    requestData.append('scope', 'squidex-api');
    requestData.append('client_secret', `${import.meta.env.VITE_SQUIDEX_CLIENTSECRET}`);
    requestData.append('client_id', `${import.meta.env.VITE_SQUIDEX_CLIENTID}`);
    requestData.append('grant_type', 'client_credentials');
    fetch(`${import.meta.env.VITE_SQUIDEX_URL}/identity-server/connect/token`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: requestData,
      method: 'POST',
    }).then(async (res) => {
      const token = await res.json();
      localStorage.setItem('squidexToken', token.access_token);
      squidexToken.value = token.access_token;
      resolve(token.access_token);
    });
  });

const slink = new MultiAPILink({
  endpoints,
  ...links,
  getContext: (endpoint, getCurrentContext) => {
    if (endpoint === 'squidexEndPoint') {
      return {
        headers: {
          Authorization: `Bearer ${squidexToken.value}`,
          ...getCurrentContext().headers, // this is needed for overriding headers in specific query in components
        },
      };
    }
    return {
      headers: {
        ...getHeaders.value,
        ...getCurrentContext().headers, // this is needed for overriding headers in specific query in components
      },
    };
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  createHttpLink: () => createUploadLink(),
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors && graphQLErrors.length) {
    graphQLErrorHandler(graphQLErrors);
  }
});

const apolloClient = new ApolloClient({
  cache: cacheConfig,
  link: ApolloLink.from([errorLink, slink]),

  connectToDevTools: true,
});

export { apolloClient };
