import gql from 'graphql-tag';
import { ENERGY_EXCHANGE_ORDER_FIELDS } from '@/api/fragments';

export const CREATE_ORDER = gql`
  ${ENERGY_EXCHANGE_ORDER_FIELDS}
  mutation CreateOrder($input: createOrderInput!) @api(name: "authEndPoint") {
    createOrder(input: $input) {
      order {
        ...energyExchangeOrderFields
      }
    }
  }
`;

export const GET_ORDERS = gql`
  ${ENERGY_EXCHANGE_ORDER_FIELDS}
  query GetOrders($page: Int, $status: String, $status_list: [String], $order: [OrderFilter_order])
  @api(name: "authEndPoint") {
    orders(page: $page, status: $status, status_list: $status_list, order: $order) {
      collection {
        ...energyExchangeOrderFields
      }
      paginationInfo {
        itemsPerPage
        lastPage
        totalCount
      }
    }
  }
`;

export const GET_CLIENT_COMPANY_ZOHO_STATUS = gql`
  query GetClientCompanyZohoStatus($id: ID!) @api(name: "authEndPoint") {
    clientCompany(id: $id) {
      primarySubEntity {
        zohoAccount {
          id
          kycStatus
          status
        }
      }
    }
  }
`;

export const GET_EXCHANGE_PLUG_STATUS = gql`
  query GetExchangePlugStatus($id: ID!) @api(name: "authEndPoint") {
    exchangePlugStatus(id: $id) {
      _id
      createdAt
      id
      setBy {
        id
      }
      status
    }
  }
`;

export const GET_EXCHANGE_PLUG_STATUSES = gql`
  query GetExchangePlugStatuses($page: Int) @api(name: "authEndPoint") {
    exchangePlugStatuses(page: $page) {
      collection {
        _id
        createdAt
        id
        setBy {
          id
        }
        status
      }
      paginationInfo {
        itemsPerPage
        lastPage
        totalCount
      }
    }
  }
`;

export const UPDATE_EXCHANGE_PLUG_STATUS = gql`
  mutation UpdateExchangePlugStatus($input: updateExchangePlugStatusInput!)
  @api(name: "authEndPoint") {
    updateExchangePlugStatus(input: $input) {
      exchangePlugStatus {
        _id
        createdAt
        id
        setBy {
          id
        }
        status
      }
    }
  }
`;
/* ID field was added to this query as under client view companyRepresentativeProducts
always empty array without id field in query */
export const GET_REPRESENTATIVE_PRODUCTS = gql`
  query getRepresentativeProducts($id: ID!) @api(name: "authEndPoint") {
    account(id: $id) {
      id
      companyRepresentativeProducts {
        edges {
          node {
            id
            name
            abbreviation
          }
        }
      }
    }
  }
`;

export const GET_LANDING_PAGE_PRODUCTS = gql`
  query getLandingPageProducts($id: ID!, $productStatus: String = "pending")
  @api(name: "authEndPoint") {
    account(id: $id) {
      id
      email
      status
      roles
      companyRepresentativeProducts {
        edges {
          node {
            id
            name
            abbreviation
          }
        }
      }
      productAccessRequests(status: $productStatus) {
        collection {
          id
          product {
            id
            abbreviation
            name
          }
          status
        }
      }
    }
  }
`;

export const GET_ALL_PRODUCTS = gql`
  query getAllProducts @api(name: "authEndPoint") {
    products {
      edges {
        node {
          id
          name
          abbreviation
        }
      }
    }
  }
`;

export const UPDATE_PAYMENT_STATUS_ORDER = gql`
  mutation updatePaymentStatusOrder($input: updatePaymentStatusOrderInput!)
  @api(name: "authEndPoint") {
    updatePaymentStatusOrder(input: $input) {
      clientMutationId
      order {
        ...energyExchangeOrderFields
      }
    }
  }
`;

export const GET_PRODUCT_COMMODITIES = gql`
  query getProductCommodities($id: String) @api(name: "authEndPoint") {
    commodities(product_id: $id) {
      id
      name
    }
  }
`;
