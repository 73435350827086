<script lang="ts">
import { computed, defineComponent, getCurrentInstance, ref } from 'vue';
import { useRouter } from 'vue-router';
import { logErrorMessages } from '@vue/apollo-util';
import { storeToRefs } from 'pinia';
import { useGlobalStore, useUserStore, useNotificationStore } from '@/store';
import { NotificationType } from '@/types/notification';
import { SelectedProductType, UserRole } from '@/types/user';
import {
  useCreateProductAccessRequestMutation,
  useGetAccountQuery,
  useProductsQuery,
  ProductsQuery,
} from '@/types/graphql';
import { Step, StepsType } from '@/types/base-types';
import { getTranslationTerms } from '@/utils/composable/localeHelper';
import ClickButton from '@/components/base/ClickButton.vue';
import BaseSkeletonLoader from '@/components/base/BaseSkeletonLoader.vue';
import BaseLoader from '@/components/base/BaseLoader.vue';
import { MAIN_ROUTES } from '@/config/constants/routes';
import Calendly from '@/components/calendar/Calendly.vue';
// temporarily no-duplicates until we don't receive others images
// eslint-disable-next-line import/no-duplicates
import GO from '@/assets/images/products/GO.jpg';
// eslint-disable-next-line import/no-duplicates
import CFC from '@/assets/images/products/GO.jpg';
import I_REC from '@/assets/images/products/I-REC.jpg';
import F_GAS from '@/assets/images/products/F-Gas.jpg';
import BIOMETHANE_HEATING from '@/assets/images/products/BIOMETHANE_HEATING.jpg';
import BIOMETHANE_TRANSPORT from '@/assets/images/products/BIOMETHANE_TRANSPORT.jpg';
import EU_ETS from '@/assets/images/products/CCM.png';
import VCM from '@/assets/images/products/VCM.jpg';
import HBE from '@/assets/images/products/HBE.jpg';
import THG_Q from '@/assets/images/products/THG_Q.jpg';
import RTFC from '@/assets/images/products/RTFC.jpg';
import BIOFUELS from '@/assets/images/products/BIOFUELS.jpg';
import PPA from '@/assets/images/products/PPA.jpg';
import REFRIGERANT_GAS from '@/assets/images/products/REFRIGERANT_GAS.jpg';
import MANAGEMENT from '@/assets/images/products/Management.jpg';
import { routeToSavedUrl } from '@/utils/routeAndlogin';

export default defineComponent({
  name: 'ProductSwitcher',
  components: {
    ClickButton,
    Calendly,
    BaseSkeletonLoader,
    BaseLoader,
  },
  props: {
    calendarMode: {
      type: Boolean,
      default: false,
    },
    popupMode: {
      type: Boolean,
      default: false,
    },
    dataCy: {
      type: String,
      required: true,
    },
  },
  emits: ['change-step'],
  setup(props, ctx) {
    const images = ref({
      GO,
      BIOMETHANE_HEATING,
      BIOMETHANE_TRANSPORT,
      EU_ETS,
      VCM,
      HBE,
      THG_Q,
      RTFC,
      BIOFUELS,
      F_GAS,
      PPA,
      CFC,
      I_REC,
      REFRIGERANT_GAS,
      MANAGEMENT,
    });

    const calendlyId = ref('');
    const calendlyName = ref('');
    const calendlyEmail = ref('');

    const loadingRequests = ref<{ [productId: string]: boolean }>({});
    const { addNotification } = useNotificationStore();
    const { push, currentRoute } = useRouter();
    const cypressDataAttribute = `${getCurrentInstance()?.type.name}`;
    const getPageTerms = getTranslationTerms.bind(null, 'pages', 'productSwitcher');
    const getModalTerms = getTranslationTerms.bind(null, 'base', 'modals');
    const getProductName = getTranslationTerms.bind(null, 'products', 'product');
    const userStore = useUserStore();
    const { toggleProductSwitcherPopup } = useGlobalStore();

    const { getUserRole, userProfile, getSelectedProduct, getViewCompany, savedUrl } =
      storeToRefs(userStore);
    const { setSelectedProduct, logout } = useUserStore();

    const {
      result: productsResult,
      loading: loadingProducts,
      refetch: refetchProducts,
    } = useProductsQuery({ fetchPolicy: 'cache-and-network' });
    const {
      result: accountResult,
      loading: loadingAccount,
      refetch: refetchAccount,
    } = useGetAccountQuery(
      {
        id: userProfile.value.id as string,
      },
      { fetchPolicy: 'cache-and-network' },
    );

    const requestProductAccess = async (productId: string) => {
      loadingRequests.value[productId] = true;
      const {
        mutate: requestAccess,
        onDone,
        onError,
      } = useCreateProductAccessRequestMutation({
        variables: {
          input: {
            product: productId,
          },
        },
      });

      onDone(async () => {
        addNotification({
          message: getPageTerms('requestAccessNotification'),
          type: NotificationType.SUCCESS,
          showIcon: true,
        });
        loadingRequests.value[productId] = false;
        refetchAccount();
        refetchProducts();
      });

      onError(async (error) => {
        logErrorMessages(error);
        loadingRequests.value[productId] = false;
      });

      await requestAccess();
    };

    const isClientRole = computed(() => getUserRole.value === UserRole.ROLE_COMPANY_REPRESENTATIVE);
    const isBSuppRole = computed(() => getUserRole.value === UserRole.ROLE_BUSINESS_SUPPORT);
    const selectedProduct = computed(() => getSelectedProduct.value);
    const isSARole = computed(() => getUserRole.value === UserRole.ROLE_SUSTAINABILITY_ADVISOR);
    const steps = ref<StepsType>({
      step1: true,
      step2: false,
      error: false,
    });

    const availableProducts = computed(() => {
      if (isSARole.value) {
        return accountResult.value?.account?.sustainabilityAdvisorProducts?.edges?.map((item) => ({
          id: item?.node?.product?.id,
          abbreviation: item?.node?.product?.abbreviation,
          name: item?.node?.product?.name,
          isProductManager: item?.node?.isProductManager,
        }));
      }
      if (isClientRole.value) {
        return accountResult.value?.account?.companyRepresentativeProducts?.edges
          ?.map((item) => ({
            id: item?.node?.id,
            abbreviation: item?.node?.abbreviation,
            name: item?.node?.name,
            isProductManager: false,
          }))
          .filter(
            (availableProduct) =>
              !userStore.getViewCompany?.products ||
              userStore.getViewCompany.products.includes(availableProduct.id || ''),
          );
      }
      return [];
    });

    const pendingProducts = computed(() => {
      if (isClientRole.value) {
        return accountResult.value?.account?.productAccessRequests?.collection?.map((item) => ({
          id: item?.product?.id,
          abbreviation: item?.product?.abbreviation,
          name: item?.product?.name,
          isProductManager: false,
        }));
      }
      return [];
    });

    const compareProducts = (
      a: NonNullable<NonNullable<ProductsQuery['products']>['edges']>[0],
      b: NonNullable<NonNullable<ProductsQuery['products']>['edges']>[0],
    ) => {
      if (
        availableProducts.value?.map((p) => p.id).includes(a?.node?.id) &&
        availableProducts.value?.map((p) => p.id).includes(b?.node?.id)
      ) {
        return 0;
      }
      if (availableProducts.value?.map((p) => p.id).includes(b?.node?.id)) {
        return 1;
      }
      return -1;
    };

    const appointedSAs = computed(() => {
      if (accountResult.value) {
        const clientCompanyProducts = accountResult?.value?.account?.clientCompany?.products?.edges;
        return clientCompanyProducts
          ?.map((product) => {
            const appointedSustainabilityAdvisors =
              product?.node?.appointedSustainabilityAdvisors?.collection;
            const filteredASAs = appointedSustainabilityAdvisors?.filter((item) => item?.id) || [];
            if (filteredASAs?.length > 0) {
              return {
                productNode: product?.node,
                aSAs: filteredASAs,
              };
            }
            return null;
          })
          .filter((item) => item !== null);
      }
      return [];
    });

    const SAPerProduct = computed(
      () =>
        appointedSAs.value?.find(
          (item) => item?.productNode?.product?.id === selectedProduct.value?.id,
        )?.aSAs,
    );

    const productsQueryResult = computed(() => {
      if (productsResult.value) {
        if (isClientRole.value && productsResult.value?.products?.edges) {
          let products: NonNullable<NonNullable<ProductsQuery['products']>['edges']> = [
            ...productsResult.value.products.edges,
          ];
          if (props.calendarMode) {
            products = products
              .filter((item) =>
                appointedSAs.value
                  ?.map((p) => p?.productNode?.product?.id)
                  .includes(item?.node?.id),
              )
              ?.map((product) => ({
                node: {
                  ...product?.node,
                  available: availableProducts.value?.map((p) => p.id).includes(product?.node?.id),
                },
              }))
              .filter((item) => item.node.available) as NonNullable<
              NonNullable<ProductsQuery['products']>['edges']
            >;
          }
          return products?.sort(compareProducts);
        }
        return productsResult.value?.products?.edges;
      }
      return [];
    });

    const allowedModules = computed(() => {
      const modules: Record<string, string[]> = {};
      if (isClientRole.value) {
        accountResult?.value?.account?.clientCompany?.products?.edges?.forEach((product) => {
          if (product?.node?.product?.id) {
            modules[product.node.product.id] = product?.node?.allowedModules?.edges
              ? product.node.allowedModules.edges.map((module) => module?.node?.slug || '')
              : [];
          }
        });
      }
      return modules;
    });

    const defaultDashboardGroups = computed(() => {
      const defaultDashboard: Record<string, string> = {};
      if (isClientRole.value) {
        accountResult?.value?.account?.clientCompany?.products?.edges?.forEach((product) => {
          if (product?.node?.product?.id) {
            defaultDashboard[product.node.product.id] = product?.node?.defaultDashboardGroup
              ? product?.node?.defaultDashboardGroup?.slug
              : '';
          }
        });
      }
      return defaultDashboard;
    });

    const productClasses = computed(() => {
      const classes: Record<string, string> = {};
      productsQueryResult.value?.forEach((product) => {
        if (product?.node?.abbreviation) {
          classes[product.node.abbreviation] = `product-card-${product.node.abbreviation}`;
        }
      });
      return classes;
    });

    const userName = computed(
      () => `${userProfile.value.firstName} ${userProfile.value?.lastName}`,
    );

    const changeStep = (step: Step) => {
      ctx.emit('change-step', step);
      Object.keys(steps.value).forEach((key: string) => {
        steps.value[key] = key === step;
      });
    };

    const selectProduct = async (pd: SelectedProductType) => {
      if (!pd.available) {
        return;
      }

      setSelectedProduct(pd);

      if (props.calendarMode) {
        changeStep(Step.Two);
      } else if (props.popupMode && !props.calendarMode) {
        toggleProductSwitcherPopup(false);
        if (currentRoute.value.fullPath !== `/${MAIN_ROUTES.DASHBOARD.name}`) {
          await push({ name: MAIN_ROUTES.DASHBOARD.name });
        }
      } else if (savedUrl.value.pathname) {
        await routeToSavedUrl();
      } else {
        await push({ name: MAIN_ROUTES.DASHBOARD.name });
      }
    };

    const clientHasOneProduct = computed(() => {
      if (productsQueryResult.value?.length === 1) {
        selectProduct(productsQueryResult.value[0]?.node as SelectedProductType);
        return true;
      }
      return false;
    });

    const getCalendlyId = async (
      id: string,
      firstName: string,
      middleName: string,
      lastName: string,
      email: string,
    ) => {
      if (id) {
        calendlyId.value = id;
        calendlyEmail.value = email.toLowerCase();
        calendlyName.value = `${firstName}%20${middleName ? `${middleName}%20` : ''}${lastName}`;
        changeStep(Step.Two);
      } else {
        changeStep(Step.Error);
      }
    };

    const logoutFromProductSwitcher = async () => {
      logout();
      await push({ name: MAIN_ROUTES.AUTH.name });
    };

    const columnWidth = computed(() => {
      switch (
        isSARole.value ? availableProducts.value?.length : productsQueryResult.value?.length
      ) {
        case 1:
          return '100%';
        case 2:
          return '49%';
        case 3:
          return '32%';
        case 4:
          return '23%';
        default:
          return '19%';
      }
    });

    const resetSelectedProduct = async () => {
      setSelectedProduct(null);
      if (props.popupMode) {
        toggleProductSwitcherPopup(false);
      }
      await push({ name: MAIN_ROUTES.DASHBOARD.name });
    };

    return {
      selectProduct,
      logoutFromProductSwitcher,
      changeStep,
      productsQueryResult,
      availableProducts,
      userName,
      selectedProduct,
      getModalTerms,
      getProductName,
      loadingAccount,
      loadingProducts,
      isSARole,
      isClientRole,
      isBSuppRole,
      clientHasOneProduct,
      allowedModules,
      defaultDashboardGroups,
      productClasses,
      steps,
      images,
      columnWidth,
      appointedSAs,
      resetSelectedProduct,
      cypressDataAttribute,
      SAPerProduct,
      calendlyId,
      calendlyName,
      calendlyEmail,
      getCalendlyId,
      Step,
      requestProductAccess,
      pendingProducts,
      loadingRequests,
      getViewCompany,
      getPageTerms,
      productsResult,
      getUserRole,
      accountResult,
    };
  },
});
</script>

<template>
  <div class="main-wrapper">
    <div v-if="!popupMode" class="header">
      <div class="text-info">
        <h1>
          {{ getPageTerms('h1') }}
        </h1>
        <h2>{{ getPageTerms('chooseProduct') }}</h2>
      </div>
      <ClickButton
        :data-cy="`${cypressDataAttribute}-${dataCy}LogoutFromProductSwitcher`"
        icon="logout"
        status="outlined-primary icon-right-side logout"
        @click="logoutFromProductSwitcher"
      >
        {{ getPageTerms('logout') }}
      </ClickButton>
    </div>
    <template v-if="calendarMode && (loadingAccount || loadingProducts)">
      <div class="user-buttons">
        <div v-for="n in 3" :key="n" class="choose-user-button">
          <BaseSkeletonLoader :is-rounded="true" max-width="3.4rem" min-height="3.4rem" />
          <BaseSkeletonLoader max-width="8rem" max-height="1.5rem" />
        </div>
      </div>
      <BaseSkeletonLoader
        max-height="1.5rem"
        max-width="80%"
        :margin-array="['1rem', '0', '0.5rem', '0']"
      />
    </template>
    <template v-else-if="loadingAccount || loadingProducts">
      <div class="product-cards">
        <BaseSkeletonLoader v-for="n in 14" :key="n" :radius="'0.5rem'" />
      </div>
    </template>
    <template v-else>
      <div v-if="availableProducts && isSARole" class="product-cards">
        <div
          v-for="product in availableProducts"
          :key="product.id"
          :class="['product-card', { active: selectedProduct?.id === product.id }]"
          :data-cy="`ChooseProductSaRole${product.abbreviation}`"
          @click="
            selectProduct({
              id: product.id ?? '',
              abbr: product.abbreviation ?? '',
              name: product.name ?? '',
              isProductManager: product.isProductManager,
              available: true,
            })
          "
        >
          <img
            :src="images[product?.abbreviation as keyof typeof images]"
            alt="some-alt"
            class="bg-img"
          />
          <div class="clickable-wrapper">
            <div class="product-name">
              {{ getProductName(product?.abbreviation ?? '') }}
            </div>
          </div>
        </div>
      </div>
      <template v-if="calendarMode && steps.step1">
        <div class="user-buttons">
          <div
            v-for="SA in SAPerProduct"
            :key="SA?.id"
            :data-cy="`${cypressDataAttribute}-${dataCy}ChooseSAButton`"
            class="choose-user-button"
            @click="
              getCalendlyId(
                SA?.calendlyId ?? '',
                SA?.firstName ?? '',
                SA?.middleName ?? '',
                SA?.lastName ?? '',
                SA?.email ?? '',
              )
            "
          >
            <img
              v-if="SA?.avatar?.contentUrl"
              alt="avatar"
              :src="SA?.avatar?.contentUrl"
              class="user__avatar"
            />
            <div v-else class="user__avatar--unavailable">
              {{ `${SA?.firstName?.[0]} ${SA?.lastName?.[0]}` }}
            </div>
            <div class="user__avatar__text">
              {{
                `${SA?.firstName}
                ${SA?.lastName}`
              }}
            </div>
          </div>
        </div>
      </template>
      <template v-if="calendarMode && steps.step2">
        <p
          v-if="!clientHasOneProduct"
          class="steps clickable"
          data-cy="ReturnToStep1"
          @click="changeStep(Step.One)"
        >
          {{ getModalTerms('scheduleAppointment.step2') }}
        </p>
        <p
          v-else
          class="steps clickable"
          data-cy="ReturnToStep1WhenOneProduct"
          @click="changeStep(Step.One)"
        >
          {{ getModalTerms('scheduleAppointment.step2hasOneProduct') }}
        </p>
        <Calendly
          :email="calendlyEmail"
          :link="`https://calendly.com/${calendlyId}`"
          :name="calendlyName"
          :is-full-size="true"
        />
      </template>
      <template v-if="calendarMode && steps.error">
        <p class="steps clickable" data-cy="ScheduleAppointmentStep2" @click="changeStep(Step.One)">
          {{ getModalTerms('scheduleAppointment.step2') }}
        </p>
        <div class="no-account-text">
          <p>
            {{ getModalTerms('scheduleAppointment.error') }}
          </p>
        </div>
      </template>
      <div
        v-else-if="!calendarMode && productsQueryResult?.length && steps.step1 && !isSARole"
        class="product-cards"
      >
        <div
          v-if="isBSuppRole"
          :class="['product-card', 'product-card-management', { active: !selectedProduct }]"
          data-cy="RetSelectedProduct"
          @click="resetSelectedProduct"
        >
          <img :src="images['MANAGEMENT']" alt="some-alt" class="bg-img" />
          <div class="clickable-wrapper">
            <div class="product-name">
              {{ getProductName('MANAGEMENT') }}
            </div>
          </div>
        </div>
        <div
          v-for="product in productsQueryResult"
          :key="product?.node?.id"
          :class="[
            {
              'not-available':
                !availableProducts?.map((p) => p.id).includes(product?.node?.id) && isClientRole,
            },
            productClasses[product?.node?.abbreviation as keyof typeof productClasses],
            { active: selectedProduct?.id === product?.node?.id },
            { 'client-view': getViewCompany },
          ]"
          :data-cy="`ChooseProductCrBsRole${product?.node?.abbreviation}`"
          class="product-card"
          @click="
            selectProduct({
              id: product?.node?.id ?? '',
              abbr: product?.node?.abbreviation ?? '',
              name: product?.node?.name ?? '',
              allowedModules: allowedModules[product?.node?.id ?? ''],
              defaultDashboardGroup: defaultDashboardGroups[product?.node?.id ?? ''],
              isProductManager: accountResult?.account?.sustainabilityAdvisorProducts?.edges?.find(
                (item) => item?.node?.product?.id === product?.node?.id,
              )?.node?.isProductManager,
              available:
                availableProducts?.map((p) => p.id).includes(product?.node?.id) || isBSuppRole,
            })
          "
        >
          <img
            :src="images[product?.node?.abbreviation as keyof typeof images]"
            alt="some-alt"
            class="bg-img"
          />
          <div
            v-if="availableProducts?.map((p) => p.id).includes(product?.node?.id) || !isClientRole"
            class="clickable-wrapper"
          >
            <div class="product-name">
              {{ getProductName(product?.node?.abbreviation ?? '') }}
            </div>
          </div>
          <div v-else class="clickable-wrapper">
            <div class="product-name">
              {{ getProductName(product?.node?.abbreviation ?? '') }}
            </div>
            <div class="custom-button__wrapper">
              <BaseLoader v-if="loadingRequests[product?.node?.id ?? '']" variant="light" />
              <div
                v-else-if="
                  !pendingProducts?.map((p) => p.id).includes(product?.node?.id) && !getViewCompany
                "
                class="custom-button"
                @click="requestProductAccess(product?.node?.id ?? '')"
              >
                {{ getPageTerms('requestAccess') }}
              </div>
              <div v-else-if="!getViewCompany" class="custom-button disabled">
                {{ getPageTerms('requestedAccess') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <p v-if="calendarMode && steps.step1" class="tip">
        {{ getModalTerms('scheduleAppointment.tip1') }}
      </p>
      <p v-if="calendarMode && steps.step2" class="tip">
        {{ getModalTerms('scheduleAppointment.tip2') }}
      </p>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  flex-direction: column;

  @media screen and (min-width: $mobile-plus) {
    flex-direction: row;
  }

  p {
    font-weight: 300;
    font-size: remCalc(26);
    margin-top: remCalc(42);
    color: var(--color-main);
  }
}

.no-account-text {
  margin-top: remCalc(16);
  font-size: remCalc(16);
  @extend %fw-400;
  color: var(--color-light-grey);
  text-align: center;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  overflow: visible;
  padding: var(--container-padding);

  @media screen and (min-width: $mobile-plus) {
    padding: remCalc(41) remCalc(67) remCalc(46);
    height: 100vh;
  }

  .tip {
    margin-top: remCalc(15);
  }

  .bg-img {
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.logout {
  height: max-content;
  width: fit-content;

  @media screen and (min-width: $mobile-plus) {
    width: auto;
  }

  :deep(svg) {
    fill: var(--color-primary);
  }
}

.user-buttons {
  display: flex;
  flex-wrap: wrap;
  margin: remCalc(24) 0;
  padding: var(--space-3xs);
  gap: var(--space-xs);

  .choose-user-button {
    display: flex;
    align-items: center;
    column-gap: var(--space-3xs);
    cursor: pointer;
    color: var(--color-secondary);
    min-width: remCalc(260);
  }

  .btn + .btn {
    margin: 0;
  }
}

.user__avatar {
  width: remCalc(55);
  height: remCalc(55);
  border-radius: 50%;

  &--unavailable {
    @extend %fw-300;

    display: flex;
    flex: 0 0 remCalc(55);
    height: remCalc(55);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-secondary);
    font-size: var(--space-sm);
  }

  &__text {
    @extend %fw-700;
    color: var(--color-secondary);
    font-size: var(--text-xl);
    line-height: var(--line-xxl);
  }
}

.text-info {
  h1,
  h2 {
    font-size: remCalc(40);
    line-height: 1;
    color: var(--color-primary);
    font-weight: 600;
  }

  h1 {
    margin-bottom: var(--space-3xs);

    @media screen and (min-width: $mobile-plus) {
      margin-bottom: 0;
    }
  }

  h2 {
    font-size: remCalc(28);
    line-height: 1;
    font-weight: 500;
    margin-bottom: var(--space-xs);

    @media screen and (min-width: $mobile-plus) {
      margin-bottom: 0;
    }
  }
}

.steps {
  &.clickable {
    cursor: pointer;

    &:before {
      content: url('data:image/svg+xml;charset=utf-8,%3Csvg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"%3E%3Cpath d="M8 1.91L6.47773 0.5L0 6.5L6.47773 12.5L8 11.09L3.05533 6.5L8 1.91Z" fill="%23061137"%3E%3C/path%3E%3C/svg%3E');
      position: relative;
      margin-right: remCalc(10);
    }
  }

  margin: remCalc(20) 0 0 remCalc(10);
}

.product-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  grid-column-gap: 12px;
  width: 100%;
  height: auto;
  padding-top: remCalc(46);
  margin: 0 auto;

  @media screen and (min-width: $mobile-plus) {
    display: grid;
    grid-template-columns: repeat(auto-fit, v-bind(columnWidth));
    grid-row-gap: 24px;
    height: 100%;
  }

  &.calendar-mode {
    height: auto;
    padding-top: remCalc(10);

    .product-card {
      min-height: initial;
      height: 180px;

      &.active {
        border: none;
        box-shadow: none;
      }

      &.not-available {
        display: none;
      }
    }
  }

  .product-card {
    box-shadow: 0 4px 4px 0 var(--color-notification-notify-2);
    position: relative;
    border-radius: 10px;
    min-height: 7rem;
    overflow: hidden;
    max-height: remCalc(200);
    cursor: pointer;
    //fix for safari border radius with overflow hidden
    isolation: isolate;
    margin-bottom: var(--space-xs);

    @media screen and (min-width: $mobile-plus) {
      min-height: 100%;
    }

    &.active {
      border: 3px solid #3137fdbf;
      box-shadow: 0px 24px 32px #3137fd40;
      .product-name {
        @extend %fw-700;
      }

      .role-bsupp & {
        border: 3px solid #8d0220;
        box-shadow: 0px 24px 32px #8d022040;
      }

      .role-advisor & {
        border: 3px solid #174e0dbf;
        box-shadow: 0px 24px 32px #174e0d40;
      }
    }

    &.not-available {
      .custom-button__wrapper {
        transform: translateY(100%);
      }

      .product-name {
        transform: translateY(0%);
      }

      cursor: default;
      img {
        filter: grayscale(1) blur(5px);
        transition: 0.3s ease-in-out filter;
      }
      &:not(.client-view):hover {
        img {
          filter: grayscale(0) blur(5px);
        }

        .custom-button__wrapper {
          transform: translateY(0%);
        }

        .product-name {
          transform: translateY(-100%);
        }
      }
    }

    &:not(.not-available):hover {
      .clickable-wrapper:before {
        background-color: var(--color-primary);
      }
    }

    img {
      filter: blur(0px);
      transition: filter 0.3s ease-in-out;
    }
    &:not(.client-view):hover {
      img {
        filter: blur(5px);
      }
    }

    &-management {
      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .clickable-wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: 100%;
      z-index: 1;

      .product-name {
        padding: 0 remCalc(16);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        color: var(--color-light);
        font-size: remCalc(16);
        line-height: 1.2;
        flex-direction: column;
        transition: 0.3s ease-in-out;
        z-index: 2;
      }

      .custom-button {
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: remCalc(10);
        text-align: center;
        padding: remCalc(8) remCalc(12);
        border-radius: 30px;
        background-color: var(--color-shade-8);
        color: var(--color-main);
        font-size: remCalc(16);
        width: max-content;
        transition:
          0.3s linear background-color,
          0.3s linear color;
        line-height: 1;

        &__wrapper {
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.3s ease-in-out;
          padding: 0 0.5rem;
        }
        &.disabled {
          background-color: var(--color-shade-3);
          color: var(--color-shade-8);
          cursor: default;
          &:hover {
            background-color: var(--color-shade-2);
            color: var(--color-shade-8);
          }
        }

        &:hover {
          background-color: var(--color-main);
          color: var(--color-shade-8);
        }
      }

      &:before {
        pointer-events: none;
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        opacity: 0.5;
        transition:
          0.3s linear background-color,
          0.3s linear opacity;
        background-color: var(--color-main);
        z-index: 1;
      }
    }
  }
}
</style>
