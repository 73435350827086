import { createApp, h, provide } from 'vue';
import { ApolloClients, provideApolloClient } from '@vue/apollo-composable';
import { plugin, defaultConfig } from '@formkit/vue';
import FloatingVue from 'floating-vue';
import * as Sentry from '@sentry/vue';
import { createGtm } from '@gtm-support/vue-gtm';
import VueSanitize from 'vue-sanitize-directive';
import VueApexCharts from 'vue3-apexcharts';
import App from '@/App.vue';
import router from '@/router';
import { apolloClient } from '@/services/apollo';
import { pinia } from '@/store';
import { i18n } from '@/utils/composable/localeHelper';
import '@/assets/styles/main.scss';
import { useReactiveDataLayer } from '@/utils/dataLayer';

const app = createApp({
  setup() {
    provide(ApolloClients, {
      default: apolloClient,
    });
  },
  render: () => h(App),
});

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_VERSION,
  release: import.meta.env.VITE_VERSION,
  integrations: [],
});

useReactiveDataLayer();

app
  .use(i18n)
  .use(router)
  .use(pinia)
  .use(FloatingVue, {
    themes: {
      'nav-button': {},
      tooltip: { triggers: ['hover', 'focus', 'touch', 'click'] },
      menu: {
        $extend: 'dropdown',
        disposeTimeout: 0,
        distance: 0,
        delay: {
          show: 200,
          hide: 150,
        },
      },
    },
  })
  .use(
    createGtm({
      id: 'GTM-MSX4QMX',
      enabled: import.meta.env.VITE_ENVIRONMENT === 'prod',
      debug: import.meta.env.VITE_ENVIRONMENT !== 'prod',
      vueRouter: router,
      loadScript: true,
    }),
  )
  .use(VueSanitize)
  .use(VueApexCharts)
  .use(plugin, defaultConfig);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.Apex.chart = { fontFamily: 'Sk-Modernist' };

app.directive('click-outside', {
  beforeMount: (el, binding) => {
    // eslint-disable-next-line
    el.clickOutsideEvent = (event: Event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event.target);
      }
    };
    document.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document.removeEventListener('click', el.clickOutsideEvent);
  },
});

app.mount('#app');

provideApolloClient(apolloClient);

// eslint-disable-next-line no-console
console.log(`VERSION: ${import.meta.env.VITE_VERSION}`);
