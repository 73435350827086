<script lang="ts">
import { computed, defineComponent, getCurrentInstance } from 'vue';
import BaseIcon from '@/components/base/BaseIcon.vue';
import { useUserStore } from '@/store';

export default defineComponent({
  name: 'NavButton',
  components: {
    BaseIcon,
  },
  props: {
    path: { type: String, default: '' },
    label: { type: String, required: true },
    status: { type: String, required: true },
    name: { type: String, required: true },
    icon: { type: String, default: '', required: false },
    linkType: { type: String, default: '' },
    type: { type: String, required: true },
    groupItem: { type: Boolean, default: false },
    showText: { type: Boolean, default: false },
    iconSize: { type: Number, default: 32 },
  },
  setup(props) {
    const userStore = useUserStore();
    const { setClientCompany } = userStore;
    const cypressDataAttribute = `${getCurrentInstance()?.type.name}`;
    const link = computed(() => {
      const namedLink = { name: props.name };
      const fullPath = props.path;

      if (fullPath && fullPath.length > 0) return fullPath;

      return namedLink;
    });

    const linkClicked = () => {
      // what is client company and why do we reset it if someone presses a menu item?
      setClientCompany(null);
    };

    return {
      link,
      linkClicked,
      cypressDataAttribute,
      setClientCompany,
    };
  },
});
</script>

<template>
  <router-link
    v-if="status !== 'disabled'"
    :class="[
      'nav-mb',
      `nav-mb--${type}--${status}`,
      `nav-mb--${type}`,
      { 'nav-mb--group': groupItem },
      { 'nav-mb--show-text': showText },
    ]"
    disabled
    data-unit-test="active-link"
    :to="link"
    :replace="linkType === 'page'"
    exact
    @click.prevent="linkClicked"
  >
    <BaseIcon
      :icon-name="icon"
      :data-cy="`${cypressDataAttribute}-${label}`"
      :size="iconSize"
      :class="`nav-mb__icon--${type}`"
    />
    <span class="nav-mb__text" :data-cy="`${cypressDataAttribute}-${label}-${status}`">{{
      label
    }}</span>

    <BaseIcon
      v-if="groupItem"
      icon-name="chevron--right"
      :data-cy="`${cypressDataAttribute}Arrow`"
      :size="20"
      class="nav-mb__arrow"
    />
  </router-link>
  <div
    v-else
    :class="[
      'nav-mb',
      `nav-mb--${type}`,
      { 'nav-mb--group': groupItem },
      { 'nav-mb--show-text': showText },
    ]"
  >
    <BaseIcon
      :icon-name="icon"
      :data-cy="`${cypressDataAttribute}-${label}`"
      :size="iconSize"
      :class="`nav-mb__icon--${type}`"
    />
    <span class="nav-mb__text" :data-cy="`${cypressDataAttribute}-${label}-${status}`">{{
      label
    }}</span>
    <BaseIcon
      v-if="groupItem"
      icon-name="chevron--right"
      :data-cy="`${cypressDataAttribute}Arrow`"
      :size="20"
      class="nav-mb__arrow"
    />
  </div>
</template>

<style lang="scss" scoped>
.nav-mb {
  @extend %font-navigation-item;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-font-inv);
  text-decoration: none;
  position: relative;
  cursor: pointer;

  .main-navbar:hover & {
    justify-content: start;
  }

  &__icon {
    .router-link-exact-active & {
      color: var(--color-font-inv);
    }
  }

  &__text {
    line-height: 1.1;
    font-size: var(--text-s);
  }

  &__arrow {
    position: absolute;
    right: -4px;
    margin-left: auto;
    transition: transform 0.3s ease-in-out;

    .iconbar-expanded & {
      position: relative;
      right: initial;
    }

    .navbar__list-group.active & {
      opacity: 0;
    }
    .navbar__list-group:has(.navbar__sublist-static) & {
      transform: rotate(90deg);
    }
    .iconbar-expanded .navbar__list-group.active & {
      opacity: 1;
    }
  }

  &--icon {
    justify-content: center;
    border-radius: remCalc(80);
    flex-direction: row;
    width: 100%;
    padding: 0.5rem;
    transition:
      width 0.25s ease-out,
      background 0.25s ease-in-out;

    &:hover {
      background: var(--color-primary-hover);
    }

    .nav-mb__text {
      @extend %fw-400;
      width: 0;
      min-width: 0px;
      overflow: hidden;
      white-space: nowrap;
      transition: width ease-in-out 0.3s;
    }

    &.nav-mb--show-text {
      .nav-mb__text {
        display: block;
      }
    }

    .iconbar-expanded & {
      justify-content: flex-start;

      .nav-mb__text {
        animation: slide-animation 0.25s cubic-bezier(1, 1, 1, 1);
        width: 8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        margin-left: var(--space-3xs);

        @keyframes slide-animation {
          0% {
            overflow: hidden;
            width: 0;
          }
          100% {
            overflow: hidden;
            width: 8rem;
          }
        }
      }

      .nav-mb__arrow {
        display: block;
      }
    }

    &.router-link-exact-active {
      background: var(--color-menu-active);
      color: var(--color-font);
      .nav-mb__icon,
      .nav-mb__text {
        color: var(--color-primary);
      }
    }

    .nav-mb__icon {
      flex: 0 0 auto;
      // overwrite inline style
      width: remCalc(20) !important;
      height: remCalc(20) !important;
      display: inherit;
    }

    .nav-mb__arrow {
      // overwrite inline style
      width: remCalc(16) !important;
      height: remCalc(16) !important;
    }

    &--disabled {
      cursor: default;

      &:hover {
        background: transparent;
      }
    }
  }

  &--group {
    cursor: auto;
  }

  .iconbar-expanded &--group {
    cursor: pointer;
  }

  &--horizontal,
  &--dashboard {
    height: remCalc(40);

    padding-right: var(--space-xs);
    color: var(--color-font-grey);
    text-align: left;
    white-space: nowrap;

    .nav-mb__icon--horizontal {
      width: remCalc(24);
      height: remCalc(24);

      min-width: remCalc(20);
      min-height: remCalc(20);
      margin-right: remCalc(10);
      margin-left: var(--space-xxs);
    }
    .nav-mb__icon--dashboard {
      width: remCalc(84);
      height: remCalc(84);

      min-width: remCalc(20);
      min-height: remCalc(20);
      margin-right: remCalc(10);
      margin-left: var(--space-xxs);
    }

    &.router-link-exact-active {
      box-shadow: 0 2px 2px rgba(69, 66, 65, 0.2);

      .nav-mb__icon {
        color: var(--color-primary);
      }
    }

    &--disabled {
      color: var(--color-font-disabled);
      cursor: default;

      &:hover {
        background: var(--color-shade-9);
        color: var(--color-font-disabled);
      }
    }

    &:hover {
      background: var(--color-primary-active);
      color: var(--color-primary-font);
    }
  }

  &--hover-button {
    color: var(--color-font);
    border-radius: 5rem;

    &.router-link-exact-active {
      background: var(--color-shade-5);
    }

    &:hover {
      background: var(--color-shade-6);
      color: var(--color-font);
    }

    .nav-mb__icon.nav-mb__icon--icon {
      margin-right: var(--space-4xs);
      width: remCalc(5);
    }
  }
}

.nav-mb--icon {
  flex-direction: row;
}

.navbar__list-group:not(:has(.navbar__sublist-static)) {
  .active-child-route .nav-mb {
    background: var(--color-menu-active);
    color: var(--color-font);
  }
}
</style>
