import { ref, computed } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

// Quote status
export type QuoteState = 'prices' | 'confirmOrder' | 'disabled' | 'outsideHours';
export type TOption = 't0' | 't2' | 't4' | 't6' | 't8' | 't10';
export type BuyOrSell = 'buy' | 'sell';
export type LatestCreatedOrder = { orderNumber: string; status: string };

export const quoteState = ref<QuoteState>('prices');
export const quoteLoadingState = ref<boolean>(false);
export const selectedT = ref<TOption>('t0');
export const buyOrSell = ref<BuyOrSell>('buy');
export const kycModalOpen = ref<boolean>(false);
export const latestCreatedOrder = ref<LatestCreatedOrder | null>(null);

export function setQuoteState(newStatus: QuoteState) {
  quoteState.value = newStatus;
}

export function setQuoteLoadingState(quoteLoadingStateParam: boolean) {
  quoteLoadingState.value = quoteLoadingStateParam;
}

export function setLatestCreatedOrder(newOrder: LatestCreatedOrder | null) {
  latestCreatedOrder.value = newOrder;
}

export const setSelectedT = (tOption: TOption) => (selectedT.value = tOption);
export const toggleBuyOrSell = () => {
  buyOrSell.value = buyOrSell.value === 'buy' ? 'sell' : 'buy';
};
export const setBuyOrSell = (buyOrSellOption: BuyOrSell) => (buyOrSell.value = buyOrSellOption);

export const isPricesState = computed(() => quoteState.value === 'prices');
export const isConfirmOrderState = computed(() => quoteState.value === 'confirmOrder');
export const isDisabledState = computed(() => quoteState.value === 'disabled');
export const isOutsideHoursState = computed(() => {
  const hours = dayjs().tz('Europe/Amsterdam').hour();
  const minutes = dayjs().tz('Europe/Amsterdam').minute();
  return hours < 9 || hours > 17 || (hours === 17 && minutes > 30);
});

// sorting and filtering of orders
export const activeSortFieldOrderConfirmations = ref<string>('createdAt'); // Default sorting field
export const sortOrderOrderConfirmations = ref<'ASC' | 'DESC'>('ASC');
export const selectedFilterOrderConfirmations = ref<string>('');
