import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from 'vue';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Iterable: any;
  Upload: any;
};

export type Account = Node & {
  __typename?: 'Account';
  _id?: Maybe<Scalars['String']>;
  activatedAt?: Maybe<Scalars['String']>;
  appointedSustainabilityAdvisorProducts?: Maybe<ClientCompanyProductCursorConnection>;
  approvedAt?: Maybe<Scalars['String']>;
  attendeeCalendarEvents?: Maybe<CalendarEventPageConnection>;
  avatar?: Maybe<Avatar>;
  bio?: Maybe<Scalars['String']>;
  calendarId?: Maybe<Scalars['String']>;
  calendlyId?: Maybe<Scalars['String']>;
  clientCompany?: Maybe<ClientCompany>;
  companyRepresentativeProducts?: Maybe<ProductCursorConnection>;
  createdAt: Scalars['String'];
  createdInAppEvents?: Maybe<InAppEventPageConnection>;
  deletedAt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inviter?: Maybe<Account>;
  isConnectedGoogleCalendar: Scalars['Boolean'];
  isCopywriter: Scalars['Boolean'];
  isTwoFactorEnabled: Scalars['Boolean'];
  lastAuthenticatedAt?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  organizerCalendarEvents?: Maybe<CalendarEventPageConnection>;
  phoneNumber?: Maybe<Scalars['String']>;
  productAccessRequests?: Maybe<ProductAccessRequestPageConnection>;
  rejectionReason?: Maybe<Scalars['String']>;
  roles: Scalars['Iterable'];
  /** Can be 'active' or 'inactive'. */
  status: Scalars['String'];
  submittedForApprovalAt?: Maybe<Scalars['String']>;
  submittedForApprovalBy?: Maybe<Account>;
  sustainabilityAdvisorProducts?: Maybe<AccountSustainabilityAdvisorProductCursorConnection>;
  timezone: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['String'];
};


export type AccountAppointedSustainabilityAdvisorProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AccountAttendeeCalendarEventsArgs = {
  dateRange?: InputMaybe<Array<InputMaybe<CalendarEventFilter_DateRange>> | InputMaybe<CalendarEventFilter_DateRange>>;
  eventDate?: InputMaybe<Scalars['String']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type AccountCompanyRepresentativeProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AccountCreatedInAppEventsArgs = {
  dateRange?: InputMaybe<Array<InputMaybe<InAppEventFilter_DateRange>> | InputMaybe<InAppEventFilter_DateRange>>;
  eventDate?: InputMaybe<Scalars['String']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type AccountOrganizerCalendarEventsArgs = {
  dateRange?: InputMaybe<Array<InputMaybe<CalendarEventFilter_DateRange>> | InputMaybe<CalendarEventFilter_DateRange>>;
  eventDate?: InputMaybe<Scalars['String']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type AccountProductAccessRequestsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<ProductAccessRequestFilter_Order>> | InputMaybe<ProductAccessRequestFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type AccountSustainabilityAdvisorProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type AccountFilter_Order = {
  approvedAt?: InputMaybe<Scalars['String']>;
  clientCompany_primarySubEntity_legalName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
};

export type AccountNotification = Node & {
  __typename?: 'AccountNotification';
  account: Account;
  id: Scalars['ID'];
  read: Scalars['Boolean'];
};

/** Cursor connection for AccountNotification. */
export type AccountNotificationCursorConnection = {
  __typename?: 'AccountNotificationCursorConnection';
  edges?: Maybe<Array<Maybe<AccountNotificationEdge>>>;
  pageInfo: AccountNotificationPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of AccountNotification. */
export type AccountNotificationEdge = {
  __typename?: 'AccountNotificationEdge';
  cursor: Scalars['String'];
  node?: Maybe<AccountNotification>;
};

/** Information about the current page. */
export type AccountNotificationPageInfo = {
  __typename?: 'AccountNotificationPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type AccountNotificationSetting = Node & {
  __typename?: 'AccountNotificationSetting';
  id: Scalars['ID'];
  module: Scalars['String'];
  sendEmail: Scalars['Boolean'];
  sendPush: Scalars['Boolean'];
};

/** Cursor connection for AccountNotificationSetting. */
export type AccountNotificationSettingCursorConnection = {
  __typename?: 'AccountNotificationSettingCursorConnection';
  edges?: Maybe<Array<Maybe<AccountNotificationSettingEdge>>>;
  pageInfo: AccountNotificationSettingPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of AccountNotificationSetting. */
export type AccountNotificationSettingEdge = {
  __typename?: 'AccountNotificationSettingEdge';
  cursor: Scalars['String'];
  node?: Maybe<AccountNotificationSetting>;
};

export type AccountNotificationSettingInputType = {
  /** Module name. For example, User Management, RFQ, Trades etc. */
  module: Scalars['String'];
  /** Flag indicates the user's desire to receive email notifications. */
  sendEmail: Scalars['Boolean'];
  /** Flag indicates the user's desire to receive push notifications. */
  sendPush: Scalars['Boolean'];
};

/** Information about the current page. */
export type AccountNotificationSettingPageInfo = {
  __typename?: 'AccountNotificationSettingPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Page connection for Account. */
export type AccountPageConnection = {
  __typename?: 'AccountPageConnection';
  collection?: Maybe<Array<Maybe<Account>>>;
  paginationInfo: AccountPaginationInfo;
};

/** Information about the pagination. */
export type AccountPaginationInfo = {
  __typename?: 'AccountPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type AccountSustainabilityAdvisorProduct = Node & {
  __typename?: 'AccountSustainabilityAdvisorProduct';
  account?: Maybe<Account>;
  id: Scalars['ID'];
  isProductManager: Scalars['Boolean'];
  product?: Maybe<Product>;
};

/** Cursor connection for AccountSustainabilityAdvisorProduct. */
export type AccountSustainabilityAdvisorProductCursorConnection = {
  __typename?: 'AccountSustainabilityAdvisorProductCursorConnection';
  edges?: Maybe<Array<Maybe<AccountSustainabilityAdvisorProductEdge>>>;
  pageInfo: AccountSustainabilityAdvisorProductPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of AccountSustainabilityAdvisorProduct. */
export type AccountSustainabilityAdvisorProductEdge = {
  __typename?: 'AccountSustainabilityAdvisorProductEdge';
  cursor: Scalars['String'];
  node?: Maybe<AccountSustainabilityAdvisorProduct>;
};

/** Information about the current page. */
export type AccountSustainabilityAdvisorProductPageInfo = {
  __typename?: 'AccountSustainabilityAdvisorProductPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type ActualDelivery = Node & {
  __typename?: 'ActualDelivery';
  certificate?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  month?: Maybe<Scalars['Int']>;
  phase?: Maybe<Scalars['String']>;
  quarter?: Maybe<Scalars['Int']>;
  registryBuyerAccountNumber?: Maybe<Scalars['String']>;
  registryBuyerName?: Maybe<Scalars['String']>;
  registrySellerAccountNumber?: Maybe<Scalars['String']>;
  registrySellerName?: Maybe<Scalars['String']>;
  subtechnology?: Maybe<Scalars['String']>;
  support?: Maybe<Scalars['String']>;
  technology?: Maybe<Scalars['String']>;
  unitOfMeasurement?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  yearOfRelease?: Maybe<Scalars['Int']>;
};

/** Page connection for ActualDelivery. */
export type ActualDeliveryPageConnection = {
  __typename?: 'ActualDeliveryPageConnection';
  collection?: Maybe<Array<Maybe<ActualDelivery>>>;
  paginationInfo: ActualDeliveryPaginationInfo;
};

/** Information about the pagination. */
export type ActualDeliveryPaginationInfo = {
  __typename?: 'ActualDeliveryPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type AfsCommodity = Node & {
  __typename?: 'AfsCommodity';
  id: Scalars['ID'];
  name: Scalars['String'];
  products?: Maybe<ProductCursorConnection>;
};


export type AfsCommodityProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ApiKey = Node & {
  __typename?: 'ApiKey';
  apiKey: Scalars['String'];
  createdAt: Scalars['String'];
  expiresAt: Scalars['String'];
  id: Scalars['ID'];
  requestsPerSecond: Scalars['String'];
  startsWith: Scalars['String'];
};

/** Cursor connection for ApiKey. */
export type ApiKeyCursorConnection = {
  __typename?: 'ApiKeyCursorConnection';
  edges?: Maybe<Array<Maybe<ApiKeyEdge>>>;
  pageInfo: ApiKeyPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of ApiKey. */
export type ApiKeyEdge = {
  __typename?: 'ApiKeyEdge';
  cursor: Scalars['String'];
  node?: Maybe<ApiKey>;
};

/** Information about the current page. */
export type ApiKeyPageInfo = {
  __typename?: 'ApiKeyPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Authentication = Node & {
  __typename?: 'Authentication';
  accessToken?: Maybe<Scalars['String']>;
  account?: Maybe<Account>;
  id: Scalars['ID'];
  is2FaInProgress: Scalars['Boolean'];
  mercureToken?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
};

export type Avatar = Node & {
  __typename?: 'Avatar';
  contentUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['String']>;
};

/** Cursor connection for Avatar. */
export type AvatarCursorConnection = {
  __typename?: 'AvatarCursorConnection';
  edges?: Maybe<Array<Maybe<AvatarEdge>>>;
  pageInfo: AvatarPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Avatar. */
export type AvatarEdge = {
  __typename?: 'AvatarEdge';
  cursor: Scalars['String'];
  node?: Maybe<Avatar>;
};

/** Information about the current page. */
export type AvatarPageInfo = {
  __typename?: 'AvatarPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type BlogPost = Node & {
  __typename?: 'BlogPost';
  body: Scalars['String'];
  cover?: Maybe<PostImage>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Account>;
  deletedAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  product?: Maybe<Product>;
  publishedAt?: Maybe<Scalars['String']>;
  rejectedBy?: Maybe<Account>;
  rejectionReason?: Maybe<Scalars['String']>;
  sharedWith?: Maybe<ClientCompanyPageConnection>;
  status: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['String'];
};


export type BlogPostSharedWithArgs = {
  client?: InputMaybe<Scalars['String']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ClientCompanyFilter_Order>> | InputMaybe<ClientCompanyFilter_Order>>;
  orderByStatus?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  primarySubEntity_legalName?: InputMaybe<Scalars['String']>;
  primarySubEntity_zohoAccount?: InputMaybe<Scalars['String']>;
  primarySubEntity_zohoAccount_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  products_product_id?: InputMaybe<Scalars['String']>;
  products_product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};

export type BlogPostFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

/** Page connection for BlogPost. */
export type BlogPostPageConnection = {
  __typename?: 'BlogPostPageConnection';
  collection?: Maybe<Array<Maybe<BlogPost>>>;
  paginationInfo: BlogPostPaginationInfo;
};

/** Information about the pagination. */
export type BlogPostPaginationInfo = {
  __typename?: 'BlogPostPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type CalendarEvent = Node & {
  __typename?: 'CalendarEvent';
  attendees?: Maybe<AccountPageConnection>;
  attendeesEmails?: Maybe<Scalars['Iterable']>;
  deletedAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endDateTime?: Maybe<Scalars['Int']>;
  googleCancelEventLink?: Maybe<Scalars['String']>;
  googleEventId?: Maybe<Scalars['String']>;
  googleEventLink?: Maybe<Scalars['String']>;
  googleRescheduleEventLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  organizer?: Maybe<Account>;
  organizerEmail?: Maybe<Scalars['String']>;
  organizerName?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['Int']>;
  summary?: Maybe<Scalars['String']>;
};


export type CalendarEventAttendeesArgs = {
  clientCompany_id?: InputMaybe<Scalars['String']>;
  clientCompany_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  companyRepresentativeProducts_id?: InputMaybe<Scalars['String']>;
  companyRepresentativeProducts_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<AccountFilter_Order>> | InputMaybe<AccountFilter_Order>>;
  orderByFullName?: InputMaybe<Scalars['String']>;
  orderByStatus?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  sustainabilityAdvisorProducts_product_id?: InputMaybe<Scalars['String']>;
  sustainabilityAdvisorProducts_product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  type?: InputMaybe<Scalars['String']>;
  type_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};

export type CalendarEventFilter_DateRange = {
  end?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['String']>;
};

/** Page connection for CalendarEvent. */
export type CalendarEventPageConnection = {
  __typename?: 'CalendarEventPageConnection';
  collection?: Maybe<Array<Maybe<CalendarEvent>>>;
  paginationInfo: CalendarEventPaginationInfo;
};

/** Information about the pagination. */
export type CalendarEventPaginationInfo = {
  __typename?: 'CalendarEventPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Certification = Node & {
  __typename?: 'Certification';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ChatMessage = Node & {
  __typename?: 'ChatMessage';
  chatRoom: ChatRoom;
  content: Scalars['String'];
  document?: Maybe<ClientCompanyDocument>;
  id: Scalars['ID'];
  readBy?: Maybe<Scalars['Iterable']>;
  sender: Account;
  sentAt: Scalars['String'];
  type: Scalars['String'];
};

/** Page connection for ChatMessage. */
export type ChatMessagePageConnection = {
  __typename?: 'ChatMessagePageConnection';
  collection?: Maybe<Array<Maybe<ChatMessage>>>;
  paginationInfo: ChatMessagePaginationInfo;
};

/** Information about the pagination. */
export type ChatMessagePaginationInfo = {
  __typename?: 'ChatMessagePaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type ChatRoom = Node & {
  __typename?: 'ChatRoom';
  clientCompany: ClientCompany;
  id: Scalars['ID'];
  lastMessageContent?: Maybe<Scalars['String']>;
  lastMessageTime?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  participants?: Maybe<AccountPageConnection>;
  product: Product;
  unreadCount: Scalars['Int'];
};


export type ChatRoomParticipantsArgs = {
  clientCompany_id?: InputMaybe<Scalars['String']>;
  clientCompany_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  companyRepresentativeProducts_id?: InputMaybe<Scalars['String']>;
  companyRepresentativeProducts_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<AccountFilter_Order>> | InputMaybe<AccountFilter_Order>>;
  orderByFullName?: InputMaybe<Scalars['String']>;
  orderByStatus?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  sustainabilityAdvisorProducts_product_id?: InputMaybe<Scalars['String']>;
  sustainabilityAdvisorProducts_product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  type?: InputMaybe<Scalars['String']>;
  type_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};

export type ChatRoomFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']>;
  messages_sentAt?: InputMaybe<Scalars['String']>;
};

/** Page connection for ChatRoom. */
export type ChatRoomPageConnection = {
  __typename?: 'ChatRoomPageConnection';
  collection?: Maybe<Array<Maybe<ChatRoom>>>;
  paginationInfo: ChatRoomPaginationInfo;
};

/** Information about the pagination. */
export type ChatRoomPaginationInfo = {
  __typename?: 'ChatRoomPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type ClientCompany = Node & {
  __typename?: 'ClientCompany';
  companyRepresentatives?: Maybe<AccountPageConnection>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Account>;
  id: Scalars['ID'];
  isProductManagerRelated: Scalars['Boolean'];
  kycRequested: Scalars['Boolean'];
  kycRequestedAt?: Maybe<Scalars['String']>;
  /** Legal name of primary sub entity. */
  legalName: Scalars['String'];
  primarySubEntity: SubEntity;
  productDocuments?: Maybe<ProductDocumentPageConnection>;
  products?: Maybe<ClientCompanyProductCursorConnection>;
  rejectionReason?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  subEntities?: Maybe<SubEntityCursorConnection>;
  updatedAt: Scalars['String'];
};


export type ClientCompanyCompanyRepresentativesArgs = {
  clientCompany_id?: InputMaybe<Scalars['String']>;
  clientCompany_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  companyRepresentativeProducts_id?: InputMaybe<Scalars['String']>;
  companyRepresentativeProducts_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<AccountFilter_Order>> | InputMaybe<AccountFilter_Order>>;
  orderByFullName?: InputMaybe<Scalars['String']>;
  orderByStatus?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  sustainabilityAdvisorProducts_product_id?: InputMaybe<Scalars['String']>;
  sustainabilityAdvisorProducts_product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  type?: InputMaybe<Scalars['String']>;
  type_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type ClientCompanyProductDocumentsArgs = {
  createdBy?: InputMaybe<Scalars['String']>;
  createdBy_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  exists?: InputMaybe<Array<InputMaybe<ProductDocumentFilter_Exists>> | InputMaybe<ProductDocumentFilter_Exists>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<ProductDocumentFilter_Order>> | InputMaybe<ProductDocumentFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  productSpecificDocuments?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type ClientCompanyProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type ClientCompanySubEntitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ClientCompanyDocument = Node & {
  __typename?: 'ClientCompanyDocument';
  approvedAt?: Maybe<Scalars['String']>;
  canBeDeleted: Scalars['Boolean'];
  canBeUpdated: Scalars['Boolean'];
  clientCompany: ClientCompany;
  clientDocumentCategory?: Maybe<ClientDocumentCategory>;
  contentUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Account>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Account>;
  filePath?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  rejectionReason?: Maybe<Scalars['String']>;
  restoredAt?: Maybe<Scalars['String']>;
  restoredBy?: Maybe<Account>;
  reviewedBy?: Maybe<Account>;
  status: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Account>;
};

export type ClientCompanyDocumentFilter_Exists = {
  clientDocumentCategory?: InputMaybe<Scalars['Boolean']>;
};

export type ClientCompanyDocumentFilter_Order = {
  approvedAt?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

/** Page connection for ClientCompanyDocument. */
export type ClientCompanyDocumentPageConnection = {
  __typename?: 'ClientCompanyDocumentPageConnection';
  collection?: Maybe<Array<Maybe<ClientCompanyDocument>>>;
  paginationInfo: ClientCompanyDocumentPaginationInfo;
};

/** Information about the pagination. */
export type ClientCompanyDocumentPaginationInfo = {
  __typename?: 'ClientCompanyDocumentPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type ClientCompanyFilter_Order = {
  primarySubEntity_legalName?: InputMaybe<Scalars['String']>;
};

/** Page connection for ClientCompany. */
export type ClientCompanyPageConnection = {
  __typename?: 'ClientCompanyPageConnection';
  collection?: Maybe<Array<Maybe<ClientCompany>>>;
  paginationInfo: ClientCompanyPaginationInfo;
};

/** Information about the pagination. */
export type ClientCompanyPaginationInfo = {
  __typename?: 'ClientCompanyPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type ClientCompanyProduct = Node & {
  __typename?: 'ClientCompanyProduct';
  allowedModules?: Maybe<ModuleCursorConnection>;
  appointedSustainabilityAdvisors?: Maybe<AccountPageConnection>;
  clientCompany?: Maybe<ClientCompany>;
  defaultDashboardGroup?: Maybe<DashboardGroup>;
  id: Scalars['ID'];
  product?: Maybe<Product>;
  serviceType: Scalars['String'];
};


export type ClientCompanyProductAllowedModulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type ClientCompanyProductAppointedSustainabilityAdvisorsArgs = {
  clientCompany_id?: InputMaybe<Scalars['String']>;
  clientCompany_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  companyRepresentativeProducts_id?: InputMaybe<Scalars['String']>;
  companyRepresentativeProducts_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<AccountFilter_Order>> | InputMaybe<AccountFilter_Order>>;
  orderByFullName?: InputMaybe<Scalars['String']>;
  orderByStatus?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  sustainabilityAdvisorProducts_product_id?: InputMaybe<Scalars['String']>;
  sustainabilityAdvisorProducts_product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  type?: InputMaybe<Scalars['String']>;
  type_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};

/** Cursor connection for ClientCompanyProduct. */
export type ClientCompanyProductCursorConnection = {
  __typename?: 'ClientCompanyProductCursorConnection';
  edges?: Maybe<Array<Maybe<ClientCompanyProductEdge>>>;
  pageInfo: ClientCompanyProductPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of ClientCompanyProduct. */
export type ClientCompanyProductEdge = {
  __typename?: 'ClientCompanyProductEdge';
  cursor: Scalars['String'];
  node?: Maybe<ClientCompanyProduct>;
};

export type ClientCompanyProductInputType = {
  /** ID of the module */
  allowedModules?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** ID of the dashboard group */
  defaultDashboardGroup?: InputMaybe<Scalars['String']>;
  /** ID of the product */
  id: Scalars['ID'];
  /** List of sustainability advisors assigned to product */
  sustainabilityAdvisors?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Product type */
  type: Scalars['String'];
};

/** Information about the current page. */
export type ClientCompanyProductPageInfo = {
  __typename?: 'ClientCompanyProductPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type ClientDocumentCategory = Node & {
  __typename?: 'ClientDocumentCategory';
  canBeDeleted: Scalars['Boolean'];
  canBeUpdated: Scalars['Boolean'];
  clientCompany: ClientCompany;
  clientCompanyDocuments?: Maybe<ClientCompanyDocumentPageConnection>;
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type ClientDocumentCategoryClientCompanyDocumentsArgs = {
  client?: InputMaybe<Scalars['String']>;
  clientCompany?: InputMaybe<Scalars['String']>;
  clientCompany_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  exists?: InputMaybe<Array<InputMaybe<ClientCompanyDocumentFilter_Exists>> | InputMaybe<ClientCompanyDocumentFilter_Exists>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<ClientCompanyDocumentFilter_Order>> | InputMaybe<ClientCompanyDocumentFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};

/** Cursor connection for ClientDocumentCategory. */
export type ClientDocumentCategoryCursorConnection = {
  __typename?: 'ClientDocumentCategoryCursorConnection';
  edges?: Maybe<Array<Maybe<ClientDocumentCategoryEdge>>>;
  pageInfo: ClientDocumentCategoryPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of ClientDocumentCategory. */
export type ClientDocumentCategoryEdge = {
  __typename?: 'ClientDocumentCategoryEdge';
  cursor: Scalars['String'];
  node?: Maybe<ClientDocumentCategory>;
};

/** Information about the current page. */
export type ClientDocumentCategoryPageInfo = {
  __typename?: 'ClientDocumentCategoryPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type ClientProductPrice = Node & {
  __typename?: 'ClientProductPrice';
  active: Scalars['Boolean'];
  automaticPriceUpdate: Scalars['Boolean'];
  commodity?: Maybe<Scalars['String']>;
  complianceYears?: Maybe<Scalars['Int']>;
  continent?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  exchangeRate: Scalars['String'];
  expirationPeriodType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  max?: Maybe<Scalars['String']>;
  midPrice: Scalars['String'];
  min?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['String']>;
  product: Product;
  productCode?: Maybe<Scalars['String']>;
  productionYear?: Maybe<Scalars['Int']>;
  responsibleBroker?: Maybe<Scalars['String']>;
  technology?: Maybe<Scalars['String']>;
  yearOfRelease?: Maybe<Scalars['Int']>;
};

/** Page connection for ClientProductPrice. */
export type ClientProductPricePageConnection = {
  __typename?: 'ClientProductPricePageConnection';
  collection?: Maybe<Array<Maybe<ClientProductPrice>>>;
  paginationInfo: ClientProductPricePaginationInfo;
};

/** Information about the pagination. */
export type ClientProductPricePaginationInfo = {
  __typename?: 'ClientProductPricePaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Commodity = Node & {
  __typename?: 'Commodity';
  id: Scalars['ID'];
  name: Scalars['String'];
  regions?: Maybe<Array<Maybe<Region>>>;
  technologies?: Maybe<Array<Maybe<Technology>>>;
};


export type CommodityRegionsArgs = {
  commodities?: InputMaybe<Scalars['String']>;
  commodities_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type CommodityTechnologiesArgs = {
  commodities?: InputMaybe<Scalars['String']>;
  commodities_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};

export type CommodityVolumesMonth = Node & {
  __typename?: 'CommodityVolumesMonth';
  deletedAt?: Maybe<Scalars['String']>;
  expected?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  month: Scalars['Int'];
  notify: Scalars['Boolean'];
  open?: Maybe<Scalars['Int']>;
  produced?: Maybe<Scalars['Int']>;
  sold?: Maybe<Scalars['Int']>;
  toBeProducedPerMonth?: Maybe<Scalars['Int']>;
  totalProduced?: Maybe<Scalars['Int']>;
  totalSold?: Maybe<Scalars['Int']>;
  year?: Maybe<CommodityVolumesYear>;
  yearNumber?: Maybe<Scalars['Int']>;
};

export type CommodityVolumesMonthNestedInput = {
  _id?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<Scalars['String']>;
  expected?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  iri: Scalars['String'];
  month: Scalars['Int'];
  notify: Scalars['Boolean'];
  open?: InputMaybe<Scalars['Int']>;
  produced?: InputMaybe<Scalars['Int']>;
  sold?: InputMaybe<Scalars['Int']>;
  toBeProducedPerMonth?: InputMaybe<Scalars['Int']>;
  totalProduced?: InputMaybe<Scalars['Int']>;
  totalSold?: InputMaybe<Scalars['Int']>;
  year: CommodityVolumesYearNestedInput;
  yearNumber?: InputMaybe<Scalars['Int']>;
};

/** Page connection for CommodityVolumesMonth. */
export type CommodityVolumesMonthPageConnection = {
  __typename?: 'CommodityVolumesMonthPageConnection';
  collection?: Maybe<Array<Maybe<CommodityVolumesMonth>>>;
  paginationInfo: CommodityVolumesMonthPaginationInfo;
};

/** Information about the pagination. */
export type CommodityVolumesMonthPaginationInfo = {
  __typename?: 'CommodityVolumesMonthPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type CommodityVolumesYear = Node & {
  __typename?: 'CommodityVolumesYear';
  company?: Maybe<ClientCompany>;
  deletedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  months?: Maybe<CommodityVolumesMonthPageConnection>;
  product?: Maybe<Product>;
  savingLimit: Scalars['Int'];
  savingLimitValidity?: Maybe<Scalars['String']>;
  totalExpected?: Maybe<Scalars['Int']>;
  year: Scalars['Int'];
};


export type CommodityVolumesYearMonthsArgs = {
  page?: InputMaybe<Scalars['Int']>;
};

export type CommodityVolumesYearNestedInput = {
  _id?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  iri: Scalars['String'];
  months?: InputMaybe<Array<CommodityVolumesMonthNestedInput>>;
  product?: InputMaybe<Scalars['String']>;
  savingLimit: Scalars['Int'];
  savingLimitValidity?: InputMaybe<Scalars['String']>;
  totalExpected?: InputMaybe<Scalars['Int']>;
  year: Scalars['Int'];
};

/** Page connection for CommodityVolumesYear. */
export type CommodityVolumesYearPageConnection = {
  __typename?: 'CommodityVolumesYearPageConnection';
  collection?: Maybe<Array<Maybe<CommodityVolumesYear>>>;
  paginationInfo: CommodityVolumesYearPaginationInfo;
};

/** Information about the pagination. */
export type CommodityVolumesYearPaginationInfo = {
  __typename?: 'CommodityVolumesYearPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Country = Node & {
  __typename?: 'Country';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Currency = Node & {
  __typename?: 'Currency';
  code: Scalars['String'];
  id: Scalars['ID'];
};

export type DashboardGroup = Node & {
  __typename?: 'DashboardGroup';
  _id?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  iri: Scalars['String'];
  modules?: Maybe<ModuleCursorConnection>;
  name: Scalars['String'];
  products?: Maybe<ClientCompanyProductCursorConnection>;
  slug: Scalars['String'];
};


export type DashboardGroupModulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type DashboardGroupProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Cursor connection for DashboardGroup. */
export type DashboardGroupCursorConnection = {
  __typename?: 'DashboardGroupCursorConnection';
  edges?: Maybe<Array<Maybe<DashboardGroupEdge>>>;
  pageInfo: DashboardGroupPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of DashboardGroup. */
export type DashboardGroupEdge = {
  __typename?: 'DashboardGroupEdge';
  cursor: Scalars['String'];
  node?: Maybe<DashboardGroup>;
};

/** Information about the current page. */
export type DashboardGroupPageInfo = {
  __typename?: 'DashboardGroupPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Delivery = Node & {
  __typename?: 'Delivery';
  actualDeliveries?: Maybe<ActualDeliveryPageConnection>;
  deliveryDate?: Maybe<Scalars['String']>;
  deliveryMadeOn?: Maybe<Scalars['String']>;
  endMonth?: Maybe<Scalars['Int']>;
  endQuarter?: Maybe<Scalars['Int']>;
  endYear?: Maybe<Scalars['Int']>;
  estimatedVolume?: Maybe<Scalars['Int']>;
  greening?: Maybe<Scalars['Boolean']>;
  greeningYear?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  invoiceDate?: Maybe<Scalars['String']>;
  invoicesCompleted?: Maybe<Scalars['Boolean']>;
  paymentDate?: Maybe<Scalars['String']>;
  paymentTerm?: Maybe<Scalars['String']>;
  phase?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  productionPeriod?: Maybe<Scalars['String']>;
  registryBuyerAccountNumber?: Maybe<Scalars['String']>;
  registryBuyerName?: Maybe<Scalars['String']>;
  registrySellerAccountNumber?: Maybe<Scalars['String']>;
  registrySellerName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  startMonth?: Maybe<Scalars['Int']>;
  startQuarter?: Maybe<Scalars['Int']>;
  startYear?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  transaction: Transaction;
  volume?: Maybe<Scalars['Int']>;
  yearOfRelease?: Maybe<Scalars['Int']>;
};


export type DeliveryActualDeliveriesArgs = {
  page?: InputMaybe<Scalars['Int']>;
};

export type DeliveryFilter_PaymentDate = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  strictly_after?: InputMaybe<Scalars['String']>;
  strictly_before?: InputMaybe<Scalars['String']>;
};

/** Page connection for Delivery. */
export type DeliveryPageConnection = {
  __typename?: 'DeliveryPageConnection';
  collection?: Maybe<Array<Maybe<Delivery>>>;
  paginationInfo: DeliveryPaginationInfo;
};

/** Information about the pagination. */
export type DeliveryPaginationInfo = {
  __typename?: 'DeliveryPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type ExchangePlugStatus = Node & {
  __typename?: 'ExchangePlugStatus';
  _id?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  setBy: Account;
  status: Scalars['String'];
};

/** Page connection for ExchangePlugStatus. */
export type ExchangePlugStatusPageConnection = {
  __typename?: 'ExchangePlugStatusPageConnection';
  collection?: Maybe<Array<Maybe<ExchangePlugStatus>>>;
  paginationInfo: ExchangePlugStatusPaginationInfo;
};

/** Information about the pagination. */
export type ExchangePlugStatusPaginationInfo = {
  __typename?: 'ExchangePlugStatusPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type InAppEvent = Node & {
  __typename?: 'InAppEvent';
  canBeDeleted: Scalars['Boolean'];
  canBeEdited: Scalars['Boolean'];
  createdBy: Account;
  description?: Maybe<Scalars['String']>;
  endDateTime?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  product: Product;
  sharedWith?: Maybe<ClientCompanyPageConnection>;
  startDateTime: Scalars['Int'];
  title: Scalars['String'];
};


export type InAppEventSharedWithArgs = {
  client?: InputMaybe<Scalars['String']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ClientCompanyFilter_Order>> | InputMaybe<ClientCompanyFilter_Order>>;
  orderByStatus?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  primarySubEntity_legalName?: InputMaybe<Scalars['String']>;
  primarySubEntity_zohoAccount?: InputMaybe<Scalars['String']>;
  primarySubEntity_zohoAccount_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  products_product_id?: InputMaybe<Scalars['String']>;
  products_product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};

export type InAppEventFilter_DateRange = {
  end?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['String']>;
};

/** Page connection for InAppEvent. */
export type InAppEventPageConnection = {
  __typename?: 'InAppEventPageConnection';
  collection?: Maybe<Array<Maybe<InAppEvent>>>;
  paginationInfo: InAppEventPaginationInfo;
};

/** Information about the pagination. */
export type InAppEventPaginationInfo = {
  __typename?: 'InAppEventPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Incoterm = Node & {
  __typename?: 'Incoterm';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type IndicationOfInterest = Node & {
  __typename?: 'IndicationOfInterest';
  clientCompany: ClientCompany;
  commodity: Commodity;
  createdAt: Scalars['String'];
  createdBy: Account;
  currency: Currency;
  currencyCode: Scalars['String'];
  expiresAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metric: Metric;
  offers?: Maybe<OfferPageConnection>;
  product: Product;
  status: Scalars['String'];
  type: Scalars['String'];
  volume: Scalars['Int'];
};


export type IndicationOfInterestOffersArgs = {
  order?: InputMaybe<Array<InputMaybe<OfferFilter_Order>> | InputMaybe<OfferFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
};

export type IndicationOfInterestFilter_ExpiresAt = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  strictly_after?: InputMaybe<Scalars['String']>;
  strictly_before?: InputMaybe<Scalars['String']>;
};

/** Page connection for IndicationOfInterest. */
export type IndicationOfInterestPageConnection = {
  __typename?: 'IndicationOfInterestPageConnection';
  collection?: Maybe<Array<Maybe<IndicationOfInterest>>>;
  paginationInfo: IndicationOfInterestPaginationInfo;
};

/** Information about the pagination. */
export type IndicationOfInterestPaginationInfo = {
  __typename?: 'IndicationOfInterestPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Invoice = Node & {
  __typename?: 'Invoice';
  currencyName: Scalars['String'];
  file?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoiceNumber: Scalars['String'];
  invoicePaidOn?: Maybe<Scalars['String']>;
  invoiceSentOn?: Maybe<Scalars['String']>;
  invoicingDate?: Maybe<Scalars['String']>;
  isCompleted: Scalars['Boolean'];
  paymentTerm: Scalars['String'];
  primaryContactEmail?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  secondaryContactsEmails?: Maybe<Scalars['Iterable']>;
  status: Scalars['String'];
  totalInvoicePrice: Scalars['String'];
  transactions?: Maybe<TransactionPageConnection>;
  type: Scalars['String'];
};


export type InvoiceTransactionsArgs = {
  commodity?: InputMaybe<Scalars['String']>;
  commodity_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  euaHistory?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<TransactionFilter_Order>> | InputMaybe<TransactionFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  primarySustainabilityAdvisor_id?: InputMaybe<Scalars['String']>;
  primarySustainabilityAdvisor_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  product?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  secondarySustainabilityAdvisors_id?: InputMaybe<Scalars['String']>;
  secondarySustainabilityAdvisors_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  status?: InputMaybe<Scalars['String']>;
  tradeDate?: InputMaybe<Array<InputMaybe<TransactionFilter_TradeDate>> | InputMaybe<TransactionFilter_TradeDate>>;
};

/** Page connection for Invoice. */
export type InvoicePageConnection = {
  __typename?: 'InvoicePageConnection';
  collection?: Maybe<Array<Maybe<Invoice>>>;
  paginationInfo: InvoicePaginationInfo;
};

/** Information about the pagination. */
export type InvoicePaginationInfo = {
  __typename?: 'InvoicePaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type MasterAgreement = Node & {
  __typename?: 'MasterAgreement';
  afsCommodities?: Maybe<Array<Maybe<AfsCommodity>>>;
  clientCompany?: Maybe<ClientCompany>;
  commodities?: Maybe<Scalars['Iterable']>;
  createdOn?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  masterAgreementFile?: Maybe<Scalars['String']>;
  modifiedOn?: Maybe<Scalars['String']>;
};


export type MasterAgreementAfsCommoditiesArgs = {
  name?: InputMaybe<Scalars['String']>;
  name_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  products?: InputMaybe<Scalars['String']>;
  products_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};

export type Metric = Node & {
  __typename?: 'Metric';
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type Module = Node & {
  __typename?: 'Module';
  _id?: Maybe<Scalars['String']>;
  clientCompanyProducts?: Maybe<ClientCompanyProductCursorConnection>;
  dashboardGroups?: Maybe<DashboardGroupCursorConnection>;
  deletedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  iri: Scalars['String'];
  name: Scalars['String'];
  products?: Maybe<ProductCursorConnection>;
  slug: Scalars['String'];
};


export type ModuleClientCompanyProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type ModuleDashboardGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type ModuleProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Cursor connection for Module. */
export type ModuleCursorConnection = {
  __typename?: 'ModuleCursorConnection';
  edges?: Maybe<Array<Maybe<ModuleEdge>>>;
  pageInfo: ModulePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Module. */
export type ModuleEdge = {
  __typename?: 'ModuleEdge';
  cursor: Scalars['String'];
  node?: Maybe<Module>;
};

/** Information about the current page. */
export type ModulePageInfo = {
  __typename?: 'ModulePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Activates a Account. */
  activateAccount?: Maybe<ActivateAccountPayload>;
  /** Approves a Account. */
  approveAccount?: Maybe<ApproveAccountPayload>;
  /** Approves a ClientCompany. */
  approveClientCompany?: Maybe<ApproveClientCompanyPayload>;
  /** Approves a ClientCompanyDocument. */
  approveClientCompanyDocument?: Maybe<ApproveClientCompanyDocumentPayload>;
  /** Approves a ProductAccessRequest. */
  approveProductAccessRequest?: Maybe<ApproveProductAccessRequestPayload>;
  /** ChangePasswordFors a Account. */
  changePasswordForAccount?: Maybe<ChangePasswordForAccountPayload>;
  /** Confirm2factors a Authentication. */
  confirm2factorAuthentication?: Maybe<Confirm2factorAuthenticationPayload>;
  /** Creates a Account. */
  createAccount?: Maybe<CreateAccountPayload>;
  /** Creates a ApiKey. */
  createApiKey?: Maybe<CreateApiKeyPayload>;
  /** Creates a BlogPost. */
  createBlogPost?: Maybe<CreateBlogPostPayload>;
  /** CreateBusinessSupports a Account. */
  createBusinessSupportAccount?: Maybe<CreateBusinessSupportAccountPayload>;
  /** Creates a ChatMessage. */
  createChatMessage?: Maybe<CreateChatMessagePayload>;
  /** Creates a ClientCompany. */
  createClientCompany?: Maybe<CreateClientCompanyPayload>;
  /** Creates a ClientDocumentCategory. */
  createClientDocumentCategory?: Maybe<CreateClientDocumentCategoryPayload>;
  /** Creates a CommodityVolumesYear. */
  createCommodityVolumesYear?: Maybe<CreateCommodityVolumesYearPayload>;
  /** CreateCompanyRepresentatives a Account. */
  createCompanyRepresentativeAccount?: Maybe<CreateCompanyRepresentativeAccountPayload>;
  /** Creates a InAppEvent. */
  createInAppEvent?: Maybe<CreateInAppEventPayload>;
  /** Creates a Newsletter. */
  createNewsletter?: Maybe<CreateNewsletterPayload>;
  /** Creates a Offer. */
  createOffer?: Maybe<CreateOfferPayload>;
  /** Creates a Order. */
  createOrder?: Maybe<CreateOrderPayload>;
  /** Creates a PriceWidgetCalculation. */
  createPriceWidgetCalculation?: Maybe<CreatePriceWidgetCalculationPayload>;
  /** Creates a ProductAccessRequest. */
  createProductAccessRequest?: Maybe<CreateProductAccessRequestPayload>;
  /** Creates a ProductDocumentCategory. */
  createProductDocumentCategory?: Maybe<CreateProductDocumentCategoryPayload>;
  /** Creates a Rfq. */
  createRfq?: Maybe<CreateRfqPayload>;
  /** Creates a SubEntity. */
  createSubEntity?: Maybe<CreateSubEntityPayload>;
  /** CreateSustainabilityAdvisors a Account. */
  createSustainabilityAdvisorAccount?: Maybe<CreateSustainabilityAdvisorAccountPayload>;
  /** Creates a Webinar. */
  createWebinar?: Maybe<CreateWebinarPayload>;
  /** Deactivates a Account. */
  deactivateAccount?: Maybe<DeactivateAccountPayload>;
  /** Deactivates a ClientCompany. */
  deactivateClientCompany?: Maybe<DeactivateClientCompanyPayload>;
  /** Deletes a Account. */
  deleteAccount?: Maybe<DeleteAccountPayload>;
  /** Deletes a BlogPost. */
  deleteBlogPost?: Maybe<DeleteBlogPostPayload>;
  /** Deletes a ClientCompanyDocument. */
  deleteClientCompanyDocument?: Maybe<DeleteClientCompanyDocumentPayload>;
  /** Deletes a ClientDocumentCategory. */
  deleteClientDocumentCategory?: Maybe<DeleteClientDocumentCategoryPayload>;
  /** Deletes a InAppEvent. */
  deleteInAppEvent?: Maybe<DeleteInAppEventPayload>;
  /** Deletes a Newsletter. */
  deleteNewsletter?: Maybe<DeleteNewsletterPayload>;
  /** Deletes a ProductDocument. */
  deleteProductDocument?: Maybe<DeleteProductDocumentPayload>;
  /** Deletes a ProductDocumentCategory. */
  deleteProductDocumentCategory?: Maybe<DeleteProductDocumentCategoryPayload>;
  /** Deletes a SubEntity. */
  deleteSubEntity?: Maybe<DeleteSubEntityPayload>;
  /** Deletes a Webinar. */
  deleteWebinar?: Maybe<DeleteWebinarPayload>;
  /** Enable2factors a Authentication. */
  enable2factorAuthentication?: Maybe<Enable2factorAuthenticationPayload>;
  /** Invites a Account. */
  inviteAccount?: Maybe<InviteAccountPayload>;
  /** Joins a ChatRoom. */
  joinChatRoom?: Maybe<JoinChatRoomPayload>;
  /** Leaves a ChatRoom. */
  leaveChatRoom?: Maybe<LeaveChatRoomPayload>;
  /** MarkAsReads a Notification. */
  markAsReadNotification?: Maybe<MarkAsReadNotificationPayload>;
  /** PasswordResetFors a Account. */
  passwordResetForAccount?: Maybe<PasswordResetForAccountPayload>;
  /** Publishs a ProductDocument. */
  publishProductDocument?: Maybe<PublishProductDocumentPayload>;
  /** Publishs a Webinar. */
  publishWebinar?: Maybe<PublishWebinarPayload>;
  /** Reactivates a Account. */
  reactivateAccount?: Maybe<ReactivateAccountPayload>;
  /** Reactivates a ClientCompany. */
  reactivateClientCompany?: Maybe<ReactivateClientCompanyPayload>;
  /** Rejects a Account. */
  rejectAccount?: Maybe<RejectAccountPayload>;
  /** Rejects a BlogPost. */
  rejectBlogPost?: Maybe<RejectBlogPostPayload>;
  /** Rejects a ClientCompany. */
  rejectClientCompany?: Maybe<RejectClientCompanyPayload>;
  /** Rejects a ClientCompanyDocument. */
  rejectClientCompanyDocument?: Maybe<RejectClientCompanyDocumentPayload>;
  /** Rejects a ProductAccessRequest. */
  rejectProductAccessRequest?: Maybe<RejectProductAccessRequestPayload>;
  /** Rejects a ProductDocument. */
  rejectProductDocument?: Maybe<RejectProductDocumentPayload>;
  /** Rejects a Webinar. */
  rejectWebinar?: Maybe<RejectWebinarPayload>;
  /** RequestPasswordResetFors a Account. */
  requestPasswordResetForAccount?: Maybe<RequestPasswordResetForAccountPayload>;
  /** Requests a Product. */
  requestProduct?: Maybe<RequestProductPayload>;
  /** ResetMFAFors a Account. */
  resetMFAForAccount?: Maybe<ResetMfaForAccountPayload>;
  /** Restores a ClientCompanyDocument. */
  restoreClientCompanyDocument?: Maybe<RestoreClientCompanyDocumentPayload>;
  /** Restores a ProductDocument. */
  restoreProductDocument?: Maybe<RestoreProductDocumentPayload>;
  /** Starts a Authentication. */
  startAuthentication?: Maybe<StartAuthenticationPayload>;
  /** SubmitForApprovals a Account. */
  submitForApprovalAccount?: Maybe<SubmitForApprovalAccountPayload>;
  /** SubmitForApprovals a ClientCompany. */
  submitForApprovalClientCompany?: Maybe<SubmitForApprovalClientCompanyPayload>;
  /** Unsets a Avatar. */
  unsetAvatar?: Maybe<UnsetAvatarPayload>;
  /** Updates a Account. */
  updateAccount?: Maybe<UpdateAccountPayload>;
  /** Updates a AccountNotificationSetting. */
  updateAccountNotificationSetting?: Maybe<UpdateAccountNotificationSettingPayload>;
  /** Updates a Avatar. */
  updateAvatar?: Maybe<UpdateAvatarPayload>;
  /** Updates a BlogPost. */
  updateBlogPost?: Maybe<UpdateBlogPostPayload>;
  /** UpdateBusinessSupports a Account. */
  updateBusinessSupportAccount?: Maybe<UpdateBusinessSupportAccountPayload>;
  /** Updates a ClientCompany. */
  updateClientCompany?: Maybe<UpdateClientCompanyPayload>;
  /** Updates a ClientCompanyDocument. */
  updateClientCompanyDocument?: Maybe<UpdateClientCompanyDocumentPayload>;
  /** Updates a ClientDocumentCategory. */
  updateClientDocumentCategory?: Maybe<UpdateClientDocumentCategoryPayload>;
  /** Updates a CommodityVolumesMonth. */
  updateCommodityVolumesMonth?: Maybe<UpdateCommodityVolumesMonthPayload>;
  /** Updates a CommodityVolumesYear. */
  updateCommodityVolumesYear?: Maybe<UpdateCommodityVolumesYearPayload>;
  /** UpdateCompanyRepresentatives a Account. */
  updateCompanyRepresentativeAccount?: Maybe<UpdateCompanyRepresentativeAccountPayload>;
  /** Updates a ExchangePlugStatus. */
  updateExchangePlugStatus?: Maybe<UpdateExchangePlugStatusPayload>;
  /** Updates a InAppEvent. */
  updateInAppEvent?: Maybe<UpdateInAppEventPayload>;
  /** Updates a Newsletter. */
  updateNewsletter?: Maybe<UpdateNewsletterPayload>;
  /** UpdatePaymentStatuss a Order. */
  updatePaymentStatusOrder?: Maybe<UpdatePaymentStatusOrderPayload>;
  /** Updates a ProductDocument. */
  updateProductDocument?: Maybe<UpdateProductDocumentPayload>;
  /** Updates a ProductDocumentCategory. */
  updateProductDocumentCategory?: Maybe<UpdateProductDocumentCategoryPayload>;
  /** UpdateQuarters a CommodityVolumesMonth. */
  updateQuarterCommodityVolumesMonth?: Maybe<UpdateQuarterCommodityVolumesMonthPayload>;
  /** Updates a SubEntity. */
  updateSubEntity?: Maybe<UpdateSubEntityPayload>;
  /** UpdateSustainabilityAdvisors a Account. */
  updateSustainabilityAdvisorAccount?: Maybe<UpdateSustainabilityAdvisorAccountPayload>;
  /** Updates a Webinar. */
  updateWebinar?: Maybe<UpdateWebinarPayload>;
  /** Verify2factors a Authentication. */
  verify2factorAuthentication?: Maybe<Verify2factorAuthenticationPayload>;
  /** Verifys a ClientCompany. */
  verifyClientCompany?: Maybe<VerifyClientCompanyPayload>;
};


export type MutationActivateAccountArgs = {
  input: ActivateAccountInput;
};


export type MutationApproveAccountArgs = {
  input: ApproveAccountInput;
};


export type MutationApproveClientCompanyArgs = {
  input: ApproveClientCompanyInput;
};


export type MutationApproveClientCompanyDocumentArgs = {
  input: ApproveClientCompanyDocumentInput;
};


export type MutationApproveProductAccessRequestArgs = {
  input: ApproveProductAccessRequestInput;
};


export type MutationChangePasswordForAccountArgs = {
  input: ChangePasswordForAccountInput;
};


export type MutationConfirm2factorAuthenticationArgs = {
  input: Confirm2factorAuthenticationInput;
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationCreateApiKeyArgs = {
  input: CreateApiKeyInput;
};


export type MutationCreateBlogPostArgs = {
  input: CreateBlogPostInput;
};


export type MutationCreateBusinessSupportAccountArgs = {
  input: CreateBusinessSupportAccountInput;
};


export type MutationCreateChatMessageArgs = {
  input: CreateChatMessageInput;
};


export type MutationCreateClientCompanyArgs = {
  input: CreateClientCompanyInput;
};


export type MutationCreateClientDocumentCategoryArgs = {
  input: CreateClientDocumentCategoryInput;
};


export type MutationCreateCommodityVolumesYearArgs = {
  input: CreateCommodityVolumesYearInput;
};


export type MutationCreateCompanyRepresentativeAccountArgs = {
  input: CreateCompanyRepresentativeAccountInput;
};


export type MutationCreateInAppEventArgs = {
  input: CreateInAppEventInput;
};


export type MutationCreateNewsletterArgs = {
  input: CreateNewsletterInput;
};


export type MutationCreateOfferArgs = {
  input: CreateOfferInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationCreatePriceWidgetCalculationArgs = {
  input: CreatePriceWidgetCalculationInput;
};


export type MutationCreateProductAccessRequestArgs = {
  input: CreateProductAccessRequestInput;
};


export type MutationCreateProductDocumentCategoryArgs = {
  input: CreateProductDocumentCategoryInput;
};


export type MutationCreateRfqArgs = {
  input: CreateRfqInput;
};


export type MutationCreateSubEntityArgs = {
  input: CreateSubEntityInput;
};


export type MutationCreateSustainabilityAdvisorAccountArgs = {
  input: CreateSustainabilityAdvisorAccountInput;
};


export type MutationCreateWebinarArgs = {
  input: CreateWebinarInput;
};


export type MutationDeactivateAccountArgs = {
  input: DeactivateAccountInput;
};


export type MutationDeactivateClientCompanyArgs = {
  input: DeactivateClientCompanyInput;
};


export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput;
};


export type MutationDeleteBlogPostArgs = {
  input: DeleteBlogPostInput;
};


export type MutationDeleteClientCompanyDocumentArgs = {
  input: DeleteClientCompanyDocumentInput;
};


export type MutationDeleteClientDocumentCategoryArgs = {
  input: DeleteClientDocumentCategoryInput;
};


export type MutationDeleteInAppEventArgs = {
  input: DeleteInAppEventInput;
};


export type MutationDeleteNewsletterArgs = {
  input: DeleteNewsletterInput;
};


export type MutationDeleteProductDocumentArgs = {
  input: DeleteProductDocumentInput;
};


export type MutationDeleteProductDocumentCategoryArgs = {
  input: DeleteProductDocumentCategoryInput;
};


export type MutationDeleteSubEntityArgs = {
  input: DeleteSubEntityInput;
};


export type MutationDeleteWebinarArgs = {
  input: DeleteWebinarInput;
};


export type MutationEnable2factorAuthenticationArgs = {
  input: Enable2factorAuthenticationInput;
};


export type MutationInviteAccountArgs = {
  input: InviteAccountInput;
};


export type MutationJoinChatRoomArgs = {
  input: JoinChatRoomInput;
};


export type MutationLeaveChatRoomArgs = {
  input: LeaveChatRoomInput;
};


export type MutationMarkAsReadNotificationArgs = {
  input: MarkAsReadNotificationInput;
};


export type MutationPasswordResetForAccountArgs = {
  input: PasswordResetForAccountInput;
};


export type MutationPublishProductDocumentArgs = {
  input: PublishProductDocumentInput;
};


export type MutationPublishWebinarArgs = {
  input: PublishWebinarInput;
};


export type MutationReactivateAccountArgs = {
  input: ReactivateAccountInput;
};


export type MutationReactivateClientCompanyArgs = {
  input: ReactivateClientCompanyInput;
};


export type MutationRejectAccountArgs = {
  input: RejectAccountInput;
};


export type MutationRejectBlogPostArgs = {
  input: RejectBlogPostInput;
};


export type MutationRejectClientCompanyArgs = {
  input: RejectClientCompanyInput;
};


export type MutationRejectClientCompanyDocumentArgs = {
  input: RejectClientCompanyDocumentInput;
};


export type MutationRejectProductAccessRequestArgs = {
  input: RejectProductAccessRequestInput;
};


export type MutationRejectProductDocumentArgs = {
  input: RejectProductDocumentInput;
};


export type MutationRejectWebinarArgs = {
  input: RejectWebinarInput;
};


export type MutationRequestPasswordResetForAccountArgs = {
  input: RequestPasswordResetForAccountInput;
};


export type MutationRequestProductArgs = {
  input: RequestProductInput;
};


export type MutationResetMfaForAccountArgs = {
  input: ResetMfaForAccountInput;
};


export type MutationRestoreClientCompanyDocumentArgs = {
  input: RestoreClientCompanyDocumentInput;
};


export type MutationRestoreProductDocumentArgs = {
  input: RestoreProductDocumentInput;
};


export type MutationStartAuthenticationArgs = {
  input: StartAuthenticationInput;
};


export type MutationSubmitForApprovalAccountArgs = {
  input: SubmitForApprovalAccountInput;
};


export type MutationSubmitForApprovalClientCompanyArgs = {
  input: SubmitForApprovalClientCompanyInput;
};


export type MutationUnsetAvatarArgs = {
  input: UnsetAvatarInput;
};


export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};


export type MutationUpdateAccountNotificationSettingArgs = {
  input: UpdateAccountNotificationSettingInput;
};


export type MutationUpdateAvatarArgs = {
  input: UpdateAvatarInput;
};


export type MutationUpdateBlogPostArgs = {
  input: UpdateBlogPostInput;
};


export type MutationUpdateBusinessSupportAccountArgs = {
  input: UpdateBusinessSupportAccountInput;
};


export type MutationUpdateClientCompanyArgs = {
  input: UpdateClientCompanyInput;
};


export type MutationUpdateClientCompanyDocumentArgs = {
  input: UpdateClientCompanyDocumentInput;
};


export type MutationUpdateClientDocumentCategoryArgs = {
  input: UpdateClientDocumentCategoryInput;
};


export type MutationUpdateCommodityVolumesMonthArgs = {
  input: UpdateCommodityVolumesMonthInput;
};


export type MutationUpdateCommodityVolumesYearArgs = {
  input: UpdateCommodityVolumesYearInput;
};


export type MutationUpdateCompanyRepresentativeAccountArgs = {
  input: UpdateCompanyRepresentativeAccountInput;
};


export type MutationUpdateExchangePlugStatusArgs = {
  input: UpdateExchangePlugStatusInput;
};


export type MutationUpdateInAppEventArgs = {
  input: UpdateInAppEventInput;
};


export type MutationUpdateNewsletterArgs = {
  input: UpdateNewsletterInput;
};


export type MutationUpdatePaymentStatusOrderArgs = {
  input: UpdatePaymentStatusOrderInput;
};


export type MutationUpdateProductDocumentArgs = {
  input: UpdateProductDocumentInput;
};


export type MutationUpdateProductDocumentCategoryArgs = {
  input: UpdateProductDocumentCategoryInput;
};


export type MutationUpdateQuarterCommodityVolumesMonthArgs = {
  input: UpdateQuarterCommodityVolumesMonthInput;
};


export type MutationUpdateSubEntityArgs = {
  input: UpdateSubEntityInput;
};


export type MutationUpdateSustainabilityAdvisorAccountArgs = {
  input: UpdateSustainabilityAdvisorAccountInput;
};


export type MutationUpdateWebinarArgs = {
  input: UpdateWebinarInput;
};


export type MutationVerify2factorAuthenticationArgs = {
  input: Verify2factorAuthenticationInput;
};


export type MutationVerifyClientCompanyArgs = {
  input: VerifyClientCompanyInput;
};

export type Newsletter = Node & {
  __typename?: 'Newsletter';
  body: Scalars['String'];
  cover?: Maybe<PostImage>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Account>;
  deletedAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  products?: Maybe<ProductCursorConnection>;
  publishedAt?: Maybe<Scalars['String']>;
  rejectedBy?: Maybe<Account>;
  rejectionReason?: Maybe<Scalars['String']>;
  sharedWith?: Maybe<ClientCompanyPageConnection>;
  status: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['String'];
};


export type NewsletterProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type NewsletterSharedWithArgs = {
  client?: InputMaybe<Scalars['String']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ClientCompanyFilter_Order>> | InputMaybe<ClientCompanyFilter_Order>>;
  orderByStatus?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  primarySubEntity_legalName?: InputMaybe<Scalars['String']>;
  primarySubEntity_zohoAccount?: InputMaybe<Scalars['String']>;
  primarySubEntity_zohoAccount_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  products_product_id?: InputMaybe<Scalars['String']>;
  products_product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};

export type NewsletterFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

/** Page connection for Newsletter. */
export type NewsletterPageConnection = {
  __typename?: 'NewsletterPageConnection';
  collection?: Maybe<Array<Maybe<Newsletter>>>;
  paginationInfo: NewsletterPaginationInfo;
};

/** Information about the pagination. */
export type NewsletterPaginationInfo = {
  __typename?: 'NewsletterPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

/** A node, according to the Relay specification. */
export type Node = {
  /** The id of this node. */
  id: Scalars['ID'];
};

export type Notification = Node & {
  __typename?: 'Notification';
  accountNotifications?: Maybe<AccountNotificationCursorConnection>;
  id: Scalars['ID'];
  module: Scalars['String'];
  notificationType: Scalars['String'];
  occurred_at: Scalars['String'];
  payload: Scalars['Iterable'];
  processedPayload?: Maybe<Scalars['Iterable']>;
};


export type NotificationAccountNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type NotificationFilter_Order = {
  occurred_at?: InputMaybe<Scalars['String']>;
};

/** Page connection for Notification. */
export type NotificationPageConnection = {
  __typename?: 'NotificationPageConnection';
  collection?: Maybe<Array<Maybe<Notification>>>;
  paginationInfo: NotificationPaginationInfo;
};

/** Information about the pagination. */
export type NotificationPaginationInfo = {
  __typename?: 'NotificationPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Offer = Node & {
  __typename?: 'Offer';
  _id: Scalars['String'];
  answeredAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy: Account;
  currencyCode: Scalars['String'];
  id: Scalars['ID'];
  indicationOfInterest: IndicationOfInterest;
  isAccepted: Scalars['Boolean'];
  price: Scalars['Float'];
  totalPrice: Scalars['Float'];
};

export type OfferFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']>;
};

/** Page connection for Offer. */
export type OfferPageConnection = {
  __typename?: 'OfferPageConnection';
  collection?: Maybe<Array<Maybe<Offer>>>;
  paginationInfo: OfferPaginationInfo;
};

/** Information about the pagination. */
export type OfferPaginationInfo = {
  __typename?: 'OfferPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Order = Node & {
  __typename?: 'Order';
  _id?: Maybe<Scalars['String']>;
  commodity: Commodity;
  createdAt: Scalars['String'];
  createdBy: Account;
  deliveryDate: Scalars['String'];
  id: Scalars['ID'];
  isPaid: Scalars['Boolean'];
  number: Scalars['String'];
  paymentDate: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  priceId: Scalars['String'];
  rejectionCode?: Maybe<Scalars['Int']>;
  rejectionReason?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  term: Scalars['Int'];
  totalAmount?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
  updatedAt: Scalars['String'];
  volume: Scalars['Int'];
};

export type OrderFilter_Order = {
  deliveryDate?: InputMaybe<Scalars['String']>;
  isPaid?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  paymentDate?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<Scalars['String']>;
  totalAmount?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['String']>;
};

/** Page connection for Order. */
export type OrderPageConnection = {
  __typename?: 'OrderPageConnection';
  collection?: Maybe<Array<Maybe<Order>>>;
  paginationInfo: OrderPaginationInfo;
};

/** Information about the pagination. */
export type OrderPaginationInfo = {
  __typename?: 'OrderPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Period = Node & {
  __typename?: 'Period';
  endMonth?: Maybe<Scalars['String']>;
  endQuarter?: Maybe<Scalars['String']>;
  endYear?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  startMonth?: Maybe<Scalars['String']>;
  startQuarter?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['String']>;
};

/** Cursor connection for Period. */
export type PeriodCursorConnection = {
  __typename?: 'PeriodCursorConnection';
  edges?: Maybe<Array<Maybe<PeriodEdge>>>;
  pageInfo: PeriodPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Period. */
export type PeriodEdge = {
  __typename?: 'PeriodEdge';
  cursor: Scalars['String'];
  node?: Maybe<Period>;
};

export type PeriodInputType = {
  /** End month of period. */
  endMonth?: InputMaybe<Scalars['String']>;
  /** End quarter of period. */
  endQuarter?: InputMaybe<Scalars['String']>;
  /** End year of period. */
  endYear?: InputMaybe<Scalars['String']>;
  /** Start month of period. */
  startMonth?: InputMaybe<Scalars['String']>;
  /** Start quarter of period. */
  startQuarter?: InputMaybe<Scalars['String']>;
  /** Start year of period. */
  startYear?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type PeriodPageInfo = {
  __typename?: 'PeriodPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Phase = Node & {
  __typename?: 'Phase';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PostImage = Node & {
  __typename?: 'PostImage';
  contentUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Account>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Cursor connection for PostImage. */
export type PostImageCursorConnection = {
  __typename?: 'PostImageCursorConnection';
  edges?: Maybe<Array<Maybe<PostImageEdge>>>;
  pageInfo: PostImagePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of PostImage. */
export type PostImageEdge = {
  __typename?: 'PostImageEdge';
  cursor: Scalars['String'];
  node?: Maybe<PostImage>;
};

/** Information about the current page. */
export type PostImagePageInfo = {
  __typename?: 'PostImagePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PriceWidgetCalculation = Node & {
  __typename?: 'PriceWidgetCalculation';
  clientCompany: ClientCompany;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Account>;
  deletedAt: Scalars['String'];
  id: Scalars['ID'];
  pendingEUAsToPurchase: Scalars['Int'];
  remainingBudget: Scalars['Float'];
  requiredEUAs: Scalars['Int'];
  totalBudget: Scalars['Float'];
  yearToDateAveragePrice: Scalars['Float'];
  yearToDateCosts: Scalars['Float'];
};

/** Cursor connection for PriceWidgetCalculation. */
export type PriceWidgetCalculationCursorConnection = {
  __typename?: 'PriceWidgetCalculationCursorConnection';
  edges?: Maybe<Array<Maybe<PriceWidgetCalculationEdge>>>;
  pageInfo: PriceWidgetCalculationPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of PriceWidgetCalculation. */
export type PriceWidgetCalculationEdge = {
  __typename?: 'PriceWidgetCalculationEdge';
  cursor: Scalars['String'];
  node?: Maybe<PriceWidgetCalculation>;
};

export type PriceWidgetCalculationFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type PriceWidgetCalculationPageInfo = {
  __typename?: 'PriceWidgetCalculationPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Product = Node & {
  __typename?: 'Product';
  _id?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  afsCommodities?: Maybe<Array<Maybe<AfsCommodity>>>;
  blogPosts?: Maybe<BlogPostPageConnection>;
  clientCompanies?: Maybe<ClientCompanyProductCursorConnection>;
  clientProductPrices?: Maybe<ClientProductPricePageConnection>;
  clients?: Maybe<AccountPageConnection>;
  commodities?: Maybe<Array<Maybe<Commodity>>>;
  createdAt: Scalars['String'];
  currencies?: Maybe<Array<Maybe<Currency>>>;
  deletedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inAppEvents?: Maybe<InAppEventPageConnection>;
  incoterms?: Maybe<Array<Maybe<Incoterm>>>;
  indicationOfInterests?: Maybe<IndicationOfInterestPageConnection>;
  iri: Scalars['String'];
  metrics?: Maybe<Array<Maybe<Metric>>>;
  modules?: Maybe<ModuleCursorConnection>;
  name: Scalars['String'];
  newsletters?: Maybe<NewsletterPageConnection>;
  productDocumentCategories?: Maybe<ProductDocumentCategoryCursorConnection>;
  productDocuments?: Maybe<ProductDocumentPageConnection>;
  rfqs?: Maybe<RfqPageConnection>;
  sustainabilityAdvisors?: Maybe<AccountSustainabilityAdvisorProductCursorConnection>;
  updatedAt: Scalars['String'];
  webinars?: Maybe<WebinarPageConnection>;
};


export type ProductAfsCommoditiesArgs = {
  name?: InputMaybe<Scalars['String']>;
  name_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  products?: InputMaybe<Scalars['String']>;
  products_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type ProductBlogPostsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<BlogPostFilter_Order>> | InputMaybe<BlogPostFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['String']>;
  product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type ProductClientCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type ProductClientProductPricesArgs = {
  datePeriod?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['String']>;
  product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  productionYear?: InputMaybe<Scalars['Int']>;
  productionYear_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  technology?: InputMaybe<Scalars['String']>;
  technology_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  yearOfRelease?: InputMaybe<Scalars['Int']>;
  yearOfRelease_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
};


export type ProductClientsArgs = {
  clientCompany_id?: InputMaybe<Scalars['String']>;
  clientCompany_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  companyRepresentativeProducts_id?: InputMaybe<Scalars['String']>;
  companyRepresentativeProducts_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<AccountFilter_Order>> | InputMaybe<AccountFilter_Order>>;
  orderByFullName?: InputMaybe<Scalars['String']>;
  orderByStatus?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  sustainabilityAdvisorProducts_product_id?: InputMaybe<Scalars['String']>;
  sustainabilityAdvisorProducts_product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  type?: InputMaybe<Scalars['String']>;
  type_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type ProductCommoditiesArgs = {
  product_id?: InputMaybe<Scalars['String']>;
  product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type ProductCurrenciesArgs = {
  products?: InputMaybe<Scalars['String']>;
  products_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type ProductInAppEventsArgs = {
  dateRange?: InputMaybe<Array<InputMaybe<InAppEventFilter_DateRange>> | InputMaybe<InAppEventFilter_DateRange>>;
  eventDate?: InputMaybe<Scalars['String']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type ProductIncotermsArgs = {
  products?: InputMaybe<Scalars['String']>;
  products_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type ProductIndicationOfInterestsArgs = {
  expiresAt?: InputMaybe<Array<InputMaybe<IndicationOfInterestFilter_ExpiresAt>> | InputMaybe<IndicationOfInterestFilter_ExpiresAt>>;
  isExpired?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type ProductMetricsArgs = {
  products?: InputMaybe<Scalars['String']>;
  products_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type ProductModulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type ProductNewslettersArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<NewsletterFilter_Order>> | InputMaybe<NewsletterFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  products_id?: InputMaybe<Scalars['String']>;
  products_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type ProductProductDocumentCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  product?: InputMaybe<Scalars['String']>;
  product_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type ProductProductDocumentsArgs = {
  createdBy?: InputMaybe<Scalars['String']>;
  createdBy_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  exists?: InputMaybe<Array<InputMaybe<ProductDocumentFilter_Exists>> | InputMaybe<ProductDocumentFilter_Exists>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<ProductDocumentFilter_Order>> | InputMaybe<ProductDocumentFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  productSpecificDocuments?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type ProductRfqsArgs = {
  client?: InputMaybe<Scalars['String']>;
  customOrder?: InputMaybe<Array<InputMaybe<RfqFilter_CustomOrder>> | InputMaybe<RfqFilter_CustomOrder>>;
  id?: InputMaybe<Scalars['String']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<RfqFilter_Order>> | InputMaybe<RfqFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['String']>;
  product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
};


export type ProductSustainabilityAdvisorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type ProductWebinarsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<WebinarFilter_Order>> | InputMaybe<WebinarFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['String']>;
  product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};

export type ProductAccessRequest = Node & {
  __typename?: 'ProductAccessRequest';
  _id: Scalars['String'];
  account?: Maybe<Account>;
  createdAt: Scalars['String'];
  deletedAt: Scalars['String'];
  id: Scalars['ID'];
  product?: Maybe<Product>;
  rejectionReason?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type ProductAccessRequestFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']>;
};

/** Page connection for ProductAccessRequest. */
export type ProductAccessRequestPageConnection = {
  __typename?: 'ProductAccessRequestPageConnection';
  collection?: Maybe<Array<Maybe<ProductAccessRequest>>>;
  paginationInfo: ProductAccessRequestPaginationInfo;
};

/** Information about the pagination. */
export type ProductAccessRequestPaginationInfo = {
  __typename?: 'ProductAccessRequestPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

/** Cursor connection for Product. */
export type ProductCursorConnection = {
  __typename?: 'ProductCursorConnection';
  edges?: Maybe<Array<Maybe<ProductEdge>>>;
  pageInfo: ProductPageInfo;
  totalCount: Scalars['Int'];
};

export type ProductDocument = Node & {
  __typename?: 'ProductDocument';
  approvedAt?: Maybe<Scalars['String']>;
  canBeDeleted: Scalars['Boolean'];
  canBeUpdated: Scalars['Boolean'];
  contentUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Account>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Account>;
  filePath?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  product: Product;
  productDocumentCategory?: Maybe<ProductDocumentCategory>;
  rejectionReason?: Maybe<Scalars['String']>;
  restoredAt?: Maybe<Scalars['String']>;
  restoredBy?: Maybe<Account>;
  reviewedBy?: Maybe<Account>;
  /** Client Companies with access to the product document. */
  sharedWith?: Maybe<ClientCompanyPageConnection>;
  status: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Account>;
};


export type ProductDocumentSharedWithArgs = {
  client?: InputMaybe<Scalars['String']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ClientCompanyFilter_Order>> | InputMaybe<ClientCompanyFilter_Order>>;
  orderByStatus?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  primarySubEntity_legalName?: InputMaybe<Scalars['String']>;
  primarySubEntity_zohoAccount?: InputMaybe<Scalars['String']>;
  primarySubEntity_zohoAccount_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  products_product_id?: InputMaybe<Scalars['String']>;
  products_product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};

export type ProductDocumentCategory = Node & {
  __typename?: 'ProductDocumentCategory';
  canBeDeleted: Scalars['Boolean'];
  canBeUpdated: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  product: Product;
  productDocuments?: Maybe<ProductDocumentPageConnection>;
};


export type ProductDocumentCategoryProductDocumentsArgs = {
  createdBy?: InputMaybe<Scalars['String']>;
  createdBy_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  exists?: InputMaybe<Array<InputMaybe<ProductDocumentFilter_Exists>> | InputMaybe<ProductDocumentFilter_Exists>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<ProductDocumentFilter_Order>> | InputMaybe<ProductDocumentFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  productSpecificDocuments?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};

/** Cursor connection for ProductDocumentCategory. */
export type ProductDocumentCategoryCursorConnection = {
  __typename?: 'ProductDocumentCategoryCursorConnection';
  edges?: Maybe<Array<Maybe<ProductDocumentCategoryEdge>>>;
  pageInfo: ProductDocumentCategoryPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of ProductDocumentCategory. */
export type ProductDocumentCategoryEdge = {
  __typename?: 'ProductDocumentCategoryEdge';
  cursor: Scalars['String'];
  node?: Maybe<ProductDocumentCategory>;
};

/** Information about the current page. */
export type ProductDocumentCategoryPageInfo = {
  __typename?: 'ProductDocumentCategoryPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type ProductDocumentFilter_Exists = {
  productDocumentCategory?: InputMaybe<Scalars['Boolean']>;
};

export type ProductDocumentFilter_Order = {
  approvedAt?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

/** Page connection for ProductDocument. */
export type ProductDocumentPageConnection = {
  __typename?: 'ProductDocumentPageConnection';
  collection?: Maybe<Array<Maybe<ProductDocument>>>;
  paginationInfo: ProductDocumentPaginationInfo;
};

/** Information about the pagination. */
export type ProductDocumentPaginationInfo = {
  __typename?: 'ProductDocumentPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

/** Edge of Product. */
export type ProductEdge = {
  __typename?: 'ProductEdge';
  cursor: Scalars['String'];
  node?: Maybe<Product>;
};

/** Information about the current page. */
export type ProductPageInfo = {
  __typename?: 'ProductPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type QuarterOfDelivery = Node & {
  __typename?: 'QuarterOfDelivery';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  accountNotificationSettings?: Maybe<AccountNotificationSettingCursorConnection>;
  accounts?: Maybe<AccountPageConnection>;
  actualDeliveries?: Maybe<ActualDeliveryPageConnection>;
  afsCommodities?: Maybe<Array<Maybe<AfsCommodity>>>;
  afsCommodity?: Maybe<AfsCommodity>;
  apiKeys?: Maybe<ApiKeyCursorConnection>;
  avatar?: Maybe<Avatar>;
  avatars?: Maybe<AvatarCursorConnection>;
  blogPost?: Maybe<BlogPost>;
  blogPosts?: Maybe<BlogPostPageConnection>;
  calendarEvent?: Maybe<CalendarEvent>;
  calendarEvents?: Maybe<CalendarEventPageConnection>;
  certifications?: Maybe<Array<Maybe<Certification>>>;
  chatMessage?: Maybe<ChatMessage>;
  chatMessages?: Maybe<ChatMessagePageConnection>;
  chatRoom?: Maybe<ChatRoom>;
  chatRooms?: Maybe<ChatRoomPageConnection>;
  clientCompanies?: Maybe<ClientCompanyPageConnection>;
  clientCompany?: Maybe<ClientCompany>;
  clientCompanyDocument?: Maybe<ClientCompanyDocument>;
  clientCompanyDocuments?: Maybe<ClientCompanyDocumentPageConnection>;
  clientCompanyProducts?: Maybe<ClientCompanyProductCursorConnection>;
  clientDocumentCategories?: Maybe<ClientDocumentCategoryCursorConnection>;
  clientDocumentCategory?: Maybe<ClientDocumentCategory>;
  clientProductPrices?: Maybe<ClientProductPricePageConnection>;
  commodities?: Maybe<Array<Maybe<Commodity>>>;
  commodity?: Maybe<Commodity>;
  commodityVolumesMonth?: Maybe<CommodityVolumesMonth>;
  commodityVolumesMonths?: Maybe<CommodityVolumesMonthPageConnection>;
  commodityVolumesYear?: Maybe<CommodityVolumesYear>;
  commodityVolumesYears?: Maybe<CommodityVolumesYearPageConnection>;
  countries?: Maybe<Array<Maybe<Country>>>;
  currencies?: Maybe<Array<Maybe<Currency>>>;
  dashboardGroup?: Maybe<DashboardGroup>;
  dashboardGroups?: Maybe<DashboardGroupCursorConnection>;
  deliveries?: Maybe<DeliveryPageConnection>;
  exchangePlugStatus?: Maybe<ExchangePlugStatus>;
  exchangePlugStatuses?: Maybe<ExchangePlugStatusPageConnection>;
  inAppEvent?: Maybe<InAppEvent>;
  inAppEvents?: Maybe<InAppEventPageConnection>;
  incoterm?: Maybe<Incoterm>;
  incoterms?: Maybe<Array<Maybe<Incoterm>>>;
  indicationOfInterest?: Maybe<IndicationOfInterest>;
  indicationOfInterests?: Maybe<IndicationOfInterestPageConnection>;
  invoices?: Maybe<InvoicePageConnection>;
  masterAgreements?: Maybe<Array<Maybe<MasterAgreement>>>;
  metrics?: Maybe<Array<Maybe<Metric>>>;
  module?: Maybe<Module>;
  modules?: Maybe<ModuleCursorConnection>;
  newsletter?: Maybe<Newsletter>;
  newsletters?: Maybe<NewsletterPageConnection>;
  node?: Maybe<Node>;
  notifications?: Maybe<NotificationPageConnection>;
  offer?: Maybe<Offer>;
  offers?: Maybe<OfferPageConnection>;
  order?: Maybe<Order>;
  orders?: Maybe<OrderPageConnection>;
  period?: Maybe<Period>;
  periods?: Maybe<PeriodCursorConnection>;
  phases?: Maybe<Array<Maybe<Phase>>>;
  postImages?: Maybe<PostImageCursorConnection>;
  priceWidgetCalculation?: Maybe<PriceWidgetCalculation>;
  priceWidgetCalculations?: Maybe<PriceWidgetCalculationCursorConnection>;
  product?: Maybe<Product>;
  productAccessRequest?: Maybe<ProductAccessRequest>;
  productAccessRequests?: Maybe<ProductAccessRequestPageConnection>;
  productDocument?: Maybe<ProductDocument>;
  productDocumentCategories?: Maybe<ProductDocumentCategoryCursorConnection>;
  productDocumentCategory?: Maybe<ProductDocumentCategory>;
  productDocuments?: Maybe<ProductDocumentPageConnection>;
  products?: Maybe<ProductCursorConnection>;
  quarterOfDeliveries?: Maybe<Array<Maybe<QuarterOfDelivery>>>;
  regions?: Maybe<Array<Maybe<Region>>>;
  rfq?: Maybe<Rfq>;
  rfqProductConfig?: Maybe<RfqProductConfig>;
  rfqProductConfigs?: Maybe<Array<Maybe<RfqProductConfig>>>;
  rfqs?: Maybe<RfqPageConnection>;
  subEntities?: Maybe<SubEntityCursorConnection>;
  subEntity?: Maybe<SubEntity>;
  technologies?: Maybe<Array<Maybe<Technology>>>;
  trades?: Maybe<TradePageConnection>;
  transactionDealStatuses?: Maybe<Array<Maybe<TransactionDealStatus>>>;
  transactions?: Maybe<TransactionPageConnection>;
  typeOfPackagings?: Maybe<Array<Maybe<TypeOfPackaging>>>;
  webinar?: Maybe<Webinar>;
  webinars?: Maybe<WebinarPageConnection>;
  yearOfDeliveries?: Maybe<Array<Maybe<YearOfDelivery>>>;
  zohoAccounts?: Maybe<ZohoAccountPageConnection>;
};


export type QueryAccountArgs = {
  id: Scalars['ID'];
};


export type QueryAccountNotificationSettingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryAccountsArgs = {
  clientCompany_id?: InputMaybe<Scalars['String']>;
  clientCompany_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  companyRepresentativeProducts_id?: InputMaybe<Scalars['String']>;
  companyRepresentativeProducts_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<AccountFilter_Order>> | InputMaybe<AccountFilter_Order>>;
  orderByFullName?: InputMaybe<Scalars['String']>;
  orderByStatus?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  sustainabilityAdvisorProducts_product_id?: InputMaybe<Scalars['String']>;
  sustainabilityAdvisorProducts_product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  type?: InputMaybe<Scalars['String']>;
  type_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryActualDeliveriesArgs = {
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAfsCommoditiesArgs = {
  name?: InputMaybe<Scalars['String']>;
  name_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  products?: InputMaybe<Scalars['String']>;
  products_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryAfsCommodityArgs = {
  id: Scalars['ID'];
};


export type QueryApiKeysArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryAvatarArgs = {
  id: Scalars['ID'];
};


export type QueryAvatarsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryBlogPostArgs = {
  id: Scalars['ID'];
};


export type QueryBlogPostsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<BlogPostFilter_Order>> | InputMaybe<BlogPostFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['String']>;
  product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryCalendarEventArgs = {
  id: Scalars['ID'];
};


export type QueryCalendarEventsArgs = {
  dateRange?: InputMaybe<Array<InputMaybe<CalendarEventFilter_DateRange>> | InputMaybe<CalendarEventFilter_DateRange>>;
  eventDate?: InputMaybe<Scalars['String']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryChatMessageArgs = {
  id: Scalars['ID'];
};


export type QueryChatMessagesArgs = {
  chatRoom?: InputMaybe<Scalars['String']>;
  chatRoom_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryChatRoomArgs = {
  id: Scalars['ID'];
};


export type QueryChatRoomsArgs = {
  clientCompany_primarySubEntity_legalName?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ChatRoomFilter_Order>> | InputMaybe<ChatRoomFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  participants_firstName?: InputMaybe<Scalars['String']>;
  participants_lastName?: InputMaybe<Scalars['String']>;
  participants_middleName?: InputMaybe<Scalars['String']>;
  participants_type?: InputMaybe<Scalars['String']>;
  participants_type_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryClientCompaniesArgs = {
  client?: InputMaybe<Scalars['String']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ClientCompanyFilter_Order>> | InputMaybe<ClientCompanyFilter_Order>>;
  orderByStatus?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  primarySubEntity_legalName?: InputMaybe<Scalars['String']>;
  primarySubEntity_zohoAccount?: InputMaybe<Scalars['String']>;
  primarySubEntity_zohoAccount_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  products_product_id?: InputMaybe<Scalars['String']>;
  products_product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryClientCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryClientCompanyDocumentArgs = {
  id: Scalars['ID'];
};


export type QueryClientCompanyDocumentsArgs = {
  client?: InputMaybe<Scalars['String']>;
  clientCompany?: InputMaybe<Scalars['String']>;
  clientCompany_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  exists?: InputMaybe<Array<InputMaybe<ClientCompanyDocumentFilter_Exists>> | InputMaybe<ClientCompanyDocumentFilter_Exists>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<ClientCompanyDocumentFilter_Order>> | InputMaybe<ClientCompanyDocumentFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryClientCompanyProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryClientDocumentCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientCompany?: InputMaybe<Scalars['String']>;
  clientCompany_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryClientDocumentCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryClientProductPricesArgs = {
  datePeriod?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['String']>;
  product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  productionYear?: InputMaybe<Scalars['Int']>;
  productionYear_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  technology?: InputMaybe<Scalars['String']>;
  technology_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  yearOfRelease?: InputMaybe<Scalars['Int']>;
  yearOfRelease_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
};


export type QueryCommoditiesArgs = {
  product_id?: InputMaybe<Scalars['String']>;
  product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryCommodityArgs = {
  id: Scalars['ID'];
};


export type QueryCommodityVolumesMonthArgs = {
  id: Scalars['ID'];
};


export type QueryCommodityVolumesMonthsArgs = {
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryCommodityVolumesYearArgs = {
  id: Scalars['ID'];
};


export type QueryCommodityVolumesYearsArgs = {
  company_id?: InputMaybe<Scalars['String']>;
  company_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['String']>;
  product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryCountriesArgs = {
  name?: InputMaybe<Scalars['String']>;
  name_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  regions?: InputMaybe<Scalars['String']>;
  regions_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryCurrenciesArgs = {
  products?: InputMaybe<Scalars['String']>;
  products_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryDashboardGroupArgs = {
  id: Scalars['ID'];
};


export type QueryDashboardGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryDeliveriesArgs = {
  invoicesCompleted?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  paymentDate?: InputMaybe<Array<InputMaybe<DeliveryFilter_PaymentDate>> | InputMaybe<DeliveryFilter_PaymentDate>>;
  product?: InputMaybe<Scalars['String']>;
  transaction_buySell?: InputMaybe<Scalars['String']>;
  transaction_buySell_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryExchangePlugStatusArgs = {
  id: Scalars['ID'];
};


export type QueryExchangePlugStatusesArgs = {
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryInAppEventArgs = {
  id: Scalars['ID'];
};


export type QueryInAppEventsArgs = {
  dateRange?: InputMaybe<Array<InputMaybe<InAppEventFilter_DateRange>> | InputMaybe<InAppEventFilter_DateRange>>;
  eventDate?: InputMaybe<Scalars['String']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryIncotermArgs = {
  id: Scalars['ID'];
};


export type QueryIncotermsArgs = {
  products?: InputMaybe<Scalars['String']>;
  products_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryIndicationOfInterestArgs = {
  id: Scalars['ID'];
};


export type QueryIndicationOfInterestsArgs = {
  expiresAt?: InputMaybe<Array<InputMaybe<IndicationOfInterestFilter_ExpiresAt>> | InputMaybe<IndicationOfInterestFilter_ExpiresAt>>;
  isExpired?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryInvoicesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  transactions_id?: InputMaybe<Scalars['Int']>;
  transactions_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  transactions_trade_id?: InputMaybe<Scalars['Int']>;
  transactions_trade_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
};


export type QueryMasterAgreementsArgs = {
  clientCompany_id?: InputMaybe<Scalars['String']>;
  clientCompany_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  id?: InputMaybe<Scalars['Int']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
};


export type QueryMetricsArgs = {
  products?: InputMaybe<Scalars['String']>;
  products_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryModuleArgs = {
  id: Scalars['ID'];
};


export type QueryModulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryNewsletterArgs = {
  id: Scalars['ID'];
};


export type QueryNewslettersArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<NewsletterFilter_Order>> | InputMaybe<NewsletterFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  products_id?: InputMaybe<Scalars['String']>;
  products_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<NotificationFilter_Order>> | InputMaybe<NotificationFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryOfferArgs = {
  id: Scalars['ID'];
};


export type QueryOffersArgs = {
  order?: InputMaybe<Array<InputMaybe<OfferFilter_Order>> | InputMaybe<OfferFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrdersArgs = {
  order?: InputMaybe<Array<InputMaybe<OrderFilter_Order>> | InputMaybe<OrderFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryPeriodArgs = {
  id: Scalars['ID'];
};


export type QueryPeriodsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  rfq?: InputMaybe<Scalars['String']>;
  rfq_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryPostImagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryPriceWidgetCalculationArgs = {
  id: Scalars['ID'];
};


export type QueryPriceWidgetCalculationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientCompany_id?: InputMaybe<Scalars['String']>;
  clientCompany_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<PriceWidgetCalculationFilter_Order>> | InputMaybe<PriceWidgetCalculationFilter_Order>>;
};


export type QueryProductArgs = {
  id: Scalars['ID'];
};


export type QueryProductAccessRequestArgs = {
  id: Scalars['ID'];
};


export type QueryProductAccessRequestsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<ProductAccessRequestFilter_Order>> | InputMaybe<ProductAccessRequestFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryProductDocumentArgs = {
  id: Scalars['ID'];
};


export type QueryProductDocumentCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  product?: InputMaybe<Scalars['String']>;
  product_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryProductDocumentCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryProductDocumentsArgs = {
  createdBy?: InputMaybe<Scalars['String']>;
  createdBy_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  exists?: InputMaybe<Array<InputMaybe<ProductDocumentFilter_Exists>> | InputMaybe<ProductDocumentFilter_Exists>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<ProductDocumentFilter_Order>> | InputMaybe<ProductDocumentFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  productSpecificDocuments?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryRegionsArgs = {
  commodities?: InputMaybe<Scalars['String']>;
  commodities_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryRfqArgs = {
  id: Scalars['ID'];
};


export type QueryRfqProductConfigArgs = {
  id: Scalars['ID'];
};


export type QueryRfqsArgs = {
  client?: InputMaybe<Scalars['String']>;
  customOrder?: InputMaybe<Array<InputMaybe<RfqFilter_CustomOrder>> | InputMaybe<RfqFilter_CustomOrder>>;
  id?: InputMaybe<Scalars['String']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<RfqFilter_Order>> | InputMaybe<RfqFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['String']>;
  product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerySubEntitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QuerySubEntityArgs = {
  id: Scalars['ID'];
};


export type QueryTechnologiesArgs = {
  commodities?: InputMaybe<Scalars['String']>;
  commodities_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryTradesArgs = {
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionsArgs = {
  commodity?: InputMaybe<Scalars['String']>;
  commodity_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  euaHistory?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<TransactionFilter_Order>> | InputMaybe<TransactionFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  primarySustainabilityAdvisor_id?: InputMaybe<Scalars['String']>;
  primarySustainabilityAdvisor_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  product?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  secondarySustainabilityAdvisors_id?: InputMaybe<Scalars['String']>;
  secondarySustainabilityAdvisors_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  status?: InputMaybe<Scalars['String']>;
  tradeDate?: InputMaybe<Array<InputMaybe<TransactionFilter_TradeDate>> | InputMaybe<TransactionFilter_TradeDate>>;
};


export type QueryWebinarArgs = {
  id: Scalars['ID'];
};


export type QueryWebinarsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<WebinarFilter_Order>> | InputMaybe<WebinarFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['String']>;
  product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type QueryZohoAccountsArgs = {
  assigned?: InputMaybe<Scalars['Boolean']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ZohoAccountFilter_Order>> | InputMaybe<ZohoAccountFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
};

export type Region = Node & {
  __typename?: 'Region';
  countries?: Maybe<Array<Maybe<Country>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type RegionCountriesArgs = {
  name?: InputMaybe<Scalars['String']>;
  name_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  regions?: InputMaybe<Scalars['String']>;
  regions_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};

export type Rfq = Node & {
  __typename?: 'Rfq';
  certifications?: Maybe<Scalars['Iterable']>;
  clientCompany?: Maybe<ClientCompany>;
  commodities?: Maybe<Array<Maybe<Commodity>>>;
  countries?: Maybe<Array<Maybe<Country>>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Account>;
  currency?: Maybe<Currency>;
  id: Scalars['ID'];
  incoterms?: Maybe<Array<Maybe<Incoterm>>>;
  isBuy?: Maybe<Scalars['Boolean']>;
  isConsumption?: Maybe<Scalars['Boolean']>;
  metric?: Maybe<Metric>;
  note?: Maybe<Scalars['String']>;
  periods?: Maybe<PeriodCursorConnection>;
  phases?: Maybe<Scalars['Iterable']>;
  price?: Maybe<Scalars['Float']>;
  product?: Maybe<Product>;
  quarterOfDelivery?: Maybe<Scalars['String']>;
  regions?: Maybe<Array<Maybe<Region>>>;
  technologies?: Maybe<Array<Maybe<Technology>>>;
  typeOfPackaging?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['Float']>;
  yearOfDelivery?: Maybe<Scalars['String']>;
};


export type RfqCommoditiesArgs = {
  product_id?: InputMaybe<Scalars['String']>;
  product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type RfqCountriesArgs = {
  name?: InputMaybe<Scalars['String']>;
  name_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  regions?: InputMaybe<Scalars['String']>;
  regions_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type RfqIncotermsArgs = {
  products?: InputMaybe<Scalars['String']>;
  products_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type RfqPeriodsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  rfq?: InputMaybe<Scalars['String']>;
  rfq_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type RfqRegionsArgs = {
  commodities?: InputMaybe<Scalars['String']>;
  commodities_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type RfqTechnologiesArgs = {
  commodities?: InputMaybe<Scalars['String']>;
  commodities_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};

export type RfqFilter_CustomOrder = {
  buySell?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
};

export type RfqFilter_Order = {
  clientCompany_primarySubEntity_legalName?: InputMaybe<Scalars['String']>;
  countries_name?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  regions_name?: InputMaybe<Scalars['String']>;
  technologies_name?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['String']>;
};

/** Page connection for Rfq. */
export type RfqPageConnection = {
  __typename?: 'RfqPageConnection';
  collection?: Maybe<Array<Maybe<Rfq>>>;
  paginationInfo: RfqPaginationInfo;
};

/** Information about the pagination. */
export type RfqPaginationInfo = {
  __typename?: 'RfqPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type RfqProductConfig = Node & {
  __typename?: 'RfqProductConfig';
  config?: Maybe<Scalars['Iterable']>;
  id: Scalars['ID'];
  productId?: Maybe<Scalars['String']>;
};

export type SubEntity = Node & {
  __typename?: 'SubEntity';
  addition?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  chamberOfCommerce?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clientCompany?: Maybe<ClientCompany>;
  country?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legalEmail?: Maybe<Scalars['String']>;
  legalFullName?: Maybe<Scalars['String']>;
  legalName: Scalars['String'];
  lei?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  primary: Scalars['Boolean'];
  status?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['String']>;
  zohoAccount?: Maybe<ZohoAccount>;
};

/** Cursor connection for SubEntity. */
export type SubEntityCursorConnection = {
  __typename?: 'SubEntityCursorConnection';
  edges?: Maybe<Array<Maybe<SubEntityEdge>>>;
  pageInfo: SubEntityPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of SubEntity. */
export type SubEntityEdge = {
  __typename?: 'SubEntityEdge';
  cursor: Scalars['String'];
  node?: Maybe<SubEntity>;
};

/** Information about the current page. */
export type SubEntityPageInfo = {
  __typename?: 'SubEntityPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type SustainabilityAdvisorProductInputType = {
  /** ID of the product */
  id: Scalars['ID'];
  /** Indicates if the sustainability advisor is manager of the product */
  isProductManager: Scalars['Boolean'];
};

export type SustainabilityAdvisorsProductInputType = {
  /** ID of the company */
  clientCompany: Scalars['ID'];
  /** ID of the product */
  products: Array<InputMaybe<Scalars['ID']>>;
};

export type Technology = Node & {
  __typename?: 'Technology';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Trade = Node & {
  __typename?: 'Trade';
  dealCompleted?: Maybe<Scalars['Boolean']>;
  fullofftake?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  principal?: Maybe<Scalars['String']>;
  tradeNumber?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionPageConnection>;
};


export type TradeTransactionsArgs = {
  commodity?: InputMaybe<Scalars['String']>;
  commodity_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  euaHistory?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<TransactionFilter_Order>> | InputMaybe<TransactionFilter_Order>>;
  page?: InputMaybe<Scalars['Int']>;
  primarySustainabilityAdvisor_id?: InputMaybe<Scalars['String']>;
  primarySustainabilityAdvisor_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  product?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  secondarySustainabilityAdvisors_id?: InputMaybe<Scalars['String']>;
  secondarySustainabilityAdvisors_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  status?: InputMaybe<Scalars['String']>;
  tradeDate?: InputMaybe<Array<InputMaybe<TransactionFilter_TradeDate>> | InputMaybe<TransactionFilter_TradeDate>>;
};

/** Page connection for Trade. */
export type TradePageConnection = {
  __typename?: 'TradePageConnection';
  collection?: Maybe<Array<Maybe<Trade>>>;
  paginationInfo: TradePaginationInfo;
};

/** Information about the pagination. */
export type TradePaginationInfo = {
  __typename?: 'TradePaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Transaction = Node & {
  __typename?: 'Transaction';
  brokerEmail?: Maybe<Scalars['String']>;
  brokerFee?: Maybe<Scalars['String']>;
  brokerName?: Maybe<Scalars['String']>;
  buySell?: Maybe<Scalars['String']>;
  cancelledOn?: Maybe<Scalars['String']>;
  certificate?: Maybe<Scalars['String']>;
  commodity?: Maybe<Scalars['String']>;
  commodityFunction?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  countDeliveredDeliveries?: Maybe<Scalars['Int']>;
  countReceivedPayments?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  dealPrice?: Maybe<Scalars['String']>;
  deliveries?: Maybe<DeliveryPageConnection>;
  deliveryDate?: Maybe<Scalars['String']>;
  deliveryStatus?: Maybe<Scalars['String']>;
  deliveryType?: Maybe<Scalars['String']>;
  documentStatus?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  importAndCancellationCosts?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoicePageConnection>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  masterAgreement?: Maybe<MasterAgreement>;
  paymentStatus?: Maybe<Scalars['String']>;
  periodType?: Maybe<Scalars['String']>;
  primaryCompanyRepresentative?: Maybe<Account>;
  primaryContactEmail?: Maybe<Scalars['String']>;
  primarySustainabilityAdvisor?: Maybe<Account>;
  productionPeriod?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  secondaryBrokerEmails?: Maybe<Scalars['Iterable']>;
  secondaryCompanyRepresentatives?: Maybe<AccountPageConnection>;
  secondaryContactEmails?: Maybe<Scalars['Iterable']>;
  secondarySustainabilityAdvisors?: Maybe<AccountPageConnection>;
  signedContractFile?: Maybe<Scalars['String']>;
  signedTcFile?: Maybe<Scalars['String']>;
  subtechnology?: Maybe<Scalars['String']>;
  support?: Maybe<Scalars['String']>;
  technology?: Maybe<Scalars['String']>;
  trade: Trade;
  tradeConfirmationStatus?: Maybe<Scalars['String']>;
  tradeDate?: Maybe<Scalars['String']>;
  tradeNumber?: Maybe<Scalars['String']>;
  transactionDealStatus?: Maybe<Scalars['String']>;
  unitOfMeasurement?: Maybe<Scalars['String']>;
  unsignedContractFile?: Maybe<Scalars['String']>;
  unsignedTcFile?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['Int']>;
  zohoAccount: ZohoAccount;
};


export type TransactionDeliveriesArgs = {
  invoicesCompleted?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  paymentDate?: InputMaybe<Array<InputMaybe<DeliveryFilter_PaymentDate>> | InputMaybe<DeliveryFilter_PaymentDate>>;
  product?: InputMaybe<Scalars['String']>;
  transaction_buySell?: InputMaybe<Scalars['String']>;
  transaction_buySell_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type TransactionInvoicesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  transactions_id?: InputMaybe<Scalars['Int']>;
  transactions_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  transactions_trade_id?: InputMaybe<Scalars['Int']>;
  transactions_trade_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
};


export type TransactionSecondaryCompanyRepresentativesArgs = {
  clientCompany_id?: InputMaybe<Scalars['String']>;
  clientCompany_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  companyRepresentativeProducts_id?: InputMaybe<Scalars['String']>;
  companyRepresentativeProducts_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<AccountFilter_Order>> | InputMaybe<AccountFilter_Order>>;
  orderByFullName?: InputMaybe<Scalars['String']>;
  orderByStatus?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  sustainabilityAdvisorProducts_product_id?: InputMaybe<Scalars['String']>;
  sustainabilityAdvisorProducts_product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  type?: InputMaybe<Scalars['String']>;
  type_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};


export type TransactionSecondarySustainabilityAdvisorsArgs = {
  clientCompany_id?: InputMaybe<Scalars['String']>;
  clientCompany_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  companyRepresentativeProducts_id?: InputMaybe<Scalars['String']>;
  companyRepresentativeProducts_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<AccountFilter_Order>> | InputMaybe<AccountFilter_Order>>;
  orderByFullName?: InputMaybe<Scalars['String']>;
  orderByStatus?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  sustainabilityAdvisorProducts_product_id?: InputMaybe<Scalars['String']>;
  sustainabilityAdvisorProducts_product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  type?: InputMaybe<Scalars['String']>;
  type_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};

export type TransactionDealStatus = Node & {
  __typename?: 'TransactionDealStatus';
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type TransactionFilter_Order = {
  buySell?: InputMaybe<Scalars['String']>;
  commodity?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  deliveryStatus?: InputMaybe<Scalars['String']>;
  technology?: InputMaybe<Scalars['String']>;
  tradeDate?: InputMaybe<Scalars['String']>;
  tradeNumber?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['String']>;
  zohoAccount_name?: InputMaybe<Scalars['String']>;
};

export type TransactionFilter_TradeDate = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  strictly_after?: InputMaybe<Scalars['String']>;
  strictly_before?: InputMaybe<Scalars['String']>;
};

/** Page connection for Transaction. */
export type TransactionPageConnection = {
  __typename?: 'TransactionPageConnection';
  collection?: Maybe<Array<Maybe<Transaction>>>;
  paginationInfo: TransactionPaginationInfo;
};

/** Information about the pagination. */
export type TransactionPaginationInfo = {
  __typename?: 'TransactionPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type TypeOfPackaging = Node & {
  __typename?: 'TypeOfPackaging';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Webinar = Node & {
  __typename?: 'Webinar';
  cover?: Maybe<PostImage>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Account>;
  deletedAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  link: Scalars['String'];
  product?: Maybe<Product>;
  publishedAt?: Maybe<Scalars['String']>;
  rejectedBy?: Maybe<Account>;
  rejectionReason?: Maybe<Scalars['String']>;
  sharedWith?: Maybe<ClientCompanyPageConnection>;
  status: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['String'];
  youtubeVideoId?: Maybe<Scalars['String']>;
};


export type WebinarSharedWithArgs = {
  client?: InputMaybe<Scalars['String']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ClientCompanyFilter_Order>> | InputMaybe<ClientCompanyFilter_Order>>;
  orderByStatus?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  primarySubEntity_legalName?: InputMaybe<Scalars['String']>;
  primarySubEntity_zohoAccount?: InputMaybe<Scalars['String']>;
  primarySubEntity_zohoAccount_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  products_product_id?: InputMaybe<Scalars['String']>;
  products_product_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
};

export type WebinarFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

/** Page connection for Webinar. */
export type WebinarPageConnection = {
  __typename?: 'WebinarPageConnection';
  collection?: Maybe<Array<Maybe<Webinar>>>;
  paginationInfo: WebinarPaginationInfo;
};

/** Information about the pagination. */
export type WebinarPaginationInfo = {
  __typename?: 'WebinarPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type YearOfDelivery = Node & {
  __typename?: 'YearOfDelivery';
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type ZohoAccount = Node & {
  __typename?: 'ZohoAccount';
  id: Scalars['ID'];
  kycStatus?: Maybe<Scalars['String']>;
  masterAgreements?: Maybe<Array<Maybe<MasterAgreement>>>;
  name: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};


export type ZohoAccountMasterAgreementsArgs = {
  clientCompany_id?: InputMaybe<Scalars['String']>;
  clientCompany_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  id?: InputMaybe<Scalars['Int']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
};

export type ZohoAccountFilter_Order = {
  name?: InputMaybe<Scalars['String']>;
};

/** Page connection for ZohoAccount. */
export type ZohoAccountPageConnection = {
  __typename?: 'ZohoAccountPageConnection';
  collection?: Maybe<Array<Maybe<ZohoAccount>>>;
  paginationInfo: ZohoAccountPaginationInfo;
};

/** Information about the pagination. */
export type ZohoAccountPaginationInfo = {
  __typename?: 'ZohoAccountPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

/** Activates a Account. */
export type ActivateAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Token sent on the invite email. */
  inviteToken: Scalars['String'];
  /** User Password. */
  password: Scalars['String'];
};

/** Activates a Account. */
export type ActivateAccountNestedPayload = Node & {
  __typename?: 'activateAccountNestedPayload';
  _id?: Maybe<Scalars['String']>;
  activatedAt?: Maybe<Scalars['String']>;
  appointedSustainabilityAdvisorProducts?: Maybe<ClientCompanyProductCursorConnection>;
  approvedAt?: Maybe<Scalars['String']>;
  attendeeCalendarEvents?: Maybe<CalendarEventPageConnection>;
  authentication: Authentication;
  avatar?: Maybe<Avatar>;
  bio?: Maybe<Scalars['String']>;
  calendlyId?: Maybe<Scalars['String']>;
  clientCompany?: Maybe<ClientCompany>;
  companyRepresentativeProducts?: Maybe<ProductCursorConnection>;
  createdAt: Scalars['String'];
  createdInAppEvents?: Maybe<InAppEventPageConnection>;
  deletedAt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inviter?: Maybe<ActivateAccountNestedPayload>;
  isConnectedGoogleCalendar: Scalars['Boolean'];
  isCopywriter: Scalars['Boolean'];
  isTwoFactorEnabled: Scalars['Boolean'];
  lastAuthenticatedAt?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  organizerCalendarEvents?: Maybe<CalendarEventPageConnection>;
  phoneNumber?: Maybe<Scalars['String']>;
  productAccessRequests?: Maybe<ProductAccessRequestPageConnection>;
  rejectionReason?: Maybe<Scalars['String']>;
  roles: Scalars['Iterable'];
  /** Can be 'active' or 'inactive'. */
  status: Scalars['String'];
  submittedForApprovalAt?: Maybe<Scalars['String']>;
  submittedForApprovalBy?: Maybe<ActivateAccountNestedPayload>;
  sustainabilityAdvisorProducts?: Maybe<AccountSustainabilityAdvisorProductCursorConnection>;
  timezone: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['String'];
};

/** Activates a Account. */
export type ActivateAccountPayload = {
  __typename?: 'activateAccountPayload';
  account?: Maybe<ActivateAccountPayloadData>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Activates a Account. */
export type ActivateAccountPayloadData = Node & {
  __typename?: 'activateAccountPayloadData';
  _id?: Maybe<Scalars['String']>;
  activatedAt?: Maybe<Scalars['String']>;
  appointedSustainabilityAdvisorProducts?: Maybe<ClientCompanyProductCursorConnection>;
  approvedAt?: Maybe<Scalars['String']>;
  attendeeCalendarEvents?: Maybe<CalendarEventPageConnection>;
  authentication: Authentication;
  avatar?: Maybe<Avatar>;
  bio?: Maybe<Scalars['String']>;
  calendlyId?: Maybe<Scalars['String']>;
  clientCompany?: Maybe<ClientCompany>;
  companyRepresentativeProducts?: Maybe<ProductCursorConnection>;
  createdAt: Scalars['String'];
  createdInAppEvents?: Maybe<InAppEventPageConnection>;
  deletedAt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inviter?: Maybe<ActivateAccountNestedPayload>;
  isConnectedGoogleCalendar: Scalars['Boolean'];
  isCopywriter: Scalars['Boolean'];
  isTwoFactorEnabled: Scalars['Boolean'];
  lastAuthenticatedAt?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  organizerCalendarEvents?: Maybe<CalendarEventPageConnection>;
  phoneNumber?: Maybe<Scalars['String']>;
  productAccessRequests?: Maybe<ProductAccessRequestPageConnection>;
  rejectionReason?: Maybe<Scalars['String']>;
  roles: Scalars['Iterable'];
  /** Can be 'active' or 'inactive'. */
  status: Scalars['String'];
  submittedForApprovalAt?: Maybe<Scalars['String']>;
  submittedForApprovalBy?: Maybe<ActivateAccountNestedPayload>;
  sustainabilityAdvisorProducts?: Maybe<AccountSustainabilityAdvisorProductCursorConnection>;
  timezone: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['String'];
};

/** Approves a Account. */
export type ApproveAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Account ID */
  id: Scalars['ID'];
};

/** Approves a Account. */
export type ApproveAccountPayload = {
  __typename?: 'approveAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Approves a ClientCompanyDocument. */
export type ApproveClientCompanyDocumentInput = {
  clientDocumentCategory?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Approves a ClientCompanyDocument. */
export type ApproveClientCompanyDocumentPayload = {
  __typename?: 'approveClientCompanyDocumentPayload';
  clientCompanyDocument?: Maybe<ClientCompanyDocument>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Approves a ClientCompany. */
export type ApproveClientCompanyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Client Company ID */
  id: Scalars['ID'];
};

/** Approves a ClientCompany. */
export type ApproveClientCompanyPayload = {
  __typename?: 'approveClientCompanyPayload';
  clientCompany?: Maybe<ClientCompany>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Approves a ProductAccessRequest. */
export type ApproveProductAccessRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Request IrI. */
  id: Scalars['ID'];
};

/** Approves a ProductAccessRequest. */
export type ApproveProductAccessRequestPayload = {
  __typename?: 'approveProductAccessRequestPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  productAccessRequest?: Maybe<ProductAccessRequest>;
};

/** ChangePasswordFors a Account. */
export type ChangePasswordForAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** New password. */
  newPassword: Scalars['String'];
  /** Current password. */
  oldPassword: Scalars['String'];
};

/** ChangePasswordFors a Account. */
export type ChangePasswordForAccountPayload = {
  __typename?: 'changePasswordForAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Confirm2factors a Authentication. */
export type Confirm2factorAuthenticationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 2 Factor Authentication Code. */
  code: Scalars['String'];
};

/** Confirm2factors a Authentication. */
export type Confirm2factorAuthenticationPayload = {
  __typename?: 'confirm2factorAuthenticationPayload';
  authentication?: Maybe<Authentication>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a Account. */
export type CreateAccountInput = {
  /** ClientCompanies and products that are related to an SA */
  appointedClientCompanies?: InputMaybe<Array<InputMaybe<SustainabilityAdvisorsProductInputType>>>;
  /** Biography for Sustainability Advisor account */
  bio?: InputMaybe<Scalars['String']>;
  /** Calendly ID */
  calendlyId?: InputMaybe<Scalars['String']>;
  /** Client company that the user works for.(for business support/sustainability advisor this value is null) */
  clientCompany?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Products for company representative, required companyId param */
  companyRepresentativeProducts?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Invitee Email Address. */
  email: Scalars['String'];
  /** Person first name. */
  firstName: Scalars['String'];
  /** Flag identifying that company representative is an API User. */
  isApiUser?: InputMaybe<Scalars['Boolean']>;
  /** Flag identifying that sustainability advisor is a Copywriter. */
  isCopywriter?: InputMaybe<Scalars['Boolean']>;
  /** Person last name. */
  lastName: Scalars['String'];
  /** Account locale */
  locale?: InputMaybe<Scalars['String']>;
  /** Person middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** Person phone number */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** Products the sustainability advisor is subscribes to (works only for sustainability advisor role). */
  sustainabilityAdvisorProducts?: InputMaybe<Array<InputMaybe<SustainabilityAdvisorProductInputType>>>;
  /** Account role in the platform.(business support, sustainability advisor, client) */
  type: Scalars['String'];
};

/** Creates a Account. */
export type CreateAccountPayload = {
  __typename?: 'createAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a ApiKey. */
export type CreateApiKeyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Creates a ApiKey. */
export type CreateApiKeyPayload = {
  __typename?: 'createApiKeyPayload';
  apiKey?: Maybe<ApiKey>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a BlogPost. */
export type CreateBlogPostInput = {
  /** BlogPost body (text). */
  body: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** BlogPost  cover image */
  cover?: InputMaybe<Scalars['Upload']>;
  /** BlogPost description. */
  description?: InputMaybe<Scalars['String']>;
  /** Related Product Iri. */
  product: Scalars['ID'];
  /** Flag indicate is post should published otherwise will be saved as draft. */
  publish?: InputMaybe<Scalars['Boolean']>;
  /** Client companies with whom the blog post was shared. */
  sharedWith?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** BlogPost title. */
  title: Scalars['String'];
};

/** Creates a BlogPost. */
export type CreateBlogPostPayload = {
  __typename?: 'createBlogPostPayload';
  blogPost?: Maybe<BlogPost>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** CreateBusinessSupports a Account. */
export type CreateBusinessSupportAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Invitee Email Address. */
  email: Scalars['String'];
  /** Person first name. */
  firstName: Scalars['String'];
  /** Person last name. */
  lastName: Scalars['String'];
  /** Account locale */
  locale?: InputMaybe<Scalars['String']>;
  /** Person middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** Person phone number */
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** CreateBusinessSupports a Account. */
export type CreateBusinessSupportAccountPayload = {
  __typename?: 'createBusinessSupportAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a ChatMessage. */
export type CreateChatMessageInput = {
  chatRoom: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
};

/** Creates a ChatMessage. */
export type CreateChatMessagePayload = {
  __typename?: 'createChatMessagePayload';
  chatMessage?: Maybe<ChatMessage>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a ClientCompany. */
export type CreateClientCompanyInput = {
  /** Client company addition address. */
  addition?: InputMaybe<Scalars['String']>;
  /** Street where client company head quarters are located. */
  address?: InputMaybe<Scalars['String']>;
  /** Chamber of commerce */
  chamberOfCommerce?: InputMaybe<Scalars['String']>;
  /** City where client company head quarters are located. */
  city?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Country where client company head quarters are located. */
  country?: InputMaybe<Scalars['String']>;
  /** Legal representative email address. */
  legalEmail?: InputMaybe<Scalars['String']>;
  /** Legal representative full name.. */
  legalFullName?: InputMaybe<Scalars['String']>;
  /** Client company name. */
  legalName: Scalars['String'];
  /** LEI */
  lei?: InputMaybe<Scalars['String']>;
  /** Postal code of client company head quarters. */
  postalCode?: InputMaybe<Scalars['String']>;
  /** Products the client company subscribes to. */
  products?: InputMaybe<Array<InputMaybe<ClientCompanyProductInputType>>>;
  /** SubEntity ids related to client company. */
  subEntityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** VAT number. */
  vat?: InputMaybe<Scalars['String']>;
  /** Zoho account id of the primary subentity. */
  zohoAccount: Scalars['String'];
};

/** Creates a ClientCompany. */
export type CreateClientCompanyPayload = {
  __typename?: 'createClientCompanyPayload';
  clientCompany?: Maybe<ClientCompany>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a ClientDocumentCategory. */
export type CreateClientDocumentCategoryInput = {
  clientCompany: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Creates a ClientDocumentCategory. */
export type CreateClientDocumentCategoryPayload = {
  __typename?: 'createClientDocumentCategoryPayload';
  clientDocumentCategory?: Maybe<ClientDocumentCategory>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a CommodityVolumesYear. */
export type CreateCommodityVolumesYearInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<Scalars['String']>;
  months?: InputMaybe<Array<CommodityVolumesMonthNestedInput>>;
  product?: InputMaybe<Scalars['String']>;
  savingLimit: Scalars['Int'];
  savingLimitValidity?: InputMaybe<Scalars['String']>;
  totalExpected?: InputMaybe<Scalars['Int']>;
  year: Scalars['Int'];
};

/** Creates a CommodityVolumesYear. */
export type CreateCommodityVolumesYearPayload = {
  __typename?: 'createCommodityVolumesYearPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  commodityVolumesYear?: Maybe<CommodityVolumesYear>;
};

/** CreateCompanyRepresentatives a Account. */
export type CreateCompanyRepresentativeAccountInput = {
  /** Client company that the user works for.(for business support/sustainability advisor this value is null) */
  clientCompany?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Products for company representative, required companyId param */
  companyRepresentativeProducts?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Invitee Email Address. */
  email: Scalars['String'];
  /** Person first name. */
  firstName: Scalars['String'];
  /** Flag identifying that company representative is an API User. */
  isApiUser?: InputMaybe<Scalars['Boolean']>;
  /** Person last name. */
  lastName: Scalars['String'];
  /** Account locale */
  locale?: InputMaybe<Scalars['String']>;
  /** Person middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** Person phone number */
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** CreateCompanyRepresentatives a Account. */
export type CreateCompanyRepresentativeAccountPayload = {
  __typename?: 'createCompanyRepresentativeAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a InAppEvent. */
export type CreateInAppEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDateTime?: InputMaybe<Scalars['Int']>;
  product: Scalars['String'];
  sharedWith?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startDateTime: Scalars['Int'];
  title: Scalars['String'];
};

/** Creates a InAppEvent. */
export type CreateInAppEventPayload = {
  __typename?: 'createInAppEventPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  inAppEvent?: Maybe<InAppEvent>;
};

/** Creates a Newsletter. */
export type CreateNewsletterInput = {
  /** Newsletter body (text). */
  body: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Newsletter  cover image */
  cover?: InputMaybe<Scalars['Upload']>;
  /** Newsletter description. */
  description?: InputMaybe<Scalars['String']>;
  /** Related Product Iri. */
  products?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Flag indicate is post should published otherwise will be saved as draft. */
  publish?: InputMaybe<Scalars['Boolean']>;
  /** Client companies with whom the newsletter was shared. */
  sharedWith?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Newsletter title. */
  title: Scalars['String'];
};

/** Creates a Newsletter. */
export type CreateNewsletterPayload = {
  __typename?: 'createNewsletterPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  newsletter?: Maybe<Newsletter>;
};

/** Creates a Offer. */
export type CreateOfferInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  indicationOfInterest: Scalars['String'];
  price: Scalars['Float'];
};

/** Creates a Offer. */
export type CreateOfferPayload = {
  __typename?: 'createOfferPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  offer?: Maybe<Offer>;
};

/** Creates a Order. */
export type CreateOrderInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Price ID */
  priceId: Scalars['String'];
  /** Payment Term */
  term: Scalars['Int'];
  /** Should be buy or sell */
  type: Scalars['String'];
  /** Volume */
  volume: Scalars['Int'];
};

/** Creates a Order. */
export type CreateOrderPayload = {
  __typename?: 'createOrderPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
};

/** Creates a PriceWidgetCalculation. */
export type CreatePriceWidgetCalculationInput = {
  clientCompany: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  requiredEUAs: Scalars['Int'];
  totalBudget: Scalars['Float'];
};

/** Creates a PriceWidgetCalculation. */
export type CreatePriceWidgetCalculationPayload = {
  __typename?: 'createPriceWidgetCalculationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  priceWidgetCalculation?: Maybe<PriceWidgetCalculation>;
};

/** Creates a ProductAccessRequest. */
export type CreateProductAccessRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Request IrI. */
  product: Scalars['ID'];
};

/** Creates a ProductAccessRequest. */
export type CreateProductAccessRequestPayload = {
  __typename?: 'createProductAccessRequestPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  productAccessRequest?: Maybe<ProductAccessRequest>;
};

/** Creates a ProductDocumentCategory. */
export type CreateProductDocumentCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  product: Scalars['String'];
};

/** Creates a ProductDocumentCategory. */
export type CreateProductDocumentCategoryPayload = {
  __typename?: 'createProductDocumentCategoryPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  productDocumentCategory?: Maybe<ProductDocumentCategory>;
};

/** Creates a Rfq. */
export type CreateRfqInput = {
  /** Multi-select list of certification. */
  certifications?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Client company id. */
  clientCompany?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** List of commodity ids linked to the Refigerant gas product. */
  commodities?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Country of origin. */
  countries?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Currency. */
  currency?: InputMaybe<Scalars['String']>;
  /** List of incoterm ids linked to the Refrigerant gas product. */
  incoterms?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Buy or Sell mode. */
  isBuy?: InputMaybe<Scalars['Boolean']>;
  /** Consumption or production. */
  isConsumption?: InputMaybe<Scalars['Boolean']>;
  /** Metric. */
  metric?: InputMaybe<Scalars['String']>;
  /** Note. */
  note?: InputMaybe<Scalars['String']>;
  /** Consumption time periods. */
  periods?: InputMaybe<Array<InputMaybe<PeriodInputType>>>;
  /** Specific phase. */
  phases?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Price. */
  price?: InputMaybe<Scalars['Float']>;
  /** Product id. */
  product: Scalars['ID'];
  /** Quarter of year. */
  quarterOfDelivery?: InputMaybe<Scalars['String']>;
  /** Region of origin. */
  regions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** List of technologies ids linked to selected commodity. */
  technologies?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Type of packaging. */
  typeOfPackaging?: InputMaybe<Scalars['String']>;
  /** Volume. */
  volume?: InputMaybe<Scalars['Float']>;
  /** Year of delivery. */
  yearOfDelivery?: InputMaybe<Scalars['String']>;
};

/** Creates a Rfq. */
export type CreateRfqPayload = {
  __typename?: 'createRfqPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  rfq?: Maybe<Rfq>;
};

/** Creates a SubEntity. */
export type CreateSubEntityInput = {
  /** SubEntity addition address. */
  addition?: InputMaybe<Scalars['String']>;
  /** SubEntity address. */
  address?: InputMaybe<Scalars['String']>;
  /** Chamber of commerce */
  chamberOfCommerce?: InputMaybe<Scalars['String']>;
  /** SubEntity city. */
  city?: InputMaybe<Scalars['String']>;
  /** Client Company Id connected to SubEntity */
  clientCompany: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** SubEntity country. */
  country?: InputMaybe<Scalars['String']>;
  /** SubEntity legal email address. */
  legalEmail?: InputMaybe<Scalars['String']>;
  /** SubEntity legal full name. */
  legalFullName?: InputMaybe<Scalars['String']>;
  /** SubEntity legal name. */
  legalName: Scalars['String'];
  /** LEI */
  lei?: InputMaybe<Scalars['String']>;
  /** SubEntity postal code. */
  postalCode?: InputMaybe<Scalars['String']>;
  /** Set SubEntity as Primary SubEntity */
  primary?: InputMaybe<Scalars['Boolean']>;
  /** VAT number. */
  vat?: InputMaybe<Scalars['String']>;
  /** Zoho Account Id connected to SubEntity */
  zohoAccount?: InputMaybe<Scalars['String']>;
};

/** Creates a SubEntity. */
export type CreateSubEntityPayload = {
  __typename?: 'createSubEntityPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  subEntity?: Maybe<SubEntity>;
};

/** CreateSustainabilityAdvisors a Account. */
export type CreateSustainabilityAdvisorAccountInput = {
  /** ClientCompanies and products that are related to an SA */
  appointedClientCompanies?: InputMaybe<Array<InputMaybe<SustainabilityAdvisorsProductInputType>>>;
  /** Biography for Sustainability Advisor account */
  bio?: InputMaybe<Scalars['String']>;
  /** Calendly ID */
  calendlyId?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Invitee Email Address. */
  email: Scalars['String'];
  /** Person first name. */
  firstName: Scalars['String'];
  /** Flag identifying that sustainability advisor is a Copywriter. */
  isCopywriter?: InputMaybe<Scalars['Boolean']>;
  /** Person last name. */
  lastName: Scalars['String'];
  /** Account locale */
  locale?: InputMaybe<Scalars['String']>;
  /** Person middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** Person phone number */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** Products the sustainability advisor is subscribes to (works only for sustainability advisor role). */
  sustainabilityAdvisorProducts?: InputMaybe<Array<InputMaybe<SustainabilityAdvisorProductInputType>>>;
};

/** CreateSustainabilityAdvisors a Account. */
export type CreateSustainabilityAdvisorAccountPayload = {
  __typename?: 'createSustainabilityAdvisorAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a Webinar. */
export type CreateWebinarInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Webinar cover image. */
  cover?: InputMaybe<Scalars['Upload']>;
  /** Webinar description. */
  description?: InputMaybe<Scalars['String']>;
  /** Youtube link to webinar. */
  link: Scalars['String'];
  /** Webinar Product Iri. */
  product: Scalars['ID'];
  /** Client companies with whom the webinar was shared. */
  sharedWith?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Webinar title. */
  title: Scalars['String'];
};

/** Creates a Webinar. */
export type CreateWebinarPayload = {
  __typename?: 'createWebinarPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  webinar?: Maybe<Webinar>;
};

/** Deactivates a Account. */
export type DeactivateAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Account ID */
  id: Scalars['ID'];
};

/** Deactivates a Account. */
export type DeactivateAccountNestedPayload = Node & {
  __typename?: 'deactivateAccountNestedPayload';
  _id?: Maybe<Scalars['String']>;
  activatedAt?: Maybe<Scalars['String']>;
  appointedSustainabilityAdvisorProducts?: Maybe<ClientCompanyProductCursorConnection>;
  approvedAt?: Maybe<Scalars['String']>;
  attendeeCalendarEvents?: Maybe<CalendarEventPageConnection>;
  avatar?: Maybe<Avatar>;
  bio?: Maybe<Scalars['String']>;
  calendlyId?: Maybe<Scalars['String']>;
  clientCompany?: Maybe<DeactivateClientCompanyNestedPayload>;
  companyRepresentativeProducts?: Maybe<ProductCursorConnection>;
  createdAt: Scalars['String'];
  createdInAppEvents?: Maybe<InAppEventPageConnection>;
  deletedAt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inviter?: Maybe<DeactivateAccountNestedPayload>;
  isConnectedGoogleCalendar: Scalars['Boolean'];
  isCopywriter: Scalars['Boolean'];
  isTwoFactorEnabled: Scalars['Boolean'];
  lastAuthenticatedAt?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  organizerCalendarEvents?: Maybe<CalendarEventPageConnection>;
  phoneNumber?: Maybe<Scalars['String']>;
  productAccessRequests?: Maybe<ProductAccessRequestPageConnection>;
  rejectionReason?: Maybe<Scalars['String']>;
  roles: Scalars['Iterable'];
  /** Can be 'active' or 'inactive'. */
  status: Scalars['String'];
  submittedForApprovalAt?: Maybe<Scalars['String']>;
  submittedForApprovalBy?: Maybe<DeactivateAccountNestedPayload>;
  sustainabilityAdvisorProducts?: Maybe<AccountSustainabilityAdvisorProductCursorConnection>;
  timezone: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['String'];
};

/** Page connection for deactivateAccountNestedPayload. */
export type DeactivateAccountNestedPayloadPageConnection = {
  __typename?: 'deactivateAccountNestedPayloadPageConnection';
  collection?: Maybe<Array<Maybe<DeactivateAccountNestedPayload>>>;
  paginationInfo: DeactivateAccountNestedPayloadPaginationInfo;
};

/** Information about the pagination. */
export type DeactivateAccountNestedPayloadPaginationInfo = {
  __typename?: 'deactivateAccountNestedPayloadPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

/** Deactivates a Account. */
export type DeactivateAccountPayload = {
  __typename?: 'deactivateAccountPayload';
  account?: Maybe<DeactivateAccountPayloadData>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Deactivates a Account. */
export type DeactivateAccountPayloadData = Node & {
  __typename?: 'deactivateAccountPayloadData';
  _id?: Maybe<Scalars['String']>;
  activatedAt?: Maybe<Scalars['String']>;
  appointedSustainabilityAdvisorProducts?: Maybe<ClientCompanyProductCursorConnection>;
  approvedAt?: Maybe<Scalars['String']>;
  attendeeCalendarEvents?: Maybe<CalendarEventPageConnection>;
  avatar?: Maybe<Avatar>;
  bio?: Maybe<Scalars['String']>;
  calendlyId?: Maybe<Scalars['String']>;
  clientCompany?: Maybe<DeactivateClientCompanyNestedPayload>;
  companyRepresentativeProducts?: Maybe<ProductCursorConnection>;
  createdAt: Scalars['String'];
  createdInAppEvents?: Maybe<InAppEventPageConnection>;
  deletedAt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inviter?: Maybe<DeactivateAccountNestedPayload>;
  isConnectedGoogleCalendar: Scalars['Boolean'];
  isCopywriter: Scalars['Boolean'];
  isTwoFactorEnabled: Scalars['Boolean'];
  lastAuthenticatedAt?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  organizerCalendarEvents?: Maybe<CalendarEventPageConnection>;
  phoneNumber?: Maybe<Scalars['String']>;
  productAccessRequests?: Maybe<ProductAccessRequestPageConnection>;
  rejectionReason?: Maybe<Scalars['String']>;
  roles: Scalars['Iterable'];
  /** Can be 'active' or 'inactive'. */
  status: Scalars['String'];
  submittedForApprovalAt?: Maybe<Scalars['String']>;
  submittedForApprovalBy?: Maybe<DeactivateAccountNestedPayload>;
  sustainabilityAdvisorProducts?: Maybe<AccountSustainabilityAdvisorProductCursorConnection>;
  timezone: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['String'];
};

/** Deactivates a ClientCompany. */
export type DeactivateClientCompanyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Client Company ID */
  id: Scalars['ID'];
};

/** Deactivates a ClientCompany. */
export type DeactivateClientCompanyNestedPayload = Node & {
  __typename?: 'deactivateClientCompanyNestedPayload';
  companyRepresentatives?: Maybe<DeactivateAccountNestedPayloadPageConnection>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<DeactivateAccountNestedPayload>;
  id: Scalars['ID'];
  isProductManagerRelated: Scalars['Boolean'];
  kycRequested: Scalars['Boolean'];
  kycRequestedAt?: Maybe<Scalars['String']>;
  /** Legal name of primary sub entity. */
  legalName: Scalars['String'];
  primarySubEntity: SubEntity;
  productDocuments?: Maybe<ProductDocumentPageConnection>;
  products?: Maybe<ClientCompanyProductCursorConnection>;
  rejectionReason?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  subEntities?: Maybe<SubEntityCursorConnection>;
  updatedAt: Scalars['String'];
};

/** Deactivates a ClientCompany. */
export type DeactivateClientCompanyPayload = {
  __typename?: 'deactivateClientCompanyPayload';
  clientCompany?: Maybe<ClientCompany>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Deletes a Account. */
export type DeleteAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a Account. */
export type DeleteAccountPayload = {
  __typename?: 'deleteAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Deletes a BlogPost. */
export type DeleteBlogPostInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** BlogPost IrI */
  id: Scalars['ID'];
};

/** Deletes a BlogPost. */
export type DeleteBlogPostPayload = {
  __typename?: 'deleteBlogPostPayload';
  blogPost?: Maybe<BlogPost>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Deletes a ClientCompanyDocument. */
export type DeleteClientCompanyDocumentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a ClientCompanyDocument. */
export type DeleteClientCompanyDocumentPayload = {
  __typename?: 'deleteClientCompanyDocumentPayload';
  clientCompanyDocument?: Maybe<ClientCompanyDocument>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Deletes a ClientDocumentCategory. */
export type DeleteClientDocumentCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a ClientDocumentCategory. */
export type DeleteClientDocumentCategoryPayload = {
  __typename?: 'deleteClientDocumentCategoryPayload';
  clientDocumentCategory?: Maybe<ClientDocumentCategory>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Deletes a InAppEvent. */
export type DeleteInAppEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a InAppEvent. */
export type DeleteInAppEventPayload = {
  __typename?: 'deleteInAppEventPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  inAppEvent?: Maybe<InAppEvent>;
};

/** Deletes a Newsletter. */
export type DeleteNewsletterInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Newsletter IrI */
  id: Scalars['ID'];
};

/** Deletes a Newsletter. */
export type DeleteNewsletterPayload = {
  __typename?: 'deleteNewsletterPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  newsletter?: Maybe<Newsletter>;
};

/** Deletes a ProductDocumentCategory. */
export type DeleteProductDocumentCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a ProductDocumentCategory. */
export type DeleteProductDocumentCategoryPayload = {
  __typename?: 'deleteProductDocumentCategoryPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  productDocumentCategory?: Maybe<ProductDocumentCategory>;
};

/** Deletes a ProductDocument. */
export type DeleteProductDocumentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a ProductDocument. */
export type DeleteProductDocumentPayload = {
  __typename?: 'deleteProductDocumentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  productDocument?: Maybe<ProductDocument>;
};

/** Deletes a SubEntity. */
export type DeleteSubEntityInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a SubEntity. */
export type DeleteSubEntityPayload = {
  __typename?: 'deleteSubEntityPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  subEntity?: Maybe<SubEntity>;
};

/** Deletes a Webinar. */
export type DeleteWebinarInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Webinar IrI */
  id: Scalars['ID'];
};

/** Deletes a Webinar. */
export type DeleteWebinarPayload = {
  __typename?: 'deleteWebinarPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  webinar?: Maybe<Webinar>;
};

/** Enable2factors a Authentication. */
export type Enable2factorAuthenticationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Enable2factors a Authentication. */
export type Enable2factorAuthenticationPayload = {
  __typename?: 'enable2factorAuthenticationPayload';
  authentication?: Maybe<Authentication>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Invites a Account. */
export type InviteAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Account ID */
  id: Scalars['ID'];
};

/** Invites a Account. */
export type InviteAccountPayload = {
  __typename?: 'inviteAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Joins a ChatRoom. */
export type JoinChatRoomInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Iri of the ChatRoom the user is joining. */
  id: Scalars['ID'];
};

/** Joins a ChatRoom. */
export type JoinChatRoomPayload = {
  __typename?: 'joinChatRoomPayload';
  chatRoom?: Maybe<ChatRoom>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Leaves a ChatRoom. */
export type LeaveChatRoomInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Iri of the ChatRoom the user is leaving. */
  id: Scalars['ID'];
};

/** Leaves a ChatRoom. */
export type LeaveChatRoomPayload = {
  __typename?: 'leaveChatRoomPayload';
  chatRoom?: Maybe<ChatRoom>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** MarkAsReads a Notification. */
export type MarkAsReadNotificationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Notification IrI. */
  id: Scalars['ID'];
};

/** MarkAsReads a Notification. */
export type MarkAsReadNotificationPayload = {
  __typename?: 'markAsReadNotificationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  notification?: Maybe<Notification>;
};

/** PasswordResetFors a Account. */
export type PasswordResetForAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** New password. */
  password: Scalars['String'];
  /** Reset Password token sent by email. */
  token: Scalars['String'];
};

/** PasswordResetFors a Account. */
export type PasswordResetForAccountPayload = {
  __typename?: 'passwordResetForAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Publishs a ProductDocument. */
export type PublishProductDocumentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  productDocumentCategory?: InputMaybe<Scalars['String']>;
  /** Client Companies with access to the product document. */
  sharedWith?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title: Scalars['String'];
};

/** Publishs a ProductDocument. */
export type PublishProductDocumentPayload = {
  __typename?: 'publishProductDocumentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  productDocument?: Maybe<ProductDocument>;
};

/** Publishs a Webinar. */
export type PublishWebinarInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Webinar IrI */
  id: Scalars['ID'];
  /** Client companies with whom the webinar was shared. */
  sharedWith?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

/** Publishs a Webinar. */
export type PublishWebinarPayload = {
  __typename?: 'publishWebinarPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  webinar?: Maybe<Webinar>;
};

/** Reactivates a Account. */
export type ReactivateAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Account ID */
  id: Scalars['ID'];
};

/** Reactivates a Account. */
export type ReactivateAccountNestedPayload = Node & {
  __typename?: 'reactivateAccountNestedPayload';
  _id?: Maybe<Scalars['String']>;
  activatedAt?: Maybe<Scalars['String']>;
  appointedSustainabilityAdvisorProducts?: Maybe<ClientCompanyProductCursorConnection>;
  approvedAt?: Maybe<Scalars['String']>;
  attendeeCalendarEvents?: Maybe<CalendarEventPageConnection>;
  avatar?: Maybe<Avatar>;
  bio?: Maybe<Scalars['String']>;
  calendlyId?: Maybe<Scalars['String']>;
  clientCompany?: Maybe<ReactivateClientCompanyNestedPayload>;
  companyRepresentativeProducts?: Maybe<ProductCursorConnection>;
  createdAt: Scalars['String'];
  createdInAppEvents?: Maybe<InAppEventPageConnection>;
  deletedAt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inviter?: Maybe<ReactivateAccountNestedPayload>;
  isConnectedGoogleCalendar: Scalars['Boolean'];
  isCopywriter: Scalars['Boolean'];
  isTwoFactorEnabled: Scalars['Boolean'];
  lastAuthenticatedAt?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  organizerCalendarEvents?: Maybe<CalendarEventPageConnection>;
  phoneNumber?: Maybe<Scalars['String']>;
  productAccessRequests?: Maybe<ProductAccessRequestPageConnection>;
  rejectionReason?: Maybe<Scalars['String']>;
  roles: Scalars['Iterable'];
  /** Can be 'active' or 'inactive'. */
  status: Scalars['String'];
  submittedForApprovalAt?: Maybe<Scalars['String']>;
  submittedForApprovalBy?: Maybe<ReactivateAccountNestedPayload>;
  sustainabilityAdvisorProducts?: Maybe<AccountSustainabilityAdvisorProductCursorConnection>;
  timezone: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['String'];
};

/** Page connection for reactivateAccountNestedPayload. */
export type ReactivateAccountNestedPayloadPageConnection = {
  __typename?: 'reactivateAccountNestedPayloadPageConnection';
  collection?: Maybe<Array<Maybe<ReactivateAccountNestedPayload>>>;
  paginationInfo: ReactivateAccountNestedPayloadPaginationInfo;
};

/** Information about the pagination. */
export type ReactivateAccountNestedPayloadPaginationInfo = {
  __typename?: 'reactivateAccountNestedPayloadPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

/** Reactivates a Account. */
export type ReactivateAccountPayload = {
  __typename?: 'reactivateAccountPayload';
  account?: Maybe<ReactivateAccountPayloadData>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Reactivates a Account. */
export type ReactivateAccountPayloadData = Node & {
  __typename?: 'reactivateAccountPayloadData';
  _id?: Maybe<Scalars['String']>;
  activatedAt?: Maybe<Scalars['String']>;
  appointedSustainabilityAdvisorProducts?: Maybe<ClientCompanyProductCursorConnection>;
  approvedAt?: Maybe<Scalars['String']>;
  attendeeCalendarEvents?: Maybe<CalendarEventPageConnection>;
  avatar?: Maybe<Avatar>;
  bio?: Maybe<Scalars['String']>;
  calendlyId?: Maybe<Scalars['String']>;
  clientCompany?: Maybe<ReactivateClientCompanyNestedPayload>;
  companyRepresentativeProducts?: Maybe<ProductCursorConnection>;
  createdAt: Scalars['String'];
  createdInAppEvents?: Maybe<InAppEventPageConnection>;
  deletedAt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inviter?: Maybe<ReactivateAccountNestedPayload>;
  isConnectedGoogleCalendar: Scalars['Boolean'];
  isCopywriter: Scalars['Boolean'];
  isTwoFactorEnabled: Scalars['Boolean'];
  lastAuthenticatedAt?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  organizerCalendarEvents?: Maybe<CalendarEventPageConnection>;
  phoneNumber?: Maybe<Scalars['String']>;
  productAccessRequests?: Maybe<ProductAccessRequestPageConnection>;
  rejectionReason?: Maybe<Scalars['String']>;
  roles: Scalars['Iterable'];
  /** Can be 'active' or 'inactive'. */
  status: Scalars['String'];
  submittedForApprovalAt?: Maybe<Scalars['String']>;
  submittedForApprovalBy?: Maybe<ReactivateAccountNestedPayload>;
  sustainabilityAdvisorProducts?: Maybe<AccountSustainabilityAdvisorProductCursorConnection>;
  timezone: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['String'];
};

/** Reactivates a ClientCompany. */
export type ReactivateClientCompanyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Client Company ID */
  id: Scalars['ID'];
};

/** Reactivates a ClientCompany. */
export type ReactivateClientCompanyNestedPayload = Node & {
  __typename?: 'reactivateClientCompanyNestedPayload';
  companyRepresentatives?: Maybe<ReactivateAccountNestedPayloadPageConnection>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<ReactivateAccountNestedPayload>;
  id: Scalars['ID'];
  isProductManagerRelated: Scalars['Boolean'];
  kycRequested: Scalars['Boolean'];
  kycRequestedAt?: Maybe<Scalars['String']>;
  /** Legal name of primary sub entity. */
  legalName: Scalars['String'];
  primarySubEntity: SubEntity;
  productDocuments?: Maybe<ProductDocumentPageConnection>;
  products?: Maybe<ClientCompanyProductCursorConnection>;
  rejectionReason?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  subEntities?: Maybe<SubEntityCursorConnection>;
  updatedAt: Scalars['String'];
};

/** Reactivates a ClientCompany. */
export type ReactivateClientCompanyPayload = {
  __typename?: 'reactivateClientCompanyPayload';
  clientCompany?: Maybe<ClientCompany>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Rejects a Account. */
export type RejectAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Account ID. */
  id: Scalars['ID'];
  /** Reason for the Account to be rejected. */
  rejectionReason: Scalars['String'];
};

/** Rejects a Account. */
export type RejectAccountPayload = {
  __typename?: 'rejectAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Rejects a BlogPost. */
export type RejectBlogPostInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** BlogPost IrI */
  id: Scalars['ID'];
  /** Reason for the BlogPost to be rejected. */
  rejectionReason: Scalars['String'];
};

/** Rejects a BlogPost. */
export type RejectBlogPostPayload = {
  __typename?: 'rejectBlogPostPayload';
  blogPost?: Maybe<BlogPost>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Rejects a ClientCompanyDocument. */
export type RejectClientCompanyDocumentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Id of the Client Company Document to be rejected. */
  id: Scalars['ID'];
  /** Reason for the Document to be rejected. */
  rejectionReason: Scalars['String'];
};

/** Rejects a ClientCompanyDocument. */
export type RejectClientCompanyDocumentPayload = {
  __typename?: 'rejectClientCompanyDocumentPayload';
  clientCompanyDocument?: Maybe<ClientCompanyDocument>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Rejects a ClientCompany. */
export type RejectClientCompanyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Client Company ID */
  id: Scalars['ID'];
  /** Reason for the Client Company to be rejected. */
  rejectionReason: Scalars['String'];
};

/** Rejects a ClientCompany. */
export type RejectClientCompanyPayload = {
  __typename?: 'rejectClientCompanyPayload';
  clientCompany?: Maybe<ClientCompany>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Rejects a ProductAccessRequest. */
export type RejectProductAccessRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Request IrI. */
  id: Scalars['ID'];
  /** Reason for the Product access request to be rejected. */
  rejectionReason: Scalars['String'];
};

/** Rejects a ProductAccessRequest. */
export type RejectProductAccessRequestPayload = {
  __typename?: 'rejectProductAccessRequestPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  productAccessRequest?: Maybe<ProductAccessRequest>;
};

/** Rejects a ProductDocument. */
export type RejectProductDocumentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Id of the Document to be rejected. */
  id: Scalars['ID'];
  /** Reason for the Document to be rejected. */
  rejectionReason: Scalars['String'];
};

/** Rejects a ProductDocument. */
export type RejectProductDocumentPayload = {
  __typename?: 'rejectProductDocumentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  productDocument?: Maybe<ProductDocument>;
};

/** Rejects a Webinar. */
export type RejectWebinarInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Webinar IrI */
  id: Scalars['ID'];
  /** Reason for the webinar to be rejected. */
  rejectionReason: Scalars['String'];
};

/** Rejects a Webinar. */
export type RejectWebinarPayload = {
  __typename?: 'rejectWebinarPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  webinar?: Maybe<Webinar>;
};

/** RequestPasswordResetFors a Account. */
export type RequestPasswordResetForAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** User email. */
  email: Scalars['String'];
};

/** RequestPasswordResetFors a Account. */
export type RequestPasswordResetForAccountPayload = {
  __typename?: 'requestPasswordResetForAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Requests a Product. */
export type RequestProductInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Product IrI. */
  id: Scalars['ID'];
};

/** Requests a Product. */
export type RequestProductPayload = {
  __typename?: 'requestProductPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
};

/** ResetMFAFors a Account. */
export type ResetMfaForAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Account ID */
  id: Scalars['ID'];
};

/** ResetMFAFors a Account. */
export type ResetMfaForAccountPayload = {
  __typename?: 'resetMFAForAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Restores a ClientCompanyDocument. */
export type RestoreClientCompanyDocumentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Id of the document to be restored. */
  id: Scalars['ID'];
};

/** Restores a ClientCompanyDocument. */
export type RestoreClientCompanyDocumentPayload = {
  __typename?: 'restoreClientCompanyDocumentPayload';
  clientCompanyDocument?: Maybe<ClientCompanyDocument>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Restores a ProductDocument. */
export type RestoreProductDocumentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Id of the document to be restored. */
  id: Scalars['ID'];
};

/** Restores a ProductDocument. */
export type RestoreProductDocumentPayload = {
  __typename?: 'restoreProductDocumentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  productDocument?: Maybe<ProductDocument>;
};

/** Starts a Authentication. */
export type StartAuthenticationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** User email. */
  email: Scalars['String'];
  /** User Password. */
  password: Scalars['String'];
};

/** Starts a Authentication. */
export type StartAuthenticationPayload = {
  __typename?: 'startAuthenticationPayload';
  authentication?: Maybe<Authentication>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** SubmitForApprovals a Account. */
export type SubmitForApprovalAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** SubmitForApprovals a Account. */
export type SubmitForApprovalAccountPayload = {
  __typename?: 'submitForApprovalAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** SubmitForApprovals a ClientCompany. */
export type SubmitForApprovalClientCompanyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Client company ID */
  id: Scalars['ID'];
};

/** SubmitForApprovals a ClientCompany. */
export type SubmitForApprovalClientCompanyPayload = {
  __typename?: 'submitForApprovalClientCompanyPayload';
  clientCompany?: Maybe<ClientCompany>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Unsets a Avatar. */
export type UnsetAvatarInput = {
  /** The Id of the account to delete the Avatar */
  accountId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Unsets a Avatar. */
export type UnsetAvatarPayload = {
  __typename?: 'unsetAvatarPayload';
  avatar?: Maybe<Avatar>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Updates a Account. */
export type UpdateAccountInput = {
  /** ClientCompanies and products that are related to an SA */
  appointedClientCompanies?: InputMaybe<Array<InputMaybe<SustainabilityAdvisorsProductInputType>>>;
  /** Biography for Sustainability Advisor account */
  bio?: InputMaybe<Scalars['String']>;
  /** Calendly ID */
  calendlyId?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Products for company representative */
  companyRepresentativeProducts?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Account email. */
  email?: InputMaybe<Scalars['String']>;
  /** Person first name. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Account IrI. */
  id: Scalars['ID'];
  /** Flag identifying that sustainability advisor is a Copywriter. */
  isCopywriter?: InputMaybe<Scalars['Boolean']>;
  /** Person last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Account locale. */
  locale?: InputMaybe<Scalars['String']>;
  /** Person middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** New phone number */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** Products the sustainability advisor is subscribes to (works only for sustainability advisor role). */
  sustainabilityAdvisorProducts?: InputMaybe<Array<InputMaybe<SustainabilityAdvisorProductInputType>>>;
  /** Account timezone */
  timezone?: InputMaybe<Scalars['String']>;
};

/** Updates a AccountNotificationSetting. */
export type UpdateAccountNotificationSettingInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Email/push notification settings of account */
  settings?: InputMaybe<Array<InputMaybe<AccountNotificationSettingInputType>>>;
};

/** Updates a AccountNotificationSetting. */
export type UpdateAccountNotificationSettingPayload = {
  __typename?: 'updateAccountNotificationSettingPayload';
  accountNotificationSetting?: Maybe<AccountNotificationSetting>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Updates a Account. */
export type UpdateAccountPayload = {
  __typename?: 'updateAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Updates a Avatar. */
export type UpdateAvatarInput = {
  /** The Id of the account to add an Avatar */
  accountId?: InputMaybe<Scalars['ID']>;
  /** Account Avatar image file, max size 5MB */
  avatar: Scalars['Upload'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Updates a Avatar. */
export type UpdateAvatarPayload = {
  __typename?: 'updateAvatarPayload';
  avatar?: Maybe<Avatar>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Updates a BlogPost. */
export type UpdateBlogPostInput = {
  /** BlogPost body (text) */
  body?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** BlogPost  cover image */
  cover?: InputMaybe<Scalars['Upload']>;
  /** BlogPost description */
  description?: InputMaybe<Scalars['String']>;
  /** BlogPost IrI */
  id: Scalars['ID'];
  /** Flag indicate is post should published otherwise will be saved as draft */
  publish?: InputMaybe<Scalars['Boolean']>;
  /** Client companies with whom the blog post was shared. */
  sharedWith?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** BlogPost title */
  title?: InputMaybe<Scalars['String']>;
};

/** Updates a BlogPost. */
export type UpdateBlogPostPayload = {
  __typename?: 'updateBlogPostPayload';
  blogPost?: Maybe<BlogPost>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** UpdateBusinessSupports a Account. */
export type UpdateBusinessSupportAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Account email. */
  email?: InputMaybe<Scalars['String']>;
  /** Person first name. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Account IrI. */
  id: Scalars['ID'];
  /** Person last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Account locale. */
  locale?: InputMaybe<Scalars['String']>;
  /** Person middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** New phone number */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** Account timezone */
  timezone?: InputMaybe<Scalars['String']>;
};

/** UpdateBusinessSupports a Account. */
export type UpdateBusinessSupportAccountPayload = {
  __typename?: 'updateBusinessSupportAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Updates a ClientCompanyDocument. */
export type UpdateClientCompanyDocumentInput = {
  clientDocumentCategory?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Updates a ClientCompanyDocument. */
export type UpdateClientCompanyDocumentPayload = {
  __typename?: 'updateClientCompanyDocumentPayload';
  clientCompanyDocument?: Maybe<ClientCompanyDocument>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Updates a ClientCompany. */
export type UpdateClientCompanyInput = {
  /** Client company addition address. */
  addition?: InputMaybe<Scalars['String']>;
  /** Street where client company head quarters are located. */
  address?: InputMaybe<Scalars['String']>;
  /** Chamber of commerce */
  chamberOfCommerce?: InputMaybe<Scalars['String']>;
  /** City where client company head quarters are located. */
  city?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Country where client company head quarters are located. */
  country?: InputMaybe<Scalars['String']>;
  /** Client company IrI. */
  id: Scalars['ID'];
  /** Legal representative email address. */
  legalEmail?: InputMaybe<Scalars['String']>;
  /** Legal representative full name. */
  legalFullName?: InputMaybe<Scalars['String']>;
  /** Client company name. */
  legalName?: InputMaybe<Scalars['String']>;
  /** LEI */
  lei?: InputMaybe<Scalars['String']>;
  /** Postal code of client company head quarters. */
  postalCode?: InputMaybe<Scalars['String']>;
  /** Products the client company subscribes to. */
  products?: InputMaybe<Array<InputMaybe<ClientCompanyProductInputType>>>;
  /** SubEntity ids related to client company. */
  subEntityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** VAT number. */
  vat?: InputMaybe<Scalars['String']>;
  /** Zoho account id of the primary subentity. */
  zohoAccount?: InputMaybe<Scalars['String']>;
};

/** Updates a ClientCompany. */
export type UpdateClientCompanyPayload = {
  __typename?: 'updateClientCompanyPayload';
  clientCompany?: Maybe<ClientCompany>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Updates a ClientDocumentCategory. */
export type UpdateClientDocumentCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

/** Updates a ClientDocumentCategory. */
export type UpdateClientDocumentCategoryPayload = {
  __typename?: 'updateClientDocumentCategoryPayload';
  clientDocumentCategory?: Maybe<ClientDocumentCategory>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Updates a CommodityVolumesMonth. */
export type UpdateCommodityVolumesMonthInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Month IRI to update */
  id: Scalars['ID'];
  /** Notify the Client about the Volume statistics update */
  notify: Scalars['Boolean'];
  /** Volume produced per period */
  produced?: InputMaybe<Scalars['Int']>;
  /** Volume sold */
  sold?: InputMaybe<Scalars['Int']>;
  /** Year IRI to update values of */
  year: Scalars['ID'];
};

/** Updates a CommodityVolumesMonth. */
export type UpdateCommodityVolumesMonthNestedInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Month IRI to update */
  id: Scalars['ID'];
  /** Notify the Client about the Volume statistics update */
  notify: Scalars['Boolean'];
  /** Volume produced per period */
  produced?: InputMaybe<Scalars['Int']>;
  /** Volume sold */
  sold?: InputMaybe<Scalars['Int']>;
  /** Year IRI to update values of */
  year: Scalars['ID'];
};

/** Updates a CommodityVolumesMonth. */
export type UpdateCommodityVolumesMonthPayload = {
  __typename?: 'updateCommodityVolumesMonthPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  commodityVolumesMonth?: Maybe<CommodityVolumesMonth>;
};

/** Updates a CommodityVolumesYear. */
export type UpdateCommodityVolumesYearInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  months?: InputMaybe<Array<UpdateCommodityVolumesMonthNestedInput>>;
  product?: InputMaybe<Scalars['String']>;
  savingLimit?: InputMaybe<Scalars['Int']>;
  savingLimitValidity?: InputMaybe<Scalars['String']>;
  totalExpected?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** Updates a CommodityVolumesYear. */
export type UpdateCommodityVolumesYearPayload = {
  __typename?: 'updateCommodityVolumesYearPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  commodityVolumesYear?: Maybe<CommodityVolumesYear>;
};

/** UpdateCompanyRepresentatives a Account. */
export type UpdateCompanyRepresentativeAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Products for company representative */
  companyRepresentativeProducts?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Account email. */
  email?: InputMaybe<Scalars['String']>;
  /** Person first name. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Account IrI. */
  id: Scalars['ID'];
  /** Person last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Account locale. */
  locale?: InputMaybe<Scalars['String']>;
  /** Person middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** New phone number */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** Account timezone */
  timezone?: InputMaybe<Scalars['String']>;
};

/** UpdateCompanyRepresentatives a Account. */
export type UpdateCompanyRepresentativeAccountPayload = {
  __typename?: 'updateCompanyRepresentativeAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Updates a ExchangePlugStatus. */
export type UpdateExchangePlugStatusInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Can be either ON or OFF. */
  newStatus: Scalars['String'];
};

/** Updates a ExchangePlugStatus. */
export type UpdateExchangePlugStatusPayload = {
  __typename?: 'updateExchangePlugStatusPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  exchangePlugStatus?: Maybe<ExchangePlugStatus>;
};

/** Updates a InAppEvent. */
export type UpdateInAppEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDateTime?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  product?: InputMaybe<Scalars['String']>;
  sharedWith?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startDateTime?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Updates a InAppEvent. */
export type UpdateInAppEventPayload = {
  __typename?: 'updateInAppEventPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  inAppEvent?: Maybe<InAppEvent>;
};

/** Updates a Newsletter. */
export type UpdateNewsletterInput = {
  /** Newsletter body (text) */
  body?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Newsletter  cover image */
  cover?: InputMaybe<Scalars['Upload']>;
  /** Newsletter description */
  description?: InputMaybe<Scalars['String']>;
  /** Newsletter IrI */
  id: Scalars['ID'];
  /** Related Product Iri. */
  products?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Flag indicate is post should published otherwise will be saved as draft */
  publish?: InputMaybe<Scalars['Boolean']>;
  /** Client companies with whom the newsletter was shared. */
  sharedWith?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Newsletter title */
  title?: InputMaybe<Scalars['String']>;
};

/** Updates a Newsletter. */
export type UpdateNewsletterPayload = {
  __typename?: 'updateNewsletterPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  newsletter?: Maybe<Newsletter>;
};

/** UpdatePaymentStatuss a Order. */
export type UpdatePaymentStatusOrderInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Order ID */
  id: Scalars['ID'];
  /** true - paid, false - unpaid */
  isPaid: Scalars['Boolean'];
};

/** UpdatePaymentStatuss a Order. */
export type UpdatePaymentStatusOrderPayload = {
  __typename?: 'updatePaymentStatusOrderPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
};

/** Updates a ProductDocumentCategory. */
export type UpdateProductDocumentCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

/** Updates a ProductDocumentCategory. */
export type UpdateProductDocumentCategoryPayload = {
  __typename?: 'updateProductDocumentCategoryPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  productDocumentCategory?: Maybe<ProductDocumentCategory>;
};

/** Updates a ProductDocument. */
export type UpdateProductDocumentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  productDocumentCategory?: InputMaybe<Scalars['String']>;
  /** Client Companies with access to the product document. */
  sharedWith?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
};

/** Updates a ProductDocument. */
export type UpdateProductDocumentPayload = {
  __typename?: 'updateProductDocumentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  productDocument?: Maybe<ProductDocument>;
};

/** UpdateQuarters a CommodityVolumesMonth. */
export type UpdateQuarterCommodityVolumesMonthInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** End month of the period */
  endMonth: Scalars['Int'];
  /** Notify the Client about the Volume statistics update */
  notify: Scalars['Boolean'];
  /** Volume produced per period */
  produced: Scalars['Int'];
  /** Volume sold outside afs per period */
  sold: Scalars['Int'];
  /** First month of the period */
  startMonth: Scalars['Int'];
  /** Year IRI to update values of */
  yearId: Scalars['ID'];
};

/** UpdateQuarters a CommodityVolumesMonth. */
export type UpdateQuarterCommodityVolumesMonthPayload = {
  __typename?: 'updateQuarterCommodityVolumesMonthPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  commodityVolumesMonth?: Maybe<CommodityVolumesMonth>;
};

/** Updates a SubEntity. */
export type UpdateSubEntityInput = {
  /** SubEntity addition address. */
  addition?: InputMaybe<Scalars['String']>;
  /** SubEntity address. */
  address?: InputMaybe<Scalars['String']>;
  /** Chamber of commerce */
  chamberOfCommerce?: InputMaybe<Scalars['String']>;
  /** SubEntity city. */
  city?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** SubEntity country. */
  country?: InputMaybe<Scalars['String']>;
  /** SubEntity IrI. */
  id: Scalars['ID'];
  /** SubEntity legal email address. */
  legalEmail?: InputMaybe<Scalars['String']>;
  /** SubEntity legal full name. */
  legalFullName?: InputMaybe<Scalars['String']>;
  /** SubEntity legal name. */
  legalName?: InputMaybe<Scalars['String']>;
  /** LEI */
  lei?: InputMaybe<Scalars['String']>;
  /** SubEntity postal code. */
  postalCode?: InputMaybe<Scalars['String']>;
  /** Set SubEntity as Primary SubEntity */
  primary?: InputMaybe<Scalars['Boolean']>;
  /** VAT number. */
  vat?: InputMaybe<Scalars['String']>;
  /** Zoho Account Id connected to SubEntity */
  zohoAccount?: InputMaybe<Scalars['String']>;
};

/** Updates a SubEntity. */
export type UpdateSubEntityPayload = {
  __typename?: 'updateSubEntityPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  subEntity?: Maybe<SubEntity>;
};

/** UpdateSustainabilityAdvisors a Account. */
export type UpdateSustainabilityAdvisorAccountInput = {
  /** ClientCompanies and products that are related to an SA */
  appointedClientCompanies?: InputMaybe<Array<InputMaybe<SustainabilityAdvisorsProductInputType>>>;
  /** Biography for Sustainability Advisor account */
  bio?: InputMaybe<Scalars['String']>;
  /** Calendly ID */
  calendlyId?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Account email. */
  email?: InputMaybe<Scalars['String']>;
  /** Person first name. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Account IrI. */
  id: Scalars['ID'];
  /** Flag identifying that sustainability advisor is a Copywriter. */
  isCopywriter?: InputMaybe<Scalars['Boolean']>;
  /** Person last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Account locale. */
  locale?: InputMaybe<Scalars['String']>;
  /** Person middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** New phone number */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** Products the sustainability advisor is subscribes to (works only for sustainability advisor role). */
  sustainabilityAdvisorProducts?: InputMaybe<Array<InputMaybe<SustainabilityAdvisorProductInputType>>>;
  /** Account timezone */
  timezone?: InputMaybe<Scalars['String']>;
};

/** UpdateSustainabilityAdvisors a Account. */
export type UpdateSustainabilityAdvisorAccountPayload = {
  __typename?: 'updateSustainabilityAdvisorAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Updates a Webinar. */
export type UpdateWebinarInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Webinar cover image */
  cover?: InputMaybe<Scalars['Upload']>;
  /** Webinar description */
  description?: InputMaybe<Scalars['String']>;
  /** Webinar IrI */
  id: Scalars['ID'];
  /** Link to webinar. */
  link: Scalars['String'];
  /** Client companies with whom the webinar was shared. */
  sharedWith?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Webinar title */
  title?: InputMaybe<Scalars['String']>;
};

/** Updates a Webinar. */
export type UpdateWebinarPayload = {
  __typename?: 'updateWebinarPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  webinar?: Maybe<Webinar>;
};

/** Verify2factors a Authentication. */
export type Verify2factorAuthenticationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 2 Factor Authentication Code. */
  code: Scalars['String'];
};

/** Verify2factors a Authentication. */
export type Verify2factorAuthenticationPayload = {
  __typename?: 'verify2factorAuthenticationPayload';
  authentication?: Maybe<Authentication>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Verifys a ClientCompany. */
export type VerifyClientCompanyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Client Company ID */
  id: Scalars['ID'];
};

/** Verifys a ClientCompany. */
export type VerifyClientCompanyPayload = {
  __typename?: 'verifyClientCompanyPayload';
  clientCompany?: Maybe<ClientCompany>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOrderMutationVariables = Exact<{
  input: CreateOrderInput;
}>;


export type CreateOrderMutation = { __typename?: 'Mutation', createOrder?: { __typename?: 'createOrderPayload', order?: { __typename?: 'Order', createdAt: string, deliveryDate: string, id: string, paymentDate: string, priceId: string, price?: number | null, status: string, term: number, totalAmount?: number | null, type: string, updatedAt: string, volume: number, number: string, isPaid: boolean, createdBy: { __typename?: 'Account', clientCompany?: { __typename?: 'ClientCompany', id: string, legalName: string } | null } } | null } | null };

export type GetOrdersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  order?: InputMaybe<Array<InputMaybe<OrderFilter_Order>> | InputMaybe<OrderFilter_Order>>;
}>;


export type GetOrdersQuery = { __typename?: 'Query', orders?: { __typename?: 'OrderPageConnection', collection?: Array<{ __typename?: 'Order', createdAt: string, deliveryDate: string, id: string, paymentDate: string, priceId: string, price?: number | null, status: string, term: number, totalAmount?: number | null, type: string, updatedAt: string, volume: number, number: string, isPaid: boolean, createdBy: { __typename?: 'Account', clientCompany?: { __typename?: 'ClientCompany', id: string, legalName: string } | null } } | null> | null, paginationInfo: { __typename?: 'OrderPaginationInfo', itemsPerPage: number, lastPage: number, totalCount: number } } | null };

export type GetClientCompanyZohoStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetClientCompanyZohoStatusQuery = { __typename?: 'Query', clientCompany?: { __typename?: 'ClientCompany', primarySubEntity: { __typename?: 'SubEntity', zohoAccount?: { __typename?: 'ZohoAccount', id: string, kycStatus?: string | null, status?: string | null } | null } } | null };

export type GetExchangePlugStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetExchangePlugStatusQuery = { __typename?: 'Query', exchangePlugStatus?: { __typename?: 'ExchangePlugStatus', _id?: string | null, createdAt: string, id: string, status: string, setBy: { __typename?: 'Account', id: string } } | null };

export type GetExchangePlugStatusesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
}>;


export type GetExchangePlugStatusesQuery = { __typename?: 'Query', exchangePlugStatuses?: { __typename?: 'ExchangePlugStatusPageConnection', collection?: Array<{ __typename?: 'ExchangePlugStatus', _id?: string | null, createdAt: string, id: string, status: string, setBy: { __typename?: 'Account', id: string } } | null> | null, paginationInfo: { __typename?: 'ExchangePlugStatusPaginationInfo', itemsPerPage: number, lastPage: number, totalCount: number } } | null };

export type UpdateExchangePlugStatusMutationVariables = Exact<{
  input: UpdateExchangePlugStatusInput;
}>;


export type UpdateExchangePlugStatusMutation = { __typename?: 'Mutation', updateExchangePlugStatus?: { __typename?: 'updateExchangePlugStatusPayload', exchangePlugStatus?: { __typename?: 'ExchangePlugStatus', _id?: string | null, createdAt: string, id: string, status: string, setBy: { __typename?: 'Account', id: string } } | null } | null };

export type GetRepresentativeProductsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetRepresentativeProductsQuery = { __typename?: 'Query', account?: { __typename?: 'Account', id: string, companyRepresentativeProducts?: { __typename?: 'ProductCursorConnection', edges?: Array<{ __typename?: 'ProductEdge', node?: { __typename?: 'Product', id: string, name: string, abbreviation?: string | null } | null } | null> | null } | null } | null };

export type GetLandingPageProductsQueryVariables = Exact<{
  id: Scalars['ID'];
  productStatus?: InputMaybe<Scalars['String']>;
}>;


export type GetLandingPageProductsQuery = { __typename?: 'Query', account?: { __typename?: 'Account', id: string, email: string, status: string, roles: any, companyRepresentativeProducts?: { __typename?: 'ProductCursorConnection', edges?: Array<{ __typename?: 'ProductEdge', node?: { __typename?: 'Product', id: string, name: string, abbreviation?: string | null } | null } | null> | null } | null, productAccessRequests?: { __typename?: 'ProductAccessRequestPageConnection', collection?: Array<{ __typename?: 'ProductAccessRequest', id: string, status: string, product?: { __typename?: 'Product', id: string, abbreviation?: string | null, name: string } | null } | null> | null } | null } | null };

export type GetAllProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllProductsQuery = { __typename?: 'Query', products?: { __typename?: 'ProductCursorConnection', edges?: Array<{ __typename?: 'ProductEdge', node?: { __typename?: 'Product', id: string, name: string, abbreviation?: string | null } | null } | null> | null } | null };

export type UpdatePaymentStatusOrderMutationVariables = Exact<{
  input: UpdatePaymentStatusOrderInput;
}>;


export type UpdatePaymentStatusOrderMutation = { __typename?: 'Mutation', updatePaymentStatusOrder?: { __typename?: 'updatePaymentStatusOrderPayload', clientMutationId?: string | null, order?: { __typename?: 'Order', createdAt: string, deliveryDate: string, id: string, paymentDate: string, priceId: string, price?: number | null, status: string, term: number, totalAmount?: number | null, type: string, updatedAt: string, volume: number, number: string, isPaid: boolean, createdBy: { __typename?: 'Account', clientCompany?: { __typename?: 'ClientCompany', id: string, legalName: string } | null } } | null } | null };

export type GetProductCommoditiesQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetProductCommoditiesQuery = { __typename?: 'Query', commodities?: Array<{ __typename?: 'Commodity', id: string, name: string } | null> | null };

export type NotificationFieldsFragment = { __typename?: 'Notification', id: string, module: string, notificationType: string, occurred_at: string, payload: any, processedPayload?: any | null, accountNotifications?: { __typename?: 'AccountNotificationCursorConnection', totalCount: number, edges?: Array<{ __typename?: 'AccountNotificationEdge', node?: { __typename?: 'AccountNotification', id: string, read: boolean, account: { __typename?: 'Account', id: string, email: string } } | null } | null> | null, pageInfo: { __typename?: 'AccountNotificationPageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type CommodityVolumesYearFieldsFragment = { __typename?: 'CommodityVolumesYear', id: string, year: number, savingLimit: number, totalExpected?: number | null, months?: { __typename?: 'CommodityVolumesMonthPageConnection', collection?: Array<{ __typename?: 'CommodityVolumesMonth', id: string, totalProduced?: number | null, sold?: number | null, totalSold?: number | null, open?: number | null, toBeProducedPerMonth?: number | null, expected?: number | null, yearNumber?: number | null, month: number, produced?: number | null } | null> | null } | null };

export type CommodityVolumesMonthFieldsFragment = { __typename?: 'CommodityVolumesMonth', id: string, totalProduced?: number | null, sold?: number | null, totalSold?: number | null, open?: number | null, toBeProducedPerMonth?: number | null, expected?: number | null, yearNumber?: number | null, month: number, produced?: number | null };

export type AccountNameFieldsFragment = { __typename?: 'Account', firstName?: string | null, middleName?: string | null, lastName?: string | null };

export type IndicationOfInterestOfferFieldsFragment = { __typename?: 'Offer', answeredAt?: string | null, createdAt: string, id: string, isAccepted: boolean, price: number, totalPrice: number, createdBy: { __typename?: 'Account', firstName?: string | null, middleName?: string | null, lastName?: string | null }, indicationOfInterest: { __typename?: 'IndicationOfInterest', id: string } };

export type IndicationOfInterestFieldsFragment = { __typename?: 'IndicationOfInterest', id: string, type: string, currencyCode: string, expiresAt?: string | null, volume: number, status: string, createdAt: string, clientCompany: { __typename?: 'ClientCompany', legalName: string }, offers?: { __typename?: 'OfferPageConnection', collection?: Array<{ __typename?: 'Offer', answeredAt?: string | null, createdAt: string, id: string, isAccepted: boolean, price: number, totalPrice: number, createdBy: { __typename?: 'Account', firstName?: string | null, middleName?: string | null, lastName?: string | null }, indicationOfInterest: { __typename?: 'IndicationOfInterest', id: string } } | null> | null } | null, createdBy: { __typename?: 'Account', firstName?: string | null, middleName?: string | null, lastName?: string | null }, commodity: { __typename?: 'Commodity', id: string, name: string } };

export type EnergyExchangeOrderFieldsFragment = { __typename?: 'Order', createdAt: string, deliveryDate: string, id: string, paymentDate: string, priceId: string, price?: number | null, status: string, term: number, totalAmount?: number | null, type: string, updatedAt: string, volume: number, number: string, isPaid: boolean, createdBy: { __typename?: 'Account', clientCompany?: { __typename?: 'ClientCompany', id: string, legalName: string } | null } };

export const NotificationFieldsFragmentDoc = gql`
    fragment notificationFields on Notification {
  id
  module
  notificationType
  occurred_at
  payload
  processedPayload
  accountNotifications {
    edges {
      node {
        id
        read
        account {
          id
          email
        }
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;
export const CommodityVolumesMonthFieldsFragmentDoc = gql`
    fragment commodityVolumesMonthFields on CommodityVolumesMonth {
  id
  totalProduced
  sold
  totalSold
  open
  toBeProducedPerMonth
  expected
  yearNumber
  month
  produced
}
    `;
export const CommodityVolumesYearFieldsFragmentDoc = gql`
    fragment commodityVolumesYearFields on CommodityVolumesYear {
  id
  year
  savingLimit
  totalExpected
  months {
    collection {
      ...commodityVolumesMonthFields
    }
  }
}
    ${CommodityVolumesMonthFieldsFragmentDoc}`;
export const AccountNameFieldsFragmentDoc = gql`
    fragment accountNameFields on Account {
  firstName
  middleName
  lastName
}
    `;
export const IndicationOfInterestOfferFieldsFragmentDoc = gql`
    fragment indicationOfInterestOfferFields on Offer {
  answeredAt
  createdAt
  createdBy {
    firstName
    middleName
    lastName
  }
  id
  indicationOfInterest {
    id
  }
  isAccepted
  price
  totalPrice
}
    `;
export const IndicationOfInterestFieldsFragmentDoc = gql`
    fragment indicationOfInterestFields on IndicationOfInterest {
  id
  type
  currencyCode
  expiresAt
  clientCompany {
    legalName
  }
  volume
  status
  createdAt
  offers {
    collection {
      answeredAt
      createdAt
      createdBy {
        firstName
        middleName
        lastName
      }
      id
      indicationOfInterest {
        id
      }
      isAccepted
      price
      totalPrice
    }
  }
  createdBy {
    firstName
    middleName
    lastName
  }
  commodity {
    id
    name
  }
}
    `;
export const EnergyExchangeOrderFieldsFragmentDoc = gql`
    fragment energyExchangeOrderFields on Order {
  createdAt
  deliveryDate
  id
  paymentDate
  priceId
  price
  status
  term
  totalAmount
  type
  updatedAt
  volume
  number
  isPaid
  createdBy {
    clientCompany {
      id
      legalName
    }
  }
}
    `;
export const CreateOrderDocument = gql`
    mutation CreateOrder($input: createOrderInput!) @api(name: "authEndPoint") {
  createOrder(input: $input) {
    order {
      ...energyExchangeOrderFields
    }
  }
}
    ${EnergyExchangeOrderFieldsFragmentDoc}`;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOrderMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderMutation(options: VueApolloComposable.UseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
}
export type CreateOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateOrderMutation, CreateOrderMutationVariables>;
export const GetOrdersDocument = gql`
    query GetOrders($page: Int, $status: String, $status_list: [String], $order: [OrderFilter_order]) @api(name: "authEndPoint") {
  orders(page: $page, status: $status, status_list: $status_list, order: $order) {
    collection {
      ...energyExchangeOrderFields
    }
    paginationInfo {
      itemsPerPage
      lastPage
      totalCount
    }
  }
}
    ${EnergyExchangeOrderFieldsFragmentDoc}`;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a Vue component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOrdersQuery({
 *   page: // value for 'page'
 *   status: // value for 'status'
 *   status_list: // value for 'status_list'
 *   order: // value for 'order'
 * });
 */
export function useGetOrdersQuery(variables: GetOrdersQueryVariables | VueCompositionApi.Ref<GetOrdersQueryVariables> | ReactiveFunction<GetOrdersQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<GetOrdersQuery, GetOrdersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetOrdersQuery, GetOrdersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetOrdersQuery, GetOrdersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, variables, options);
}
export function useGetOrdersLazyQuery(variables: GetOrdersQueryVariables | VueCompositionApi.Ref<GetOrdersQueryVariables> | ReactiveFunction<GetOrdersQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<GetOrdersQuery, GetOrdersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetOrdersQuery, GetOrdersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetOrdersQuery, GetOrdersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, variables, options);
}
export type GetOrdersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetOrdersQuery, GetOrdersQueryVariables>;
export const GetClientCompanyZohoStatusDocument = gql`
    query GetClientCompanyZohoStatus($id: ID!) @api(name: "authEndPoint") {
  clientCompany(id: $id) {
    primarySubEntity {
      zohoAccount {
        id
        kycStatus
        status
      }
    }
  }
}
    `;

/**
 * __useGetClientCompanyZohoStatusQuery__
 *
 * To run a query within a Vue component, call `useGetClientCompanyZohoStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientCompanyZohoStatusQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetClientCompanyZohoStatusQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetClientCompanyZohoStatusQuery(variables: GetClientCompanyZohoStatusQueryVariables | VueCompositionApi.Ref<GetClientCompanyZohoStatusQueryVariables> | ReactiveFunction<GetClientCompanyZohoStatusQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetClientCompanyZohoStatusQuery, GetClientCompanyZohoStatusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetClientCompanyZohoStatusQuery, GetClientCompanyZohoStatusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetClientCompanyZohoStatusQuery, GetClientCompanyZohoStatusQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetClientCompanyZohoStatusQuery, GetClientCompanyZohoStatusQueryVariables>(GetClientCompanyZohoStatusDocument, variables, options);
}
export function useGetClientCompanyZohoStatusLazyQuery(variables?: GetClientCompanyZohoStatusQueryVariables | VueCompositionApi.Ref<GetClientCompanyZohoStatusQueryVariables> | ReactiveFunction<GetClientCompanyZohoStatusQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetClientCompanyZohoStatusQuery, GetClientCompanyZohoStatusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetClientCompanyZohoStatusQuery, GetClientCompanyZohoStatusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetClientCompanyZohoStatusQuery, GetClientCompanyZohoStatusQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetClientCompanyZohoStatusQuery, GetClientCompanyZohoStatusQueryVariables>(GetClientCompanyZohoStatusDocument, variables, options);
}
export type GetClientCompanyZohoStatusQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetClientCompanyZohoStatusQuery, GetClientCompanyZohoStatusQueryVariables>;
export const GetExchangePlugStatusDocument = gql`
    query GetExchangePlugStatus($id: ID!) @api(name: "authEndPoint") {
  exchangePlugStatus(id: $id) {
    _id
    createdAt
    id
    setBy {
      id
    }
    status
  }
}
    `;

/**
 * __useGetExchangePlugStatusQuery__
 *
 * To run a query within a Vue component, call `useGetExchangePlugStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangePlugStatusQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetExchangePlugStatusQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetExchangePlugStatusQuery(variables: GetExchangePlugStatusQueryVariables | VueCompositionApi.Ref<GetExchangePlugStatusQueryVariables> | ReactiveFunction<GetExchangePlugStatusQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetExchangePlugStatusQuery, GetExchangePlugStatusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetExchangePlugStatusQuery, GetExchangePlugStatusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetExchangePlugStatusQuery, GetExchangePlugStatusQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetExchangePlugStatusQuery, GetExchangePlugStatusQueryVariables>(GetExchangePlugStatusDocument, variables, options);
}
export function useGetExchangePlugStatusLazyQuery(variables?: GetExchangePlugStatusQueryVariables | VueCompositionApi.Ref<GetExchangePlugStatusQueryVariables> | ReactiveFunction<GetExchangePlugStatusQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetExchangePlugStatusQuery, GetExchangePlugStatusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetExchangePlugStatusQuery, GetExchangePlugStatusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetExchangePlugStatusQuery, GetExchangePlugStatusQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetExchangePlugStatusQuery, GetExchangePlugStatusQueryVariables>(GetExchangePlugStatusDocument, variables, options);
}
export type GetExchangePlugStatusQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetExchangePlugStatusQuery, GetExchangePlugStatusQueryVariables>;
export const GetExchangePlugStatusesDocument = gql`
    query GetExchangePlugStatuses($page: Int) @api(name: "authEndPoint") {
  exchangePlugStatuses(page: $page) {
    collection {
      _id
      createdAt
      id
      setBy {
        id
      }
      status
    }
    paginationInfo {
      itemsPerPage
      lastPage
      totalCount
    }
  }
}
    `;

/**
 * __useGetExchangePlugStatusesQuery__
 *
 * To run a query within a Vue component, call `useGetExchangePlugStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangePlugStatusesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetExchangePlugStatusesQuery({
 *   page: // value for 'page'
 * });
 */
export function useGetExchangePlugStatusesQuery(variables: GetExchangePlugStatusesQueryVariables | VueCompositionApi.Ref<GetExchangePlugStatusesQueryVariables> | ReactiveFunction<GetExchangePlugStatusesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<GetExchangePlugStatusesQuery, GetExchangePlugStatusesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetExchangePlugStatusesQuery, GetExchangePlugStatusesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetExchangePlugStatusesQuery, GetExchangePlugStatusesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetExchangePlugStatusesQuery, GetExchangePlugStatusesQueryVariables>(GetExchangePlugStatusesDocument, variables, options);
}
export function useGetExchangePlugStatusesLazyQuery(variables: GetExchangePlugStatusesQueryVariables | VueCompositionApi.Ref<GetExchangePlugStatusesQueryVariables> | ReactiveFunction<GetExchangePlugStatusesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<GetExchangePlugStatusesQuery, GetExchangePlugStatusesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetExchangePlugStatusesQuery, GetExchangePlugStatusesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetExchangePlugStatusesQuery, GetExchangePlugStatusesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetExchangePlugStatusesQuery, GetExchangePlugStatusesQueryVariables>(GetExchangePlugStatusesDocument, variables, options);
}
export type GetExchangePlugStatusesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetExchangePlugStatusesQuery, GetExchangePlugStatusesQueryVariables>;
export const UpdateExchangePlugStatusDocument = gql`
    mutation UpdateExchangePlugStatus($input: updateExchangePlugStatusInput!) @api(name: "authEndPoint") {
  updateExchangePlugStatus(input: $input) {
    exchangePlugStatus {
      _id
      createdAt
      id
      setBy {
        id
      }
      status
    }
  }
}
    `;

/**
 * __useUpdateExchangePlugStatusMutation__
 *
 * To run a mutation, you first call `useUpdateExchangePlugStatusMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExchangePlugStatusMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateExchangePlugStatusMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExchangePlugStatusMutation(options: VueApolloComposable.UseMutationOptions<UpdateExchangePlugStatusMutation, UpdateExchangePlugStatusMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateExchangePlugStatusMutation, UpdateExchangePlugStatusMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateExchangePlugStatusMutation, UpdateExchangePlugStatusMutationVariables>(UpdateExchangePlugStatusDocument, options);
}
export type UpdateExchangePlugStatusMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateExchangePlugStatusMutation, UpdateExchangePlugStatusMutationVariables>;
export const GetRepresentativeProductsDocument = gql`
    query getRepresentativeProducts($id: ID!) @api(name: "authEndPoint") {
  account(id: $id) {
    id
    companyRepresentativeProducts {
      edges {
        node {
          id
          name
          abbreviation
        }
      }
    }
  }
}
    `;

/**
 * __useGetRepresentativeProductsQuery__
 *
 * To run a query within a Vue component, call `useGetRepresentativeProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepresentativeProductsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetRepresentativeProductsQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetRepresentativeProductsQuery(variables: GetRepresentativeProductsQueryVariables | VueCompositionApi.Ref<GetRepresentativeProductsQueryVariables> | ReactiveFunction<GetRepresentativeProductsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetRepresentativeProductsQuery, GetRepresentativeProductsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetRepresentativeProductsQuery, GetRepresentativeProductsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetRepresentativeProductsQuery, GetRepresentativeProductsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetRepresentativeProductsQuery, GetRepresentativeProductsQueryVariables>(GetRepresentativeProductsDocument, variables, options);
}
export function useGetRepresentativeProductsLazyQuery(variables?: GetRepresentativeProductsQueryVariables | VueCompositionApi.Ref<GetRepresentativeProductsQueryVariables> | ReactiveFunction<GetRepresentativeProductsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetRepresentativeProductsQuery, GetRepresentativeProductsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetRepresentativeProductsQuery, GetRepresentativeProductsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetRepresentativeProductsQuery, GetRepresentativeProductsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetRepresentativeProductsQuery, GetRepresentativeProductsQueryVariables>(GetRepresentativeProductsDocument, variables, options);
}
export type GetRepresentativeProductsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetRepresentativeProductsQuery, GetRepresentativeProductsQueryVariables>;
export const GetLandingPageProductsDocument = gql`
    query getLandingPageProducts($id: ID!, $productStatus: String = "pending") @api(name: "authEndPoint") {
  account(id: $id) {
    id
    email
    status
    roles
    companyRepresentativeProducts {
      edges {
        node {
          id
          name
          abbreviation
        }
      }
    }
    productAccessRequests(status: $productStatus) {
      collection {
        id
        product {
          id
          abbreviation
          name
        }
        status
      }
    }
  }
}
    `;

/**
 * __useGetLandingPageProductsQuery__
 *
 * To run a query within a Vue component, call `useGetLandingPageProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLandingPageProductsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetLandingPageProductsQuery({
 *   id: // value for 'id'
 *   productStatus: // value for 'productStatus'
 * });
 */
export function useGetLandingPageProductsQuery(variables: GetLandingPageProductsQueryVariables | VueCompositionApi.Ref<GetLandingPageProductsQueryVariables> | ReactiveFunction<GetLandingPageProductsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetLandingPageProductsQuery, GetLandingPageProductsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetLandingPageProductsQuery, GetLandingPageProductsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetLandingPageProductsQuery, GetLandingPageProductsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetLandingPageProductsQuery, GetLandingPageProductsQueryVariables>(GetLandingPageProductsDocument, variables, options);
}
export function useGetLandingPageProductsLazyQuery(variables?: GetLandingPageProductsQueryVariables | VueCompositionApi.Ref<GetLandingPageProductsQueryVariables> | ReactiveFunction<GetLandingPageProductsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetLandingPageProductsQuery, GetLandingPageProductsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetLandingPageProductsQuery, GetLandingPageProductsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetLandingPageProductsQuery, GetLandingPageProductsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetLandingPageProductsQuery, GetLandingPageProductsQueryVariables>(GetLandingPageProductsDocument, variables, options);
}
export type GetLandingPageProductsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetLandingPageProductsQuery, GetLandingPageProductsQueryVariables>;
export const GetAllProductsDocument = gql`
    query getAllProducts @api(name: "authEndPoint") {
  products {
    edges {
      node {
        id
        name
        abbreviation
      }
    }
  }
}
    `;

/**
 * __useGetAllProductsQuery__
 *
 * To run a query within a Vue component, call `useGetAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllProductsQuery();
 */
export function useGetAllProductsQuery(options: VueApolloComposable.UseQueryOptions<GetAllProductsQuery, GetAllProductsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetAllProductsQuery, GetAllProductsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetAllProductsQuery, GetAllProductsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(GetAllProductsDocument, {}, options);
}
export function useGetAllProductsLazyQuery(options: VueApolloComposable.UseQueryOptions<GetAllProductsQuery, GetAllProductsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetAllProductsQuery, GetAllProductsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetAllProductsQuery, GetAllProductsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(GetAllProductsDocument, {}, options);
}
export type GetAllProductsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetAllProductsQuery, GetAllProductsQueryVariables>;
export const UpdatePaymentStatusOrderDocument = gql`
    mutation updatePaymentStatusOrder($input: updatePaymentStatusOrderInput!) @api(name: "authEndPoint") {
  updatePaymentStatusOrder(input: $input) {
    clientMutationId
    order {
      ...energyExchangeOrderFields
    }
  }
}
    ${EnergyExchangeOrderFieldsFragmentDoc}`;

/**
 * __useUpdatePaymentStatusOrderMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentStatusOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentStatusOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdatePaymentStatusOrderMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentStatusOrderMutation(options: VueApolloComposable.UseMutationOptions<UpdatePaymentStatusOrderMutation, UpdatePaymentStatusOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdatePaymentStatusOrderMutation, UpdatePaymentStatusOrderMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdatePaymentStatusOrderMutation, UpdatePaymentStatusOrderMutationVariables>(UpdatePaymentStatusOrderDocument, options);
}
export type UpdatePaymentStatusOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdatePaymentStatusOrderMutation, UpdatePaymentStatusOrderMutationVariables>;
export const GetProductCommoditiesDocument = gql`
    query getProductCommodities($id: String) @api(name: "authEndPoint") {
  commodities(product_id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetProductCommoditiesQuery__
 *
 * To run a query within a Vue component, call `useGetProductCommoditiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCommoditiesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetProductCommoditiesQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetProductCommoditiesQuery(variables: GetProductCommoditiesQueryVariables | VueCompositionApi.Ref<GetProductCommoditiesQueryVariables> | ReactiveFunction<GetProductCommoditiesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<GetProductCommoditiesQuery, GetProductCommoditiesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetProductCommoditiesQuery, GetProductCommoditiesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetProductCommoditiesQuery, GetProductCommoditiesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetProductCommoditiesQuery, GetProductCommoditiesQueryVariables>(GetProductCommoditiesDocument, variables, options);
}
export function useGetProductCommoditiesLazyQuery(variables: GetProductCommoditiesQueryVariables | VueCompositionApi.Ref<GetProductCommoditiesQueryVariables> | ReactiveFunction<GetProductCommoditiesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<GetProductCommoditiesQuery, GetProductCommoditiesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetProductCommoditiesQuery, GetProductCommoditiesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetProductCommoditiesQuery, GetProductCommoditiesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetProductCommoditiesQuery, GetProductCommoditiesQueryVariables>(GetProductCommoditiesDocument, variables, options);
}
export type GetProductCommoditiesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetProductCommoditiesQuery, GetProductCommoditiesQueryVariables>;